import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const wishlistApi = createApi({
  reducerPath: "wishlistApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Wishlist", "WishlistById"],
  endpoints: (builder) => ({
    getWishlist: builder.query({
      query: () => `/wishlist`,
      providesTags: ["Wishlist"],
    }),
    getWishlistById: builder.query({
      query: (id) => {
        return {
          url: `/wishlist/${id}`,
          method: "GET",
        };
      },
      providesTags: ["WishlistById"],
    }),
    updateWishList: builder.mutation({
      query: (list) => {
        return {
          url: `/wishlist/update`,
          method: "PATCH",
          body: {
            list,
          },
        };
      },
      invalidatesTags: ["Wishlist", "WishlistById"],
    }),
    createWishList: builder.mutation({
      query: (list) => {
        return {
          url: `/wishlist/add`,
          method: "POST",
          body: {
            ...list,
          },
        };
      },
      invalidatesTags: ["Wishlist"],
    }),
    removeWishList: builder.mutation({
      query: (list) => {
        return {
          url: `/wishlist/remove`,
          method: "DELETE",
          body: {
            ...list,
          },
        };
      },
      invalidatesTags: ["Wishlist"],
    }),
  }),
});

export const {
  useGetWishlistQuery,
  useGetWishlistByIdQuery,
  useUpdateWishListMutation,
  useCreateWishListMutation,
  useRemoveWishListMutation,
} = wishlistApi;
