import { useGetOrdersQuery, useLazyGetAllOrdersQuery } from "api/orders";
import { useGetReturnsQuery } from "api/returns";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "pages/profile/ReturnsPage.scss";
import Modal from "components/Reusable/Modal/Modal";
import ReturnItemModal from "pages/profile/ReturnItemModal";
import useWindowDimensions from "utils/windowWidth";

const ReturnsPage = () => {
  let { data, isLoading, error } = useGetReturnsQuery();
  const [getAllOrders, { data: allOrders }] = useLazyGetAllOrdersQuery();
  const orders = allOrders?.orders;
  const [open, setOpen] = useState(false);
  const [returnItem, setReturnItem] = useState(false);

  const getTheOrder = (id, returnId) => {
    let order = orders.find((order) => order.id === id);
    let copy = data?.returns?.find((el) => el._id === returnId);
    const objCopy = JSON.parse(JSON.stringify(order));
    objCopy.items = copy.items.map((el) => {
      return {
        ...el.itemToReturn,
        returnStatus: "pending",
      };
    });

    setReturnItem(objCopy);
    setOpen(true);
  };
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  useEffect(() => {
    getAllOrders({
      limit: 99999,
      page: 1,
    });
  }, []);

  return (
    <div className="returns_container">
      <h1 style={{ textAlign: "center" }}>My returns</h1>
      <table className="returns_table">
        <thead className="returns_table_head">
          <tr>
            <th>Return id</th>
            {!isMobile ? (
              <>
                <th>Items</th>
                <th>Reason</th>
              </>
            ) : null}
            <th>Status</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody className="returns_table_body">
          {data?.returns?.length > 0 ? (
            data?.returns?.map((returnItem) => (
              <tr key={returnItem._id}>
                <td>
                  <button
                    onClick={() =>
                      getTheOrder(returnItem.orderId, returnItem._id)
                    }
                  >
                    {returnItem._id}
                  </button>
                </td>
                {!isMobile ? (
                  <>
                    <td>
                      <p>
                        {returnItem.items
                          .map((el) => {
                            return el.itemToReturn.shortDescription;
                          })
                          .join(", ")}
                      </p>
                    </td>
                    <td>
                      <p>
                        {returnItem.items
                          .map((el) => {
                            return el.returnReason;
                          })
                          .join(", ")}
                      </p>
                    </td>
                  </>
                ) : null}
                <td>
                  <p>{returnItem.status}</p>
                </td>
                <td>
                  <p>{new Date(returnItem.createdAt).toLocaleString()}</p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" style={{ textAlign: "center" }}>
                {isLoading ? <CircularProgress /> : "No returns"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div>
          <ReturnItemModal
            returnPage
            returnItem={returnItem}
            setOpen={setOpen}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ReturnsPage;
