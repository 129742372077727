import React, { useEffect, useState } from "react";
import "components/Reusable/Filters/children/ColorFilter.scss";
import { useLazyGetProductColorQuery } from "api/products";
import { useParams, useSearchParams } from "react-router-dom";

const ColorFilter = () => {
  const { category, subCategory, designer } = useParams();
  const [hideFilter, setHideFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  //To get all colors of products
  const [getProductColors, { data: colors, isLoading, isError, error }] =
    useLazyGetProductColorQuery();
  useEffect(() => {
    getProductColors({ category, subCategory, designer });
  }, [category, subCategory]);

  //To get the color query from the url
  const colorParams = searchParams.get("color");
  const splitValues = colorParams?.split(",");
  //Other than color, to add to the filters below
  const allOtherQueris = {};
  searchParams.forEach((value, key) => {
    if (key !== "color") {
      allOtherQueris[key] = value;
    }
  });

  return (
    <div className="color_filter_container">
      <p
        onClick={() => setHideFilter(!hideFilter)}
        className="color_filter_title"
      >
        Color
      </p>
      {!hideFilter ? (
        <div className="color_container">
          {colors?.colors?.map((color) => {
            return (
              <div key={color} className="color_filter_wrapper">
                <input
                  checked={splitValues?.includes(String(color))}
                  className="filter_checkbox"
                  type="checkbox"
                  id={color}
                  onClick={(e) => {
                    if (splitValues?.includes(e.target.id)) {
                      //If we removing last color, then remove the entire color query from the url
                      if (splitValues?.length === 1) {
                        setSearchParams({ ...allOtherQueris });
                        return;
                      } else {
                        //If we removing color and it's not the last color item, we remove only that item
                        setSearchParams({
                          ...allOtherQueris,
                          color: colorParams
                            ?.split(",")
                            .filter((item) => item !== e.target.id)
                            .join(","),
                        });
                        return;
                      }
                    } else {
                      //If we adding a new color and it doesn't exists on the url query
                      setSearchParams({
                        ...allOtherQueris,
                        color: colorParams
                          ? colorParams + "," + e.target.id
                          : e.target.id,
                      });
                      return;
                    }
                  }}
                />
                <label htmlFor={color}>{color}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ColorFilter;
