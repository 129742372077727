import React from "react";
import "components/ShoppingBag/Table.scss";
import { v4 as uuidv4 } from "uuid";
import { INCRESE_QUANTITY } from "store/slices/CartSlice";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useParams } from "react-router-dom";

const WebTable = ({
  products,
  deleteCartItem,
  subTotal,
  taxes,
  discount = 0,
  details = false,
  total = 0,
  dbItemsOutOfStock = [],
}) => {
  const dispatch = useDispatch();
  const maxdQuantity = (product) => {
    const value = product.size.find((item) => {
      if (Number(item.value) === Number(product.selectedSize)) {
        return item;
      }
    });

    if (value) {
      return value.quantity;
    } else {
      return 0;
    }
  };
  const params = useParams();

  return (
    <table>
      {/* HEAD */}
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>
            <p>Quantity</p>
          </th>
          <th>
            <p>Price</p>
          </th>
          <th>
            <p>Total</p>
          </th>
        </tr>
      </thead>
      {/* BODY */}
      <tbody>
        {products?.map((product) => {
          const itemNotInStock = dbItemsOutOfStock.filter((item) => {
            return item._id === product._id;
          });
          const isOutOfStock = itemNotInStock[0]?.stockAvailable <= 0;
          const alternativeStock =
            !isOutOfStock &&
            itemNotInStock[0]?.stockRequested >
              itemNotInStock[0]?.stockAvailable;
          const isItemReturned = !!product?.returnStatus;
          return (
            <tr
              style={{
                color: isItemReturned ? "rgba(0,0,0,0.5)" : "",
              }}
              key={uuidv4()}
            >
              <td>
                <img
                  style={{
                    border:
                      isOutOfStock || alternativeStock
                        ? "3px solid red"
                        : "none",
                    filter: isItemReturned ? "grayscale(100%)" : "none",
                  }}
                  className="shopping_bag_image"
                  src={product.primaryPhoto || product.photos[0].url}
                ></img>
              </td>
              <td
                style={{ paddingLeft: details ? "2rem" : "4rem" }}
                className="shopping_bag_product_details"
              >
                <div>
                  <p>{product.designer}</p>
                </div>
                <div>
                  <p>
                    <strong>{product.shortDescription}</strong>
                  </p>
                </div>
                <div>
                  {product?.category !== "Perfumes" ? (
                    <p>
                      Size: {product.selectedSize} - Color:{" "}
                      {product.selectedColor || "Default"}
                    </p>
                  ) : null}
                  {product?.category === "Perfumes" &&
                  product?.fragrances?.length > 0 ? (
                    <p>Fragrance: {product?.fragrances?.toString()}</p>
                  ) : null}
                  {isItemReturned ? (
                    <p
                      style={{
                        marginTop: "25px",
                        color: "#555",
                        whiteSpace: "nowrap",
                        fontWeight: "600",
                      }}
                    >
                      This item was returned
                    </p>
                  ) : null}
                </div>

                {!details ? (
                  <div>
                    <span>
                      <button
                        onClick={() => deleteCartItem(product)}
                        className="shopping_bag_buttons"
                      >
                        Remove from cart
                      </button>
                      <button>Add to wishlist</button>
                    </span>
                    {/* Out of stock message */}
                    {isOutOfStock ? (
                      <p
                        style={{
                          color: "red",
                          fontWeight: "600",
                          marginTop: "5px",
                        }}
                      >
                        This item is out of stock.
                        <br /> Consider removing it from cart.
                      </p>
                    ) : null}
                    {/* Not enough stock message */}
                    {alternativeStock ? (
                      <p
                        style={{
                          color: "red",
                          fontWeight: "600",
                          marginTop: "5px",
                        }}
                      >
                        {`Only ${itemNotInStock[0]?.stockAvailable} product${
                          itemNotInStock[0]?.stockAvailable > 1 ? "s" : ""
                        } in stock`}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </td>
              <td className="ta-right">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "0.5rem",
                    position: "relative",
                  }}
                >
                  {!params?.order_id && (
                    <RemoveIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatch(
                          INCRESE_QUANTITY({
                            ...product,
                            quantity: Number(product.quantity) - 1,
                          })
                        );
                      }}
                    ></RemoveIcon>
                  )}
                  {product?.quantity}
                  {!params?.order_id && (
                    <AddIcon
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        dispatch(
                          INCRESE_QUANTITY({
                            ...product,
                            quantity: Number(product.quantity) + 1,
                          })
                        );
                      }}
                    ></AddIcon>
                  )}
                  {product.quantity >= maxdQuantity(product) &&
                    !params?.order_id && (
                      <p
                        style={{
                          color: "#999",
                          cursor: "default",
                          position: "absolute",
                          bottom: 2.5,
                          right: -40,
                        }}
                      >
                        max
                      </p>
                    )}
                </div>
              </td>
              {/* Single item price */}
              <td className={"ta-right"}>
                {product.finalPrice.format(2, 3, " ", ",")} €
              </td>
              {/* Total price of the item(s) */}
              <td className={"ta-right"}>
                <b>{`${(Number(product.finalPrice) * product.quantity).format(
                  2,
                  3,
                  " ",
                  ","
                )}€`}</b>
              </td>
            </tr>
          );
        })}
      </tbody>

      {/* FOOTER */}
      <tfoot>
        <tr>
          <td colSpan="4" className="ta-right">
            <p>Price</p>
          </td>
          <td className="ta-right">{subTotal.format(2, 3, " ", ",")} €</td>
        </tr>
        <tr>
          <td colSpan="4" className="ta-right">
            <p>Taxes</p>
          </td>
          <td className="ta-right">
            {Math.ceil(taxes).format(2, 3, " ", ",")} €
          </td>
        </tr>
        {discount > 0 ? (
          <tr>
            <td colSpan="4" className="ta-right">
              <p>Discount</p>
            </td>
            <td className="ta-right">-{discount.format(2, 3, " ", ",")} €</td>
          </tr>
        ) : null}
        <tr>
          <td colSpan="4" className="ta-right">
            <p>
              <strong>Total price</strong>
            </p>
          </td>
          {total ? (
            <td className="ta-right">{total.format(2, 3, " ", ",")} €</td>
          ) : (
            <td className="ta-right">
              {Math.ceil(subTotal + taxes).format(2, 3, " ", ",")} €
            </td>
          )}
        </tr>
      </tfoot>
    </table>
  );
};

export default WebTable;
