import "styles/Modals/OrderStatusChange.scss";
import Modal from "components/Reusable/Modal/Modal";
import { useState } from "react";

const OrderStatusChange = ({
  isModalOpen,
  setIsModalOpen,
  modifyOrderStatus,
}) => {
  const [form, setForm] = useState({
    tracking_number: "",
    tracking_url: "",
  });

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
      <div className="order_status_modal">
        <h3>Order Status Change</h3>
        {/* tracking number */}
        <div className="order_status_input">
          <label htmlFor="tracking_number">
            Tracking number <span>(optional)</span>
          </label>
          <input
            onChange={handleFormChange}
            type="text"
            name="tracking_number"
          />
        </div>
        {/* tracking url */}
        <div className="order_status_input">
          <label>
            Tracking company URL <span>(optional)</span>
          </label>
          <input
            onChange={handleFormChange}
            htmlFor="tracking_url"
            type="text"
            name="tracking_url"
          />
        </div>
        <button
          className="modal_button"
          onClick={() => {
            setForm({
              tracking_number: "",
              tracking_url: "",
            });
            modifyOrderStatus({ target: { value: "Shipped" } }, form);
          }}
        >
          Submit
        </button>
        {/* close button right top corner */}
        <button
          className="modal_close_button"
          onClick={() => setIsModalOpen(false)}
        >
          X
        </button>
      </div>
    </Modal>
  );
};

export default OrderStatusChange;
