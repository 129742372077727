import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const designersApi = createApi({
  reducerPath: "designersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Designers"],
  endpoints: (builder) => ({
    getDesigners: builder.query({
      query: () => `/designers`,
      providesTags: ["Designers"],
    }),
    addDesigner: builder.mutation({
      query: (body) => ({
        url: `/admin/designers/add`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Designers"],
    }),
    deleteDesigner: builder.mutation({
      query: (selected) => ({
        url: `/admin/designers/delete`,
        method: "DELETE",
        body: {
          nameArray: selected,
        },
      }),
      invalidatesTags: ["Designers"],
    }),
    updateDesigner: builder.mutation({
      query: (body) => ({
        url: `/admin/designers/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Designers"],
    }),
  }),
});

export const {
  useGetDesignersQuery,
  useLazyGetDesignersQuery,
  useAddDesignerMutation,
  useDeleteDesignerMutation,
  useUpdateDesignerMutation,
} = designersApi;
