import React, { useEffect, useRef } from "react";
import "pages/blogs/blogs.scss";
import MainBlocks from "pages/blogs/mainBlocks";
import { useDispatch, useSelector } from "react-redux";
import { setBlogs } from "store/slices/Blogs";
import { useLocation, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const itemsFiltered = {};
  const dispatch = useDispatch();
  const { search } = useLocation();
  const homePageRedirect = search.includes("redirect");
  const { blocks, categories } = useSelector((state) => state.blogs);
  //SCROLL TO OBJECTS SECTION IF OPENED FROM HOME PAGE
  useEffect(() => {
    if (homePageRedirect) {
      scrollToItem(2);
    }

    return () => {};
  }, [homePageRedirect]);

  //GET REF"S OF ALL THE SECTIONS
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    if (!itemsFiltered[block.category]) {
      itemsFiltered[block.category] = [];
    }
    itemsFiltered[block.category].push(block);
  }
  const data = categories.map((category) => {
    return {
      ...category,
      blocks: itemsFiltered[category.title.toLowerCase()],
    };
  });
  useEffect(() => {
    dispatch(setBlogs(data));
  }, []);

  //SCROLL TO SECTION
  const sectionRef = useRef([]);
  const scrollToItem = (index) => {
    const offsetTop =
      sectionRef.current[index].getBoundingClientRect().top +
      window.pageYOffset +
      -75;

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="blogs_story_container">
      <Helmet>
        <title>
          1511 Paris | Design Chronicles: Discover Stories, Editorials and
          Objects
        </title>
        {/* <link rel="canonical" href="https://1511.paris/blogs" /> */}
        <meta
          name="keywords"
          content="Stories, Editorials, Objects, Design, Designers, Creativity, Innovation, Inspirations, Journeys, Trends, Aesthetics, Functionality, Articles, Videos, Captivating, Inspiring, Thought-Provoking, Transformative, Exploration, Planets, Poetry, Documentary, Film, Photography, Art, Design, 1511 stories"
        />
        <meta
          name="description"
          content="Welcome to our Stories page, your gateway to captivating narratives and inspiring editorials in the world of design. Immerse yourself in the rich tapestry of stories, editorials, and objects that celebrate the intersection of creativity and innovation. Explore the 'Stories' section, where we share captivating tales of designers, their journeys, and the inspirations behind their exceptional creations. Dive into the 'Editorials' section, where our team of experts delves into the latest trends, design philosophies, and thought-provoking insights shaping the design landscape. Discover the 'Objects' section, where we showcase unique and curated design pieces that redefine aesthetics and functionality. From thought-provoking articles to visually stunning videos, our Stories page offers a multifaceted exploration of design and its transformative power. Get ready to be inspired, enlightened, and enthralled as you embark on a journey of creativity and storytelling."
        />
        <meta
          property="og:description"
          content="Welcome to our Stories page, your gateway to captivating narratives and inspiring editorials in the world of design. Immerse yourself in the rich tapestry of stories, editorials, and objects that celebrate the intersection of creativity and innovation. Explore the 'Stories' section, where we share captivating tales of designers, their journeys, and the inspirations behind their exceptional creations. Dive into the 'Editorials' section, where our team of experts delves into the latest trends, design philosophies, and thought-provoking insights shaping the design landscape. Discover the 'Objects' section, where we showcase unique and curated design pieces that redefine aesthetics and functionality. From thought-provoking articles to visually stunning videos, our Stories page offers a multifaceted exploration of design and its transformative power. Get ready to be inspired, enlightened, and enthralled as you embark on a journey of creativity and storytelling."
        ></meta>
      </Helmet>
      <div>
        <div className="blogs_story_header">
          <h5 onClick={() => scrollToItem(0)}>EDITORIALS</h5>
          <span />
          <h5 onClick={() => scrollToItem(1)}>STORIES</h5>
          <span />
          <h5 onClick={() => scrollToItem(2)}>OBJECTS</h5>
        </div>
        {data?.map((blog, index) => {
          return (
            <div ref={(el) => (sectionRef.current[index] = el)}>
              <MainBlocks blog={blog} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Blogs;
