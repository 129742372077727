import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "styles/RegisterPage.scss";
import { useSignUpMutation } from "api/auth";
import { useDispatch } from "react-redux";
import { regValidation } from "utils/authValidation";
import { USER_LOGIN } from "store/slices/UserSlice";
import { Helmet } from "react-helmet";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localError, setLocalError] = useState(null);
  const [signIn, { data: userData, isLoading, isError, isSuccess, error }] =
    useSignUpMutation();

  //If registration is successful, we will run this useEffect
  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(USER_LOGIN(userData));
      navigate("/");
    }
  }, [isSuccess]);

  //Registration function
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const user = {
      email: data.get("email").trim(),
      password: data.get("password").trim(),
      confirmPassword: data.get("confirmPassword").trim(),
      firstName: data.get("firstName").trim(),
      lastName: data.get("lastName").trim(),
    };
    //Frontend data validation
    const validationError = await regValidation(user);
    if (validationError) return setLocalError(validationError);
    else setLocalError(null);

    //If validation ok, send data to backend
    const { confirmPassword, ...userObject } = user;
    await signIn(userObject);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>1511 Paris | Sign-up</title>
        {/* <link rel="canonical" href="https://1511.paris/register" /> */}
        <meta
          name="keywords"
          content="1511 Paris, Sign Up, Register, Account Sign up, User sign up, Customer Registration, Secure Sign Up, Secure Registration, Secure Account Register, Secure User Sign up, Secure Customer Register, Register, Account, User, Customer, 1511, Paris, Sign Up for Your Account"
        />
        <meta
          name="description"
          content="Join us and embark on a beauty adventure by signing up at our website! Create your account today to unlock a world of beauty products, expert tips, and personalized recommendations. As a member, you'll gain access to exclusive offers, early access to new arrivals, and a seamless shopping experience. Start your journey towards beauty transformation and self-care. Sign up now and discover the power of 1511.paris."
        />
        <meta
          property="og:description"
          content="Join us and embark on a beauty adventure by signing up at our website! Create your account today to unlock a world of beauty products, expert tips, and personalized recommendations. As a member, you'll gain access to exclusive offers, early access to new arrivals, and a seamless shopping experience. Start your journey towards beauty transformation and self-care. Sign up now and discover the power of 1511.paris."
        ></meta>
      </Helmet>
      <Box className="register_container">
        <Avatar sx={{ m: 1, bgcolor: "#C4BBC6" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Optima",
            color: "#696969",
          }}
        >
          REGISTRATION
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                InputLabelProps={{
                  style: { color: "#C4BBC6" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C4BBC6",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C4BBC6",
                    },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                InputLabelProps={{
                  style: { color: "#C4BBC6" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C4BBC6",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C4BBC6",
                    },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                InputLabelProps={{
                  style: { color: "#C4BBC6" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C4BBC6",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C4BBC6",
                    },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                InputLabelProps={{
                  style: { color: "#C4BBC6" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C4BBC6",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C4BBC6",
                    },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                type="password"
                id="confirmPassword"
                autoComplete="confirm-password"
                InputLabelProps={{
                  style: { color: "#C4BBC6" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C4BBC6",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#C4BBC6",
                    },
                }}
              />
            </Grid>
          </Grid>
          {localError || isError ? (
            <Box sx={{ marginTop: "20px" }}>
              <Alert severity="error">
                {localError || error?.data?.message}
              </Alert>
            </Box>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Box className="spinner">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontFamily: "Optima",
                backgroundColor: "#C4BBC6",
                color: "black",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#C4BBC6",
                },
              }}
            >
              Sign Up
            </Button>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item>
              <button className="link_btn" onClick={() => navigate("/signin")}>
                Already have an account? Sign in
              </button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
