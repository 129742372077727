import React, { useEffect } from "react";
import "components/WishList/WishList.scss";
import ProductCard from "components/Reusable/ProductCard/ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useGetWishlistByIdQuery } from "api/wishlist";

const SharedWishList = () => {
  const { id } = useParams();
  const { data: wishList, isLoading, error } = useGetWishlistByIdQuery(id);

  //   CONDITIONS
  if (error?.data?.error)
    return (
      <h3
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Wish list is no longer available
      </h3>
    );
  if (!wishList || wishList?.products?.length <= 0)
    return (
      <h3
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "20px",
        }}
      >
        Wish List is empty
      </h3>
    );
  if (isLoading)
    return (
      <Box className="designer_table_loader">
        <CircularProgress />
      </Box>
    );
  // MAIN RETURN

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3 style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
        {wishList?.name}
      </h3>
      <div
        style={{
          justifyContent:
            wishList?.products?.length === 1 ? "flex-start" : "space-around",
        }}
        className={"wish_list_product_container"}
      >
        {wishList?.products?.map((product) => {
          return (
            product?.productActive && (
              <div
                style={{ marginLeft: "1rem" }}
                className="wish_list_product_wrapper"
              >
                <ProductCard key={product.id} item={product} />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SharedWishList;
