import React, { useCallback, useEffect, useMemo } from "react";
import "./product.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  SET_PRICE_TTC,
  SET_PRICE_HT,
  SET_PRODUCT_ACTIVE,
  SET_PRODUCT_DISCOUNT,
} from "store/slices/NewProductItemSlice";

const Discount = ({ item }) => {
  const dispatch = useDispatch();
  const { vat } = useSelector((state) => state.newProduct);
  const { priceHT, priceTTC, productActive, discount, finalPrice, activeTab } =
    item;

  const updatePriceHT = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value < 0) return;
    if (typeof e.target.value === "string" && e.target.value.includes("."))
      return;
    dispatch(
      SET_PRICE_HT({
        itemId: item?.id,
        value: e.target.value,
        priceTTC: Math.round(e.target.value * (1 + vat / 100)),
      })
    );
  };

  const updatePriceTTC = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value < 0) return;
    if (typeof e.target.value === "string" && e.target.value.includes("."))
      return;
    dispatch(
      SET_PRICE_TTC({
        itemId: item?.id,
        value: e.target.value,
        priceHT: Math.round(e.target.value / (1 + vat / 100)),
      })
    );
  };

  const updateDiscount = (e) => {
    if (isNaN(e.target.value)) return;
    if (e.target.value < 0) return;
    if (typeof e.target.value === "string" && e.target.value.includes("."))
      return;
    dispatch(
      SET_PRODUCT_DISCOUNT({
        itemId: item?.id,
        value: e.target.value,
      })
    );
  };

  const productActivityChange = () => {
    dispatch(SET_PRODUCT_ACTIVE({ itemId: item?.id }));
  };

  useEffect(() => {
    dispatch(
      SET_PRICE_TTC({
        itemId: item?.id,
        value: Math.round(priceHT * (1 + vat / 100)),
        priceHT: priceHT,
      })
    );
  }, [vat]);

  const priceFields = [
    {
      label: "Price HT",
      value: priceHT,
      onChange: updatePriceHT,
      priceTag: "EUR HT",
      priceSymbol: "€",
    },
    {
      label: "Price TTC",
      value: priceTTC,
      onChange: updatePriceTTC,
      priceTag: "EUR TTC",
      priceSymbol: "€",
    },
  ];
  const discountFields = [
    {
      label: "Discount reduction",
      value: discount,
      onChange: updateDiscount,
      preTag: "Reduction",
      postTag: "%",
    },
    {
      label: "Final price",
      value: finalPrice || priceTTC,
      onChange: updateDiscount,
      preTag: "Final",
      postTag: "EUR TTC",
      priceSymbol: "€",
    },
  ];

  return (
    <div className="discount_container">
      {/* ACTIVITY TOGGLE */}
      <div className="discount_activity_toggle_container">
        <div>
          <label className="switch">
            <input
              onClick={productActivityChange}
              defaultChecked={productActive}
              type="checkbox"
            />
            <span className="slider">
              <p className="activity_toggle_slider_text">Active</p>
            </span>
          </label>
        </div>
      </div>
      {/* DEVIDER LINE */}
      <div className="discount_devider_line" />
      {/* PRICE SECTION */}
      <h6 style={{ marginTop: "30px", marginBottom: "20px" }}>Price</h6>
      {priceFields?.map((item, index) => {
        return (
          <div
            key={item.label}
            className="price_container"
            style={{ marginTop: index === 1 ? "15px" : "0" }}
          >
            <div className="price_input_container">
              <div className="price_input_currency">{item.priceSymbol}</div>
              <input
                value={item.value}
                onChange={(e) => item.onChange(e)}
                className="price_input_field"
              ></input>
              <div className="price_input_currency_title">
                <p className="price_input_currency_title_text">
                  {item.priceTag}
                </p>
              </div>
            </div>
          </div>
        );
      })}
      {/* DEVIDER LINE #2 */}
      <div className="discount_devider_line" />
      {/* DISCOUNT SECTION */}
      <h6 style={{ marginTop: "30px", marginBottom: "20px" }}>Discount</h6>
      {discountFields?.map((item, index) => {
        return (
          <div
            key={item.label}
            className="price_container"
            style={{
              backgroundColor: index === 1 ? "#f1f1f1" : "transparent",
              marginTop: index === 1 ? "15px" : "0",
            }}
          >
            <div className="price_input_container">
              <div className="price_input_discount_label">
                <p className="discount_input_pre_tag">{item.preTag}</p>
              </div>
              <div style={{ position: "relative" }}>
                <div className="price_input_currency">{item.priceSymbol}</div>
                <input
                  disabled={index === 1}
                  placeholder="0"
                  value={item.value}
                  onChange={(e) => item.onChange(e)}
                  type="number"
                  className="price_input_field_discount"
                  style={{
                    paddingLeft: index === 1 ? "20px" : "10px",
                    paddingRight: index === 1 ? "0px" : "14px",
                    backgroundColor: index === 1 ? "#f1f1f1" : "transparent",
                  }}
                ></input>
              </div>
              <div className="price_input_currency_title">
                <p className="price_input_currency_title_text">
                  {item.postTag}
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Discount;
