import React, { useState, useEffect } from "react";
import "./FragranceSelect.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { uuidv4 } from "@firebase/util";

const FragranceSelect = ({
  listOfFragrances,
  limit,
  fragrances,
  setFragrances,
}) => {
  const [openState, setOpenState] = useState(false);
  const handleDropdown = () => {
    setOpenState((prev) => !prev);
  };
  const handleFragranceSelect = (e) => {
    e.stopPropagation();
    const fragrance = e.target.innerText;
    if (limit === 1) setFragrances([fragrance]);
    else setFragrances((prev) => [...prev, fragrance]);
    setOpenState(false);
  };

  if (!listOfFragrances) return <div>loading...</div>;

  return (
    <>
      <div onClick={handleDropdown} className="fragrance_button_container">
        <span>Select Fragrances</span>
        <KeyboardArrowDownIcon
          style={{
            transform: openState ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
        {/* DROPDOWN MENU */}
        {openState ? (
          <div className="fragrance_dropdown_container">
            {listOfFragrances?.map((fragrance) => {
              return (
                <div
                  key={uuidv4()}
                  onClick={(e) => {
                    if (fragrances.length < limit || limit === 1)
                      handleFragranceSelect(e);
                    else {
                      e.stopPropagation();
                      alert(`You can only select ${limit} fragrances`);
                      setOpenState(false);
                    }
                  }}
                  className="fragrance_dropdown_item"
                >
                  <span>{fragrance}</span>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {fragrances?.length > 0 ? (
        <div className="fragrance_list_container">
          <span className="fragrance_list_title">
            Selected {fragrances?.length}/{limit}
          </span>
          {fragrances?.map((fragrance, fragranceIndex) => {
            return (
              <div key={uuidv4()} className="fragrance_selected_container">
                <span>{fragrance}</span>
                <DeleteOutlineIcon
                  onClick={() => {
                    setFragrances((prev) =>
                      prev.filter((_, index) => index !== fragranceIndex)
                    );
                  }}
                  style={{ wdith: "20px", height: "20px" }}
                />
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default FragranceSelect;
