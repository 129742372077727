import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../styles/AdminPanel.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BrushIcon from "@mui/icons-material/Brush";
import Share from "@mui/icons-material/Share";
import CategoryIcon from "@mui/icons-material/Category";
import DiscountIcon from "@mui/icons-material/Discount";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const AdminPanel = () => {
  const menuTabs = [
    {
      name: "Dashboard",
      link: "dashboard",
      icon: <DashboardIcon />,
    },
    {
      name: "Users",
      link: "users",
      icon: <GroupIcon />,
    },
    {
      name: "Products",
      link: "products",
      icon: <AddShoppingCartIcon />,
    },
    {
      name: "Orders",
      link: "orders",
      icon: <LocalShippingIcon />,
    },
    {
      name: "Returns",
      link: "returns",
      icon: <ProductionQuantityLimitsIcon />,
    },
    {
      name: "Categories",
      link: "categories",
      icon: <AccountTreeIcon />,
    },
    {
      name: "Designers",
      link: "designers",
      icon: <BrushIcon />,
    },
    {
      name: "Blog Posts",
      link: "blogs/posts",
      icon: <Share />,
    },
    {
      name: "Blog Categories",
      link: "blogs/categories",
      icon: <CategoryIcon />,
    },
    {
      name: "Top Banner",
      link: "banners/top",
      icon: <DiscountIcon />,
    },
    {
      name: "Merchandising",
      link: "merchandising",
      icon: <StorefrontIcon />,
    },
    {
      name: "Home Modules",
      link: "homemodules",
      icon: <ViewModuleIcon />,
    },
  ];

  return (
    <div className="admin_container">
      <div className="admin_menu">
        {/* LEFT MENU TABS */}
        <nav className="admin_nav_container">
          <ul className="admin_nav">
            {menuTabs.map((item, index) => (
              <li style={{}} key={item.name}>
                <NavLink
                  className="admin_nav_item"
                  style={({ isActive }) => ({
                    fontWeight: isActive ? "bold" : "",
                    color: isActive ? "#1e88e5" : "#4a4a4a",
                    backgroundColor: isActive ? "rgba(232, 240, 254, 0.9)" : "",
                  })}
                  to={`/adminpanel/${item.link}`}
                >
                  <div className="admin_nav_item_wrapper">
                    {item.icon}
                    <span style={{ marginLeft: "10px" }} />
                    {item.name}
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {/* MAIN CONTENT */}
      <div className="admin_content">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminPanel;
