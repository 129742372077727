import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "api/auth";
import "styles/ForgotPassPage.scss";
import { successToast } from "utils/toasts";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email").trim();
    const res = await forgotPassword({ email: email });

    if (res?.data?.message) {
      setError(null);
      successToast();
      setTimeout(() => {
        navigate("/signin");
      }, 2000);
    } else {
      setError(res?.error?.data?.message || "Something went wrong");
    }
    return;
  };

  return (
    <Container component="main" maxWidth="xs" minWidth="md">
      <Helmet>
        <title>1511 Paris | Forgot Password</title>
        {/* <link rel="canonical" href="https://1511.paris/forgot" /> */}
        <meta
          name="keywords"
          content="Forgot Password, Reset Password, Password Recovery, Password Reset, 1511 Paris forgot password, 1511 password, 1511 reset, 1511 forgot password, Account Recovery, Account Password, Forgotten Password, Password Recovery Process, Password Help, Reset Password Link"
        />
        <meta
          name="description"
          content="Forget your password? No worries! We've got you covered. Welcome to our Forgot Password page. Enter your email address associated with your account, and we'll send you a password reset link. Regain access to your account and get back on track with your beauty journey. Don't let a forgotten password hold you back. Let us assist you in reclaiming your account. Reset your password now and continue exploring the world of beauty at 1511.paris."
        />
        <meta
          property="og:description"
          content="Forget your password? No worries! We've got you covered. Welcome to our Forgot Password page. Enter your email address associated with your account, and we'll send you a password reset link. Regain access to your account and get back on track with your beauty journey. Don't let a forgotten password hold you back. Let us assist you in reclaiming your account. Reset your password now and continue exploring the world of beauty at 1511.paris."
        ></meta>
      </Helmet>
      <Box className="login_container">
        <Avatar sx={{ m: 1, bgcolor: "#C4BBC6" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, width: "90%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{
              style: { color: "#C4BBC6" },
            }}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C4BBC6",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#C4BBC6",
                },
            }}
          />
          {error ? (
            <Box sx={{ margin: "20px 0px 0px 0px" }}>
              <Alert severity="error">{error}</Alert>
            </Box>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Box className="spinner">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontFamily: "Optima",
                backgroundColor: "#C4BBC6",
                color: "black",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#C4BBC6",
                },
              }}
            >
              Send
            </Button>
          )}
          <Box sx={{ display: "flex" }}>
            <button className="link_btn" onClick={() => navigate("/signin")}>
              Back to sign In
            </button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
