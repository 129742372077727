import React from "react";
import UserTable from "components/AdminPanel/Users/userTable";
import { useGetUsersQuery } from "api/users";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "./users.scss";

const Users = () => {
  const { data, isLoading, error } = useGetUsersQuery();

  return (
    <div className="user_tab_container">
      {isLoading ? (
        <div className="spinner">
          <Box className="category_table_loader">
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <Box sx={{ marginTop: 2.5 }}>
          <UserTable data={data} />
        </Box>
      )}
    </div>
  );
};

export default Users;
