import React from "react";
import AddBlogCategory from "components/AdminPanel/BlogCategories/AddBlogCategory";

const BlogCategories = () => {
  return (
    <div style={{ display: "flex", flex: 1, padding: "0 25px" }}>
      <AddBlogCategory />
    </div>
  );
};

export default BlogCategories;
