import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useModifyOrderMutation } from "api/orders";
import { toast } from "react-toastify";
import { cartErrorToasts, cartToasts } from "utils/toasts";
import { useUpdateReturnMutation } from "api/returns";

const StatusComponent = ({ params }) => {
  const [value, setValue] = React.useState("");
  useEffect(() => {
    const capitalizedStatus =
      params.value.charAt(0).toUpperCase() + params.value.slice(1);
    setValue(capitalizedStatus);
  }, [params]);

  const [modifyOrder, {}] = useUpdateReturnMutation();

  return (
    <div className="status" style={{ width: "150px" }}>
      <Select
        value={value}
        onChange={async (e) => {
          const consent = window.confirm(
            "Are you sure you want to change the status?"
          );
          if (consent) {
            modifyOrder({
              id: params.row.id,
              status: e.target.value.toLowerCase(),
            });
          }
        }}
        sx={{ width: "150px" }}
      >
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Confirmed">Confirmed</MenuItem>
        <MenuItem value="Refunded">Refunded</MenuItem>
        <MenuItem value="Rejected">Rejected</MenuItem>
      </Select>
    </div>
  );
};

export default StatusComponent;
