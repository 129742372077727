import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, CircularProgress, Pagination } from "@mui/material";
import StatusComponent from "./statusComponent";
import { useModifyOrderMutation } from "api/orders";

export default function PageSizeCustomOptions({
  allOrders,
  pageNumber,
  setPageNumber,
  limit,
  setLimit,
}) {
  let orders = allOrders?.orders || [];
  orders = orders.map((order, index) => {
    return {
      id: order?.id || index + 1,
      price: `${(order.price_total / 100).format(2, 3, " ", ",")} €`,
      user_id: order.userId,
      status: order.status,
      created_at: new Date(order.createdAt).toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
    };
  });

  const columns = [
    {
      id: 1,
      field: "id",
      headerName: "Transaction id",
      width: 150,
    },
    {
      id: 2,
      field: "price",
      headerName: "Price",
      width: 125,
    },
    {
      id: 3,
      field: "user_id",
      headerName: "User id",
      width: 250,
    },
    {
      id: 4,
      field: "status",
      headerName: "Status",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return <StatusComponent params={params} />;
      },
    },
    {
      id: 5,
      field: "created_at",
      headerName: "Created at",
      width: 185,
    },
    {
      id: 6,
      field: "action",
      headerName: "Action",
      width: 145,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              window.open(`/profile/orders/${params.row.id}`, "_blank");
            }}
            sx={{ alignSelf: "center" }}
          >
            Open
          </Button>
        );
      },
    },
  ];

  //PAGINATION
  const CustomPagination = (props) => {
    return (
      <div>
        <Pagination
          count={allOrders?.pages}
          page={pageNumber}
          onChange={(event, value) => {
            setPageNumber(value);
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="test_header"
      style={{
        flex: 1,
        display: "flex",
      }}
    >
      <DataGrid
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f9f9f9",
            fontFamily: "OptimaB",
          },
        }}
        rows={orders}
        columns={columns}
        components={{
          Pagination: CustomPagination,
        }}
        pageSize={limit}
      />
    </div>
  );
}
