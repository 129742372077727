import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetProductQuery, useGetSameSkuProductQuery } from "api/products";
import "components/Product/descriptionSection/DescriptionSection.scss";
import ProductButton from "components/Reusable/ProductButton";
import Facebook from "assets/icons/facebook";
import Pinterest from "assets/icons/pinterest";
import Mail from "assets/icons/mail";
import { useDispatch, useSelector } from "react-redux";
import { ADD_TO_CART } from "store/slices/CartSlice";
import { cartErrorToasts, cartToasts } from "utils/toasts";
import useLocalStorage from "utils/localStorageHelper";
import { useWishList } from "utils/hooks/useWishList";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useGetFragrancesQuery } from "api/perfumeFragrance";
import { Button } from "@mui/material";
import useNavigateToAdminPanel from "utils/hooks/useNavigateToAdminPanel";
import Modal from "components/Reusable/Modal/Modal";
//PERFUME SAMPLES

import FragranceSelect from "components/Reusable/FragranceSelect/FragranceSelect";
import AdminWishlistModal from "components/AdminWishlistModal/AdminWishlistModal";
import { v4 } from "uuid";

const Description = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { products } = useSelector((state) => state.cart);
  const [value, setValue] = useLocalStorage("cart", { products: [] });
  const [openSelector, setOpenSelector] = useState(false);
  const [colorSelector, setColorSelector] = useState(false);
  const [fragrances, setFragrances] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedSize, setSelectedSize] = useState(0);
  const [cartQuantity, setCartQuantity] = useState(0);
  const { category, subCategory, product_id } = useParams();
  const { data: productData, isLoading } = useGetProductQuery({ product_id });
  const [soldOut, setSoldOut] = useState(false);
  let product = productData?.product;
  const { navigateToAdminPanel } = useNavigateToAdminPanel(product);
  const {
    handleStarClick,
    isInWishList,
    isLoading: wishListLoading,
  } = useWishList(product);
  const { data: sameSkuProducts, isLoading: sameSkuProductLoading } =
    useGetSameSkuProductQuery({ id: product_id, sku: product.sku });

  //PERFUME SAMPLES
  const isPerfumeSample =
    product?.category === "Perfumes" && product?.perfumeSample > 0;
  const [perfumeModalOpen, setPerfumeModalOpen] = useState(false);

  //ALLOWED PRODUCT QUANTITY IN THE CART
  const maxQuantity =
    product?.size?.length > 1
      ? product?.size?.find((item) => item.value === Number(selectedSize))
          ?.quantity
      : product?.size?.length === 1 &&
        product?.size[0]?.value === Number(selectedSize)
      ? product?.size[0]?.quantity
      : null;

  // console.log("Is perfume sample", isPerfumeSample);
  // console.log("Is sold out", soldOut);
  // console.log("Product", product);
  // console.log("Cart quantity", cartQuantity);
  // console.log("selected size", selectedSize);
  // console.log("Max quantity", maxQuantity);

  //CHECK CART FOR THE QUANTITY OF THE PRODUCT BASED ON THE ID, SIZE and COLOR
  useEffect(() => {
    //Cart items quantity logic for perfume samples
    if (isPerfumeSample) {
      const itemFromCart = products
        ?.map((item) => {
          if (item?.id === product?.id) {
            return item;
          }
        })
        .filter((item) => item !== undefined);

      if (!itemFromCart || itemFromCart.length === 0) {
        setCartQuantity(0);
        return;
      } else {
        setCartQuantity(
          itemFromCart.reduce(
            (acc, item) => Number(acc) + Number(item.quantity),
            0
          )
        );
        return;
      }
    }

    //Cart items quantity logic for perfume
    if (product?.category === "Perfumes" && !isPerfumeSample) {
      const itemFromCart = products?.find((item) => item.id === product?.id);
      if (!itemFromCart) {
        setCartQuantity(0);
        return;
      } else {
        setCartQuantity(itemFromCart?.quantity);
        return;
      }
    }

    //Cart items quantity logic for other products
    const itemFromCart = products?.find(
      (item) => item.id === product?.id && item.selectedSize === selectedSize
    );
    if (!itemFromCart) {
      setCartQuantity(0);
      return;
    }

    if (itemFromCart?.selectedSize === selectedSize) {
      setCartQuantity(itemFromCart?.quantity);
    } else if (itemFromCart?.selectedSize !== selectedSize) {
      setCartQuantity(0);
    }
  }, [product, products, selectedSize, selectedColor]);

  // COLORS PREP
  useEffect(() => {
    const colors = [];
    colors.push(product.color);
    sameSkuProducts?.products?.map((product) => {
      colors.push(product.color);
    });

    setColors(colors?.filter((item) => item !== ""));
  }, [sameSkuProducts]);

  // DETAILS PREP
  const productDetails = product?.details.split("\n");
  // SIZE PREP
  const productSizes = product?.size
    ?.map((product) => product.value)
    .filter((item) => item !== 0);

  // NAVIGATE TO NEW PRODUCT IF COLOR CHANGED
  const navigateToNewProduct = (productColor) => {
    const productId = sameSkuProducts?.products?.find(
      (item) => item.color === productColor
    )?.id;

    if (productId && colors[0] !== productColor) {
      setSelectedSize(0);
      navigate(`/product/${category}/${subCategory}/${productId}`);
    }
  };

  //SETTING CART TO LOCAL STORAGE TO PERSIST DATA
  useEffect(() => {
    setValue({ products });
  }, [products]);

  const addToCart = () => {
    if (soldOut && !isPerfume) return cartErrorToasts("Item is sold out");
    if (soldOut && isPerfume) {
      setPerfumeModalOpen(true);
      return;
    }

    if (!selectedSize && productSizes?.length > 0)
      return cartErrorToasts("Please select size");
    if (fragrances.length < product?.perfumeSample && isPerfumeSample)
      return cartErrorToasts(
        `Please select ${product?.perfumeSample} ${
          product?.perfumeSample === 1 ? "fragrance" : "fragrances"
        }`
      );
    if (isPerfumeSample) product = { ...product, fragrances };

    dispatch(
      ADD_TO_CART({
        ...product,
        selectedSize: selectedSize || 0,
        selectedColor: selectedColor || colors[0],
      })
    );
    cartToasts("Item added");
  };
  const isPerfume = product?.category === "Perfumes";

  //SOLD OUT CONDITIONS
  useEffect(() => {
    const perfumeSampleCondition =
      product?.category === "Perfumes" && product?.perfumeSample > 0;
    const perfumeCondition =
      product?.category === "Perfumes" &&
      product?.size?.length > 0 &&
      product?.perfumeSample === 0;

    const clothingCondition = product?.category !== "Perfumes";

    //Perfume samples condition
    if (perfumeSampleCondition) {
      if (product?.size?.length == 1 && product?.size[0]?.quantity == 0) {
        setSoldOut(true);
      } else if (
        perfumeSampleCondition &&
        cartQuantity >= maxQuantity &&
        maxQuantity !== null
      ) {
        setSoldOut(true);
      } else {
        setSoldOut(false);
      }
      return;
    }

    //Perfume condition
    if (perfumeCondition) {
      if (product?.size?.length == 1 && product?.size[0]?.quantity == 0) {
        setSoldOut(true);
      } else if (
        perfumeCondition &&
        cartQuantity >= maxQuantity &&
        maxQuantity !== null
      ) {
        setSoldOut(true);
      } else {
        setSoldOut(false);
      }
      return;
    }

    //Rest of the products condition
    if (clothingCondition) {
      if (product?.size?.length == 1 && product?.size[0]?.quantity == 0) {
        setSoldOut(true);
      } else if (cartQuantity >= maxQuantity && maxQuantity !== null) {
        setSoldOut(true);
      } else {
        setSoldOut(false);
      }
    }
  }, [selectedSize, product?.id, cartQuantity]);

  //PERFUME FUNCTIONALITY SAMEPLES
  const { data: fragranceData, isLoading: fragranceLoading } =
    useGetFragrancesQuery();

  const listOfFragrances = fragranceData?.fragrances?.map(
    (fragrance) => fragrance?.shortDescription
  );

  //CHECK IF THE ITEM IS CLOTHING FOR FRENCH SIZING
  const params = useParams();
  const isClothing = params?.category.toLowerCase() === "clothing";

  //Admin wishlist modal
  const [open, setOpen] = React.useState(false);
  const handleStarClickWrapper = () => {
    if (user && user.role === "admin") {
      if (!open) {
        setOpen(true);
      }
    } else {
      if (!user)
        return cartErrorToasts("Please log in to add to wishlist", 2500);
      handleStarClick();
      return;
    }
  };

  const modalRef = React.useRef(null);

  return (
    <div className="product_details_wrapper">
      <Modal open={open} handleClose={() => setOpen(false)}>
        {/* Has to be wrapped in a fragment because of the Modal component */}
        <>
          <AdminWishlistModal item={product} />
        </>
      </Modal>
      {/* DESIGNER AND DESCRIPTION SECTION */}
      <div className="product_details_designer_section">
        <NavLink
          className="product_details_designer_link"
          to={`/designers/${product.designer}`}
        >
          <h2
            style={{
              color: product?.webColorSchema || "#000",
              fontFamily: "Henderson",
            }}
          >
            {product.designer.toUpperCase()}
          </h2>
        </NavLink>
        <h1>{product?.shortDescription}</h1>
        {/* PRICE AND GUIDE SECTION */}
        <div className="product_details_price_section">
          <div className="designer_details_price_wrapper">
            <p className="designer_details_price_text">
              {product.finalPrice.format(2, 3, " ", ",")} €
            </p>
          </div>
        </div>
      </div>
      {/*FRAGRANCE SELECT SECTION */}
      <div className="product_details_buttons_section">
        {isPerfumeSample ? (
          <FragranceSelect
            listOfFragrances={listOfFragrances}
            limit={product?.perfumeSample}
            fragrances={fragrances}
            setFragrances={setFragrances}
          />
        ) : null}

        <div
          style={{
            marginTop:
              colors?.length > 1 || productSizes?.length > 0 ? "0.8rem" : 0,
            display: "flex",
          }}
        >
          {/* SIZE COLOR */}
          {colors?.length > 1 ? (
            <ProductButton
              value={"Choose color"}
              data={colors}
              openSelector={colorSelector}
              onPress={(openState, value) => {
                setColorSelector(openState);
                if (value) setSelectedColor(value);
              }}
              style={{
                width: "100%",
              }}
              mainContainerStyle={{ marginRight: "1%", width: "51%" }}
              navigateToNewProduct={navigateToNewProduct}
            />
          ) : null}
          {/* SIZE SELECT */}
          {productSizes?.length > 0 ? (
            <ProductButton
              value={"Select size"}
              data={productSizes}
              openSelector={openSelector}
              onPress={(openState, value) => {
                setOpenSelector(openState);
                if (value) setSelectedSize(String(value));
              }}
              style={{ width: "100%" }}
            />
          ) : null}
        </div>

        {/* WISH AND BAG BUTTONS */}
        <div style={{ marginTop: "0.8rem", display: "flex" }}>
          <ProductButton
            value={isInWishList ? "Remove" : "Add to wish list"}
            image={
              wishListLoading ? "loading" : isInWishList ? "filledstar" : "star"
            }
            mainContainerStyle={{ marginRight: "1%", width: "51%" }}
            onPress={() => handleStarClickWrapper()}
          />
          <ProductButton
            value={
              soldOut && isPerfume
                ? "Place Order"
                : soldOut
                ? "Out of stock"
                : `Add to cart ${
                    maxQuantity ? `(${maxQuantity - cartQuantity})` : ""
                  }`
            }
            image={"shopping"}
            mainContainerStyle={{
              backgroundColor: product?.webColorSchema || "#000",
              borderColor: product?.webColorSchema || "#000",
              color: "#fff",
            }}
            onPress={addToCart}
          />
        </div>
      </div>

      {/* DESCRIPTION SECTION */}
      <div className="product_details_description_section">
        <div>
          {/* <h6 style={{ textTransform: "capitalize" }}>STORY</h6> */}
          <p style={{ textAlign: "justify" }}>{product?.longDescription}</p>
        </div>
        <div style={{ marginTop: "1.25rem" }}>
          <h6>Details</h6>
          {productDetails?.map((detail) => {
            return <p key={v4()}>{detail}</p>;
          })}
        </div>
        <div style={{ marginTop: "1.25rem" }}>
          {isClothing ? (
            <div style={{ display: "flex" }}>
              <p style={{ marginRight: "4px" }}>French sizing - </p>
              <NavLink to="#">
                <p>View size guide</p>
              </NavLink>
            </div>
          ) : null}
          <p className="designer_details_sku_text">
            Product Code: {product?.sku}
          </p>
          {/* <h6 style={{ textTransform: "capitalize" }}>
            SIZE & FIT INFORMATION
          </h6>*/}
        </div>
        {/* <div className="designer_details_guide_wrapper">
          <NavLink to="/">
            <p>View size guide</p>
          </NavLink>
          <button>contact advisor</button>
        </div> */}
        <div className="product_details_social_icons_container">
          <div>
            <Facebook
              style={{
                width: "20px",
                height: "20px",
              }}
              fill="#5c5c60"
            />
          </div>
          <div>
            <Pinterest
              style={{ width: "25px", height: "25px" }}
              fill="#5c5c60"
            />
          </div>
          <div>
            <Mail style={{ width: "22px", height: "22px" }} fill="#5c5c60" />
          </div>
          <a target={"_blank"} href="https://www.instagram.com/1511.paris/">
            <InstagramIcon />
          </a>
        </div>

        {user?.role === "admin" ? (
          <div style={{ marginTop: "2rem" }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                navigateToAdminPanel(e);
              }}
              variant="contained"
            >
              Show in admin panel
            </Button>
          </div>
        ) : null}
        <Modal
          open={perfumeModalOpen}
          handleClose={() => setPerfumeModalOpen(false)}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={(event) => {
              if (!modalRef.current.contains(event.target)) {
                setPerfumeModalOpen(false);
              }
            }}
          >
            <div
              ref={modalRef}
              style={{
                backgroundColor: "white",
                padding: "1rem 2rem",
                maxWidth: "500px",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                borderRadius: "2px",
              }}
            >
              <h3 style={{ textAlign: "center" }}>Place your order</h3>
              <p>
                We regret to inform you that the perfume you desire is currently
                unavailable.
              </p>
              <p>
                However, we are delighted to offer you the opportunity to place
                an order, which we will promptly fulfill.
              </p>
              <p>
                To proceed, please email us at{" "}
                <a href="mailto:advisor@1511.paris">advisor@1511.paris</a>,
                specifying the name of the perfume and the desired quantity.
                Additionally, please provide your full name and complete
                shipping address details. Thank you for your understanding and
                cooperation.
              </p>
              <ProductButton
                value={"Copy email address"}
                mainContainerStyle={{
                  border: "1px solid rgba(0, 0, 0, 0.5)",
                  backgroundColor: "#fff",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#000",
                  marginTop: "1rem",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onPress={() => {
                  navigator.clipboard
                    .writeText("advisor@1511.paris")
                    .finally(() => {
                      cartToasts("Email copied to clipboard");
                    });
                }}
              />
              <ProductButton
                value={"Close"}
                mainContainerStyle={{
                  backgroundColor: "#000",
                  borderColor: "#000",
                  height: "40px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  marginTop: "0.25rem",
                  cursor: "pointer",
                  userSelect: "none",
                }}
                onPress={() => setPerfumeModalOpen(false)}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Description;
