import React from "react";
import "components/AdminWishlistModal/AdminWishlistModal.scss";
import { useSelector } from "react-redux";
import { useGetActiveWishList } from "utils/hooks/useGetActiveWishList";
import { useWishList } from "utils/hooks/useWishList";
import { CircularProgress } from "@mui/material";

const AdminWishlistModal = ({ item }) => {
  const { wishList } = useGetActiveWishList();
  const { handleStarClick, isLoading } = useWishList(item);
  const [tempId, setTempId] = React.useState(null);

  const handleItem = (listId) => {
    setTempId(listId);
    handleStarClick(listId);
  };

  return (
    <div className="admin_modal_container">
      <h5>Choose the wishlist</h5>
      {wishList?.map((wishlist) => {
        const isItemInWishlist = wishlist?.products?.find(
          (product) => product.id === item?.id
        );
        return (
          <div key={wishList?._id} className="admin_modal_wishlist_wrapper">
            <p>{wishlist.name}</p>
            <span>items:{wishlist?.products?.length}</span>
            {isLoading && wishlist?._id === tempId ? (
              <CircularProgress />
            ) : (
              <button
                onClick={() => handleItem(wishlist?._id)}
                className={
                  isItemInWishlist
                    ? "admin_modal_wishlist_remove_btn"
                    : "admin_modal_wishlist_add_btn"
                }
              >
                {isItemInWishlist ? "Remove" : "Choose"}
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AdminWishlistModal;
