import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ITEM_UPDATE } from "store/slices/NewProductItemSlice";
import { SET_PRODUCT } from "store/slices/NewProductSlice";

const useNavigateToAdminPanel = (product) => {
  //SETTING ITEM IN REDUX TO POPULATE IT IN ADMIN PANEL
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToAdminPanel = (e) => {
    e.stopPropagation();

    if (user.role === "admin") {
      const newProduct = () => ({
        sku: product.sku,
        category: product.category,
        designer: product.designer,
        season: product.season,
        vat: product.vat,
        comingSoon: product.comingSoon,
        shippingInfo: product.shippingInfo,
        dressCode: product.dressCode,
      });
      const {
        sku,
        category,
        designer,
        season,
        vat,
        comingSoon,
        shippingInfo,
        dressCode,
        ...rest
      } = product;
      const newProductItem = () => ({
        update: true,
        ...rest,
      });
      dispatch(SET_PRODUCT(newProduct()));
      dispatch(ITEM_UPDATE(newProductItem()));

      navigate(`/adminpanel/products/add/${product.id}`);
    }
  };

  return { navigateToAdminPanel };
};

export default useNavigateToAdminPanel;
