import React, { useEffect, useState } from "react";
import "components/TopBanner/TopBanner.scss";
import { motion } from "framer-motion";

const TopBanner = ({ items }) => {
  const [activeText, setActiveText] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeText === items.length - 1) setActiveText(0);
      else setActiveText(activeText + 1);
    }, 5000);

    return () => clearInterval(interval);
  }, [activeText]);

  const dropIn = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 100,
      opacity: 0,
    },
  };
  return (
    <div className="top_banner_container">
      <motion.span
        key={activeText}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {items[activeText]}
      </motion.span>
    </div>
  );
};

export default TopBanner;
