import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "store/slices/UserSlice";
import CategorySlice from "store/slices/CategorySlice";
import NewProductSlice from "store/slices/NewProductSlice";
import NewProductItemSlice from "store/slices/NewProductItemSlice";
import CartSlice from "store/slices/CartSlice";
import BlogsSlice from "store/slices/Blogs";

//API CALLS RTK QUERY
import { categoriesApi } from "api/categories";
import { designersApi } from "api/designers";
import { authApi } from "api/auth";
import { usersApi } from "api/users";
import { productsApi } from "api/products";
import { dashboardApi } from "api/dashboard";
import { cartApi } from "api/cart";
import { ordersApi } from "api/orders";
import { wishlistApi } from "api/wishlist";
import { topBannerApi } from "api/topBanner";
import { fragranceApi } from "api/perfumeFragrance";
import { merchandisingApi } from "api/merchandising";
import { returnsApi } from "api/returns";
import { homeModulesApi } from "api/homeModules";
import { suggestionsApi } from "api/suggestions";

export const store = configureStore({
  reducer: {
    user: UserSlice,
    category: CategorySlice,
    newProduct: NewProductSlice,
    newProductItem: NewProductItemSlice,
    cart: CartSlice,
    blogs: BlogsSlice,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [designersApi.reducerPath]: designersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [cartApi.reducerPath]: cartApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [wishlistApi.reducerPath]: wishlistApi.reducer,
    [topBannerApi.reducerPath]: topBannerApi.reducer,
    [fragranceApi.reducerPath]: fragranceApi.reducer,
    [merchandisingApi.reducerPath]: merchandisingApi.reducer,
    [returnsApi.reducerPath]: returnsApi.reducer,
    [homeModulesApi.reducerPath]: homeModulesApi.reducer,
    [suggestionsApi.reducerPath]: suggestionsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      categoriesApi.middleware,
      designersApi.middleware,
      authApi.middleware,
      usersApi.middleware,
      productsApi.middleware,
      dashboardApi.middleware,
      cartApi.middleware,
      ordersApi.middleware,
      wishlistApi.middleware,
      topBannerApi.middleware,
      fragranceApi.middleware,
      merchandisingApi.middleware,
      returnsApi.middleware,
      homeModulesApi.middleware,
      suggestionsApi.middleware
    ),
});
