import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => `/admin/dashboard`,
      method: "GET",
      providesTags: ["Dashboard"],
    }),
  }),
});

export const { useGetDashboardQuery } = dashboardApi;
