import React, { useEffect, useState } from "react";
import Routes from "../routes";
import NavBar from "../components/Navbar/NavBar";
import Footer from "components/Footer/Footer";
import "layout/Layout.scss";
import TopBanner from "components/TopBanner/TopBanner";
import TermsBanner from "components/TermsBanner/TermsBanner";
import { useGetTopBannerQuery } from "api/topBanner";
import { Helmet } from "react-helmet";

const Layout = () => {
  const pagesForTopBanner = [];
  const [bannerText, setBannerText] = useState([]);
  const { data: topBanner, isLoading } = useGetTopBannerQuery();

  return (
    <div className="layout_container">
      <Helmet>
        <title>1511 Paris</title>
        {/* <link rel="canonical" href="https://1511.paris" /> */}
      </Helmet>
      {/* TOP */}
      {!pagesForTopBanner.includes(window.location.pathname) &&
        topBanner?.enabled && <TopBanner items={topBanner?.text} />}
      {/* <TopBanner /> */}
      <NavBar />
      <div style={{ flex: 1 }}>
        {/* MAIN CONTENT */}
        <Routes />
      </div>

      {/* FOOTER */}
      <Footer />

      <TermsBanner />
    </div>
  );
};

export default Layout;
