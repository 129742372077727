import React from "react";
import "./dashboard.scss";
import { useGetDashboardQuery } from "api/dashboard";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { upperCaseFirstLetter } from "utils/upperCaseFirstLetter";

const Dashboard = () => {
  const { data: dashboardInfo, isLoading, isError } = useGetDashboardQuery();
  const navigate = useNavigate();

  if (!dashboardInfo || isLoading)
    return (
      <Box className="category_table_loader">
        <CircularProgress />
      </Box>
    );
  return (
    <div className="dashboard_card_wrapper">
      {Object?.keys(dashboardInfo)?.map((key) => {
        const link = `/adminpanel/${key}`;
        if (key === "totalSales")
          return (
            <div
              key={key}
              style={{ cursor: "auto" }}
              className="card_container"
            >
              <h2 style={{ margin: 0 }}>
                ${dashboardInfo[key][0]?.total || 0}
              </h2>
              <p style={{ margin: "0 0 10px 0" }}>
                {upperCaseFirstLetter(key)}
              </p>
            </div>
          );
        return (
          <div
            onClick={() => navigate(`/adminpanel/${key}`)}
            key={key}
            className="card_container"
          >
            <h2 style={{ margin: 0 }}>{dashboardInfo[key]}</h2>
            <p style={{ margin: "0 0 10px 0" }}>{upperCaseFirstLetter(key)}</p>
            <a href={link}>View all {key}</a>
          </div>
        );
      })}
    </div>
  );
};

export default Dashboard;

// <div style={{ display: "flex" }}>
//   <div className="card_container">
//     <h2 style={{ margin: 0 }}>327</h2>
//     <p style={{ margin: "0 0 10px 0" }}>Users</p>
//     <a href="/">View all users</a>
//   </div>
//   <div className="card_container">
//     <h2 style={{ margin: 0 }}>102</h2>
//     <p style={{ margin: "0 0 10px 0" }}>Orders</p>
//     <a href="/">View all orders</a>
//   </div>
//   <div className="card_container">
//     <h2 style={{ margin: 0 }}>$12,034</h2>
//     <p style={{ margin: "0 0 10px 0" }}>Sales</p>
//     <a href="/">View sales</a>
//   </div>
// </div>
