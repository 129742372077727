import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { SET_PRODUCT } from "store/slices/NewProductSlice";
import Box from "@mui/material/Box";

const Toggles = () => {
  const dispatch = useDispatch();
  const { dressCode } = useSelector((state) => state.newProduct);
  const dressCodeItems = [
    "cocktail",
    "black tie",
    "white tie",
    "resort",
    "smart casual",
    "business",
  ];

  const handleChange = (event) => {
    if (event.target.checked === false) {
      const newDressCode = dressCode.filter(
        (item) => item !== event.target.name
      );
      dispatch(
        SET_PRODUCT({
          dressCode: newDressCode,
        })
      );
    } else {
      dispatch(
        SET_PRODUCT({
          dressCode: [...dressCode, event.target.name],
        })
      );
    }
  };

  return (
    <div>
      <Box sx={{ boxShadow: 2 }} className="toggle_container">
        <FormControl component="fieldset" variant="standard">
          <FormControlLabel
            control={
              <Switch
                checked={dressCode?.notAvailable}
                onChange={handleChange}
                name="notAvailable"
              />
            }
            label="Coming soon if unavailable"
            className="toggle_container_coming_soon"
          />
          <h5>DressCode</h5>
          <FormGroup
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            {dressCodeItems && dressCodeItems.length > 0
              ? dressCodeItems?.map((item) => {
                  return (
                    <FormControlLabel
                      key={item}
                      control={
                        <Switch
                          checked={dressCode[item]}
                          onChange={handleChange}
                          name={item}
                        />
                      }
                      label={item}
                      sx={{ fontWeight: "bold", fontSize: "16px" }}
                    />
                  );
                })
              : null}
          </FormGroup>
        </FormControl>
      </Box>
    </div>
  );
};

export default Toggles;
