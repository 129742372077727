import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const suggestionsApi = createApi({
  reducerPath: "suggestionsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Suggestions"],

  endpoints: (builder) => ({
    getSuggestions: builder.query({
      query: ({ category, productId }) =>
        `/suggestions/${category}/${productId}`,
      method: "GET",
      providesTags: ["Suggestions"],
      refetchOnMountOrArgChange: 900,
      keepUnusedDataFor: 900,
    }),
  }),
});

export const { useGetSuggestionsQuery } = suggestionsApi;
