import React, { useState, useEffect } from "react";
import "components/TermsBanner/TermsBanner.scss";
import CloseIcon from "@mui/icons-material/Close";

const TermsBanner = () => {
  const [showTermsBanner, setShowTermsBanner] = useState(true);
  const termsAndConditions = localStorage.getItem("termsAndConditions");
  const handleBannerClose = () => {
    localStorage.setItem("termsAndConditions", true);
    setShowTermsBanner(false);
  };

  //   AUTO BANNER HIDE ON PAGE LOAD IF TERMS ARE TRUE
  useEffect(() => {
    if (termsAndConditions === "true") setShowTermsBanner(false);
  }, [termsAndConditions]);
  return (
    <div
      className="terms_banner"
      style={{ display: showTermsBanner ? "block" : "none" }}
    >
      <div className="terms_banner_container">
        <div className="terms_banner_wrapper">
          <span>
            By continuing to browse on this site, you agree the use of Cookies
            or other trackers in order to ensure your optimal browsing, the
            possibility of sharing content on social networks, as well as
            services and offers suited to your areas of interest.
          </span>
          <div onClick={handleBannerClose} className="terms_close_btn">
            <CloseIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsBanner;
