import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Pagination } from "@mui/material";
import StatusComponent from "./statusComponent";

export default function PageSizeCustomOptions({
  allReturns,
  pageNumber,
  setPageNumber,
  limit,
  setLimit,
  getTheOrder,
}) {
  let returns = allReturns?.returns || [];

  returns = returns.map((item, index) => {
    return {
      id: item?._id || index + 1,
      price: `${item?.amount.format(2, 3, " ", ",")} €`,
      user_id: item.userId,
      status: item.status,
      created_at: new Date(item.createdAt).toLocaleString({
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }),
      orderId: item.orderId,
    };
  });

  const columns = [
    {
      id: 1,
      field: "id",
      headerName: "Return id",
      width: 200,
    },
    {
      id: 2,
      field: "price",
      headerName: "Price",
      width: 125,
    },
    {
      id: 3,
      field: "user_id",
      headerName: "User id",
      width: 250,
    },
    {
      id: 4,
      field: "status",
      headerName: "Status",
      type: "actions",
      width: 150,
      renderCell: (params) => {
        return <StatusComponent params={params} />;
      },
    },
    {
      id: 5,
      field: "created_at",
      headerName: "Created at",
      width: 185,
    },
    {
      id: 6,
      field: "action",
      headerName: "Action",
      width: 145,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              getTheOrder(params.row.orderId, params.row.id);
            }}
            sx={{ alignSelf: "center" }}
          >
            Open
          </Button>
        );
      },
    },
  ];

  //PAGINATION
  const CustomPagination = (props) => {
    return (
      <div>
        <Pagination
          count={allReturns?.pages}
          page={pageNumber}
          onChange={(event, value) => {
            setPageNumber(value);
          }}
        />
      </div>
    );
  };

  return (
    <div
      className="test_header"
      style={{
        flex: 1,
        display: "flex",
      }}
    >
      <DataGrid
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f9f9f9",
            fontFamily: "OptimaB",
          },
        }}
        rows={returns}
        columns={columns}
        components={{
          Pagination: CustomPagination,
        }}
        pageSize={limit}
      />
    </div>
  );
}
