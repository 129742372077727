import { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useModifyOrderMutation } from "api/orders";
import { cartErrorToasts, cartToasts } from "utils/toasts";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import OrderStatusChange from "components/Modals/OrderStatusChange";

const StatusComponent = ({ params }) => {
  const capitalizedStatus =
    params.value.charAt(0).toUpperCase() + params.value.slice(1);
  const [value, setValue] = useState(capitalizedStatus);
  const [modifyOrder, { isLoading }] = useModifyOrderMutation();
  const { email: adminEmail } = useSelector((state) => state.user);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);

  const modifyOrderStatus = async (e, form) => {
    const consent = window.confirm(
      "Are you sure you want to change the status?"
    );
    if (consent) {
      const { tracking_number, tracking_url } = form || {};
      if (isStatusChangeModalOpen) setIsStatusChangeModalOpen(false);
      const res = await modifyOrder({
        id: params.row.id,
        status: e.target.value.toLowerCase(),
        tracking_number,
        tracking_url,
        adminEmail,
      });
      if (res?.data?.status === 200 || res?.status === 200) {
        cartToasts("Status changed successfully");
        setValue(e.target.value);
      } else if (res?.error?.data?.message === "EMAIL_NOT_SENT") {
        cartErrorToasts(
          "Status changed successfully but email not sent to the user due to error.",
          5000
        );
        setValue(e.target.value);
      } else {
        cartErrorToasts("Something went wrong");
      }
    }
  };

  return (
    <div className="status" style={{ width: "150px" }}>
      {!isLoading ? (
        <Select
          value={value}
          disabled={isLoading}
          onChange={async (e) => {
            if (e.target.value === "Shipped") {
              setIsStatusChangeModalOpen(true);
            } else {
              await modifyOrderStatus(e);
            }
          }}
          sx={{ width: "150px" }}
        >
          <MenuItem value="Confirmed">
            <span>Confirmed</span>
          </MenuItem>
          <MenuItem value={"Shipped"}>Shipped</MenuItem>
          <MenuItem value={"Refunded"}>Refunded</MenuItem>
          <MenuItem value={"Pickup"}>Pickup</MenuItem>
        </Select>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress size={22} />
        </div>
      )}
      <OrderStatusChange
        isModalOpen={isStatusChangeModalOpen}
        setIsModalOpen={setIsStatusChangeModalOpen}
        modifyOrderStatus={modifyOrderStatus}
      />
    </div>
  );
};

export default StatusComponent;
