import { useEffect, useState } from "react";
import "pages/profile/OrdersPage.scss";
import { useGetOrdersQuery } from "api/orders";
import { CircularProgress } from "@mui/material";
import { compareAsc, format } from "date-fns";
import { Helmet } from "react-helmet";
import useWindowDimensions from "utils/windowWidth";
import Modal from "components/Reusable/Modal/Modal";
import ReturnItemModal from "pages/profile/ReturnItemModal";

const OrderPage = () => {
  let { data, isLoading, isError } = useGetOrdersQuery();
  const [open, setOpen] = useState(false);
  const [returnItem, setReturnItem] = useState(false);
  const { width } = useWindowDimensions();

  const renderOrders = () => {
    if (isLoading) {
      return (
        <div style={{ flex: 1, textAlign: "center", marginTop: "2rem" }}>
          <CircularProgress />
        </div>
      );
    }

    if (isError) {
      return (
        <div style={{ flex: 1, textAlign: "center", marginTop: "2rem" }}>
          There is an error loading your orders
        </div>
      );
    }

    if (!data?.length)
      return (
        <div style={{ flex: 1, textAlign: "center", marginTop: "2rem" }}>
          You have no orders to display
        </div>
      );

    return (
      <div className="orders_table_items_container">
        <Helmet>
          <title>1511 Paris | Orders</title>
          {/* <link rel="canonical" href="https://1511.paris/profile/orders" /> */}
          <meta
            name="keywords"
            content="1511 Paris Orders, 1511 Paris My Orders, My Orders, Account Orders, Order History, Purchase History, Delivery Status, Order Status"
          />
          <meta
            name="description"
            content="Welcome to your My Orders page! Easily track and manage your beauty product orders in one convenient location. Stay up-to-date with the status of your purchases, view order details, and access shipment information. Take control of your shopping experience and enjoy the peace of mind that comes with effortless order management. Dive into your order history and explore the world of beauty delivered right to your doorstep at 1511.paris."
          />
          <meta
            property="og:description"
            content="Welcome to your My Orders page! Easily track and manage your beauty product orders in one convenient location. Stay up-to-date with the status of your purchases, view order details, and access shipment information. Take control of your shopping experience and enjoy the peace of mind that comes with effortless order management. Dive into your order history and explore the world of beauty delivered right to your doorstep at 1511.paris."
          ></meta>
        </Helmet>
        {data?.map((order) => (
          <div key={order.id} className="orders_table_items">
            <div className="orders_table_item">
              <p>{format(new Date(order.createdAt), "dd MMM yyy, kk:mm")}</p>
            </div>
            <div>
              <a href={`/profile/orders/${order.id}`}>{order.id}</a>
            </div>
            {width > 768 ? (
              <div>
                <p>{order.status}</p>
              </div>
            ) : null}
            <div>
              <button
                onClick={() => {
                  setReturnItem(order);
                  setOpen(true);
                }}
              >
                Return
              </button>
            </div>
            <div>
              <p>{(order.price_total / 100).format(2, 3, " ", ",")} €</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="orders_page_container">
        <h1 style={{ textAlign: "center" }}>MY ORDERS</h1>
        <p>
          Click on an order number below to view details or make a reutn. You
          have 28 days from receiving your order to send your purchase back to
          us for an exchange or refund
        </p>
        {/* ORDERS TABLE */}
        <div className="orders_table_container">
          {/* HEADER */}
          <div className="orders_table_header">
            <div>
              <p>DATE</p>
            </div>
            <div>
              <p>ORDER ID</p>
            </div>
            {width > 768 ? (
              <div>
                <p>STATUS</p>
              </div>
            ) : null}
            <div>
              <p>Return</p>
            </div>
            <div>
              <p>Amount</p>
            </div>
          </div>
          {/* ORDERS */}
          {renderOrders()}
        </div>
      </div>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div>
          <ReturnItemModal returnItem={returnItem} setOpen={setOpen} />
        </div>
      </Modal>
    </>
  );
};

export default OrderPage;
