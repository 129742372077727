import React from "react";
import DesignerTable from "./DesignerTable";
import AddDesigner from "./AddDesigner";
import {
  useGetDesignersQuery,
  useAddDesignerMutation,
  useDeleteDesignerMutation,
  useUpdateDesignerMutation,
} from "../../../api/designers";
import "./designers.scss";

const Categories = () => {
  const [rows, setRows] = React.useState([]);
  //API CALLS
  const { data: designers, isLoading } = useGetDesignersQuery();
  const [addDesigner, { isLoading: addLoading, isError: addError }] =
    useAddDesignerMutation();
  const [deleteDesigner, { isLoading: deleteLoading, isError: deleteError }] =
    useDeleteDesignerMutation();
  const [updateDesigner, { isError: updateError, isLoading: updateLoading }] =
    useUpdateDesignerMutation();

  //Shape the list of designers for render
  const rowItems = async (createData) => {
    if (designers?.length) {
      setRows(
        designers.map((item) =>
          createData(item.name, item.createdAt, item.biography, item._id)
        )
      );
    }
  };

  return (
    <div className="designers_tab_container">
      <AddDesigner
        rows={rows}
        addDesigner={addDesigner}
        addLoading={addLoading}
        addError={addError}
      />
      <DesignerTable
        rowItems={rowItems}
        designers={designers}
        rows={rows}
        deleteLoading={deleteLoading}
        deleteDesigner={deleteDesigner}
        addLoading={addLoading}
        isLoading={isLoading}
        deleteError={deleteError}
        updateDesigner={updateDesigner}
        updateLoading={updateLoading}
        updateError={updateError}
      />
    </div>
  );
};

export default Categories;
