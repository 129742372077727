import React, { useState, useEffect } from "react";
import "pages/profile/ProfilePage.scss";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { USER_LOGOUT } from "store/slices/UserSlice";
import { Helmet } from "react-helmet";

const ProfilePage = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const signOut = () => {
    dispatch(USER_LOGOUT());
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
  };
  return (
    <div className="profile_container">
      <Helmet>
        <title>1511 Paris | My Account</title>
        {/* <link rel="canonical" href="https://1511.paris/profile/account" /> */}
        <meta
          name="keywords"
          content="1511 Paris, 1511 Paris Account, 1511 Paris My Account, My Account, Account Dashboard, User Profile, Account Management, Order Tracking, Preferences"
        />
        <meta
          name="description"
          content="Welcome to your My Account page! This personalized hub is designed exclusively for you. Manage your beauty profile, track your orders, update your preferences, and explore tailored recommendations. Take control of your beauty journey and unlock a world of convenience and customization. Your account is your gateway to a seamless and delightful shopping experience at 1511.paris."
        />
        <meta
          property="og:description"
          content="Welcome to your My Account page! This personalized hub is designed exclusively for you. Manage your beauty profile, track your orders, update your preferences, and explore tailored recommendations. Take control of your beauty journey and unlock a world of convenience and customization. Your account is your gateway to a seamless and delightful shopping experience at 1511.paris."
        ></meta>
      </Helmet>
      <h1>MY ACCOUNT</h1>
      <h4>
        Welcome{" "}
        <strong>
          {user?.firstName} {user?.lastName}
        </strong>
      </h4>
      <div className="profile_menu">
        <span>
          <NavLink to="/profile/orders">
            <p>YOUR 1511 ORDERS</p>
          </NavLink>
          <p>Track your order or arrange an exchange or return</p>
        </span>
        <span>
          <NavLink to="/profile/returns">
            <p>RETURNS</p>
          </NavLink>
          <p>Monitor status of your returns</p>
        </span>
        <span>
          <NavLink to="/profile/address">
            <p>YOUR ADDRESS</p>
          </NavLink>
          <p>Edit your address preference for delivery</p>
        </span>
        <span>
          <NavLink to="/profile/wishList">
            <p>WISH LIST</p>
          </NavLink>
          <p>Keep track of your preferences and see when they are in store</p>
        </span>
        <span>
          <NavLink to="/profile/details">
            <p>ACCOUNT DETAILS</p>
          </NavLink>
          <p>If you need to modify your sign in information</p>
        </span>
        <button onClick={signOut}>Sign out</button>
      </div>
    </div>
  );
};

export default ProfilePage;
