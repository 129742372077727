import React, { useEffect, useState } from "react";
import "components/Reusable/Filters/children/SizeFilter.scss";
import { useLazyGetProductSizeQuery } from "api/products";
import { useParams, useSearchParams } from "react-router-dom";

const SizeFilter = () => {
  const { category, subCategory, designer } = useParams();
  const [hideFilter, setHideFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  //To get all sizes of products
  const [getProductSizes, { data: sizes, isLoading, isError, error }] =
    useLazyGetProductSizeQuery();
  useEffect(() => {
    getProductSizes({ category, subCategory, designer });
  }, [category, subCategory]);

  //To get the size query from the url
  const sizeParams = searchParams.get("size");
  const splitValues = sizeParams?.split(",");
  //Other than size, to add to the filters below
  const allOtherQueris = {};
  searchParams.forEach((value, key) => {
    if (key !== "size") {
      allOtherQueris[key] = value;
    }
  });

  //IF THERE IS NO SIZES, RETURN NOTHING
  if (sizes?.sizes?.length === 0) return null;
  let filteredArray = [];
  if (sizes?.sizes && sizes?.sizes?.length > 0) {
    filteredArray = [...sizes?.sizes];
    filteredArray.sort((a, b) => a - b);
  }

  return (
    <div className="size_filter_container">
      <p
        onClick={() => setHideFilter(!hideFilter)}
        className="size_filter_title"
      >
        Size
      </p>
      {!hideFilter ? (
        <div className="size_filter_container">
          {filteredArray?.map((size) => {
            return (
              <div key={size} className="size_filter_wrapper">
                <input
                  checked={splitValues?.includes(String(size))}
                  className="filter_checkbox"
                  type="checkbox"
                  id={size}
                  onClick={(e) => {
                    if (splitValues?.includes(e.target.id)) {
                      //If we removing last size, then remove the entire size query from the url
                      if (splitValues?.length === 1) {
                        setSearchParams({ ...allOtherQueris });
                        return;
                      } else {
                        //If we removing size and it's not the last size item, we remove only that item
                        setSearchParams({
                          ...allOtherQueris,
                          size: sizeParams
                            ?.split(",")
                            .filter((item) => item !== e.target.id)
                            .join(","),
                        });
                        return;
                      }
                    } else {
                      //If we adding a new size and it doesn't exists on the url query
                      setSearchParams({
                        ...allOtherQueris,
                        size: sizeParams
                          ? sizeParams + "," + e.target.id
                          : e.target.id,
                      });
                      return;
                    }
                  }}
                />
                <label htmlFor={size}>{size}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SizeFilter;
