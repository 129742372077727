export const regValidation = async (user) => {
  if (user.password !== user.confirmPassword)
    return Promise.resolve("Passwords don't match");
  if (user.password.length < 6)
    return Promise.resolve("Password must be at least 6 characters long.");
  if (user.firstName.length < 2)
    return Promise.resolve("First name must be at least 2 characters long.");
  if (user.lastName.length < 2)
    return Promise.resolve("Last name must be at least 2 characters long.");
  if (user.email.includes("@") === false)
    return Promise.resolve("Invalid email");
};
