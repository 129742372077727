import React from "react";
import "styles/About.scss";

const About = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const clcHeight = screenHeight / screenWidth;

  return (
    <div
      // style={{ height: (screenHeight / 1.5) * clcHeight }}
      className="about_container"
    >
      <h1>
        Les Suites, known for creating and curating shopping in an haute way,
        metamorphoses into 1511.
      </h1>
      <h2>
        With the goal to nurture creativity, 1511 is dedicated to the diverse
        world of Art and Design.
        <br /> Under the creative direction of the founder Eka Iukuridze,
        <br /> 1511 brings together unique artisans from around the world to
        form multidisciplinary collaborations.
      </h2>
      <h2>
        Each artistic project gives rise to an exceptional sensorial experience,
        be it a poetic film, a sculpture, or a perfume.
        <br /> 1511 invites to discover one of a kind pieces, as well as the
        most extraordinary of the world’s fashion and decor.
      </h2>
    </div>
  );
};

export default About;
