import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ITEM_UPDATE } from "store/slices/NewProductItemSlice";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import ShoeSize from "components/AdminPanel/AddProduct/Product/ShoeSize";

const ColorOthersSwitch = ({ item }) => {
  const dispatch = useDispatch();
  const itemUpdate = (fieldName, value, item) => {
    let obj = {};
    obj[fieldName] = value;
    obj.id = item.id;
    dispatch(ITEM_UPDATE(obj));
  };

  return (
    <>
      <div className="color_other_switches_container">
        <div>
          <div>
            <h6 className="dropdowns_label" htmlFor="sku">
              Color
            </h6>
          </div>
          <TextField
            value={item?.color}
            onChange={(e) =>
              itemUpdate(
                "color",
                e.target.value <= 1
                  ? e.target.value
                  : e.target.value[0].toUpperCase() +
                      e.target.value.slice(1).toLowerCase(),
                item
              )
            }
          />
        </div>

        <div>
          <div>
            <h6 className="dropdowns_label" htmlFor="sku">
              Web Color
            </h6>
          </div>
          <TextField
            value={item?.webColorSchema}
            onChange={(e) => itemUpdate("webColorSchema", e.target.value, item)}
          />
        </div>
        <div>
          <div>
            <h6 className="dropdowns_label" htmlFor="sku">
              Offer mini video
            </h6>
          </div>
          <TextField
            value={item?.shortVideo}
            onChange={(e) => itemUpdate("shortVideo", e.target.value, item)}
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <ShoeSize item={item} />
      </div>
      <div className="color_other_switches_exclusive_container">
        <Switch
          checked={item?.exclusive}
          onChange={(e) => itemUpdate("exclusive", !item?.exclusive, item)}
          name="exclusive"
        />
        <div>
          <h5 className="dropdowns_label" htmlFor="sku">
            Exclusive
          </h5>
        </div>
        {/* VIDEO SECTION */}
        <div style={{ marginTop: "35px" }}>
          <h5 style={{ margin: 0 }}>Product video (Vimeo ID)</h5>
          <p>Sample link (change number):</p>
          <p style={{ opacity: 0.5 }}>
            https://player.vimeo.com/video/903021632?autoplay=1
          </p>

          <TextField
            style={{ marginTop: "10px", width: "100%" }}
            placeholder="link/url"
            onChange={(e) => itemUpdate("productVideo", e.target.value, item)}
            value={item?.productVideo || ""}
          />
        </div>
      </div>
    </>
  );
};

export default ColorOthersSwitch;
