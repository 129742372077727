import * as React from "react";

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20.66 23.28"
    style={{
      enableBackground: "new 0 0 20.66 23.28",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st0{fill:none;stroke:#000;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}"
      }
    </style>
    <path
      className="st0"
      d="M19.45 22.04v-4.32c0-3.51-2.85-6.36-6.36-6.36H7.57c-3.51 0-6.36 2.85-6.36 6.36v4.32"
    />
    <circle className="st0" cx={10.33} cy={6.25} r={5.01} />
  </svg>
);

export default SvgComponent;
