import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import "components/Reusable/BreadCrumbs/BreadCrumb.scss";

const BreadCrumb = ({
  category,
  subCategory,
  productId,
  shortDescription = false,
}) => {
  return (
    <div className="catalogue_breadcrumb_container" onClick={() => {}}>
      <Breadcrumbs className="bread_crumb" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          HOME
        </Link>
        {!subCategory && !productId ? (
          <p>{category.toUpperCase()}</p>
        ) : (
          <Link underline="hover" color="inherit" href={`/${category}`}>
            {category.toUpperCase()}
          </Link>
        )}
        {subCategory &&
        productId &&
        category.toUpperCase() != subCategory.toUpperCase() ? (
          <Link
            style={{ marginTop: "2px" }}
            underline="hover"
            color="inherit"
            href={`/${category}/${subCategory}`}
          >
            {subCategory.toUpperCase()}
          </Link>
        ) : subCategory && !productId ? (
          <p>{subCategory.toUpperCase()}</p>
        ) : null}
        {productId ? <p>{shortDescription.toUpperCase()}</p> : null}
      </Breadcrumbs>
    </div>
  );
};

export default BreadCrumb;
