import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const merchandisingApi = createApi({
  reducerPath: "merchandisingApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["merchandise"],
  endpoints: (builder) => ({
    getAllMerchandise: builder.query({
      query: () => `/merchandising`,
      providesTags: ["merchandise"],
    }),
    addMerchandise: builder.mutation({
      query: (body) => ({
        url: `/merchandising/add`,
        method: "POST",
        body: {
          products: body,
        },
      }),
      invalidatesTags: ["merchandise"],
    }),
  }),
});

export const {
  useGetAllMerchandiseQuery,
  useLazyGetAllMerchandiseQuery,
  useAddMerchandiseMutation,
} = merchandisingApi;
