import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Category", "SubCategory"],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `/categories`,
      providesTags: ["Category"],
    }),
    getAllSubCategories: builder.mutation({
      query: (category) => ({
        url: `/categories/subcategories`,
        method: "POST",
        body: {
          parentCategory: category,
        },
      }),
      providesTags: ["SubCategory"],
    }),
    addCategory: builder.mutation({
      query: (body) => ({
        url: `/admin/category/create`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Category"],
    }),
    deleteCategory: builder.mutation({
      query: (selected) => ({
        url: `/admin/category/delete`,
        method: "DELETE",
        body: {
          itemsArray: selected,
        },
      }),
      invalidatesTags: ["Category"],
    }),
    updateCategory: builder.mutation({
      query: (body) => ({
        url: `/admin/category/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Category"],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetAllSubCategoriesMutation,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} = categoriesApi;
