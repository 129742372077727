import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import { useSignInMutation } from "api/auth";
import { useDispatch } from "react-redux";
import { USER_LOGIN, USER_AUTHENTICATED } from "store/slices/UserSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import "styles/LoginPage.scss";
import { Helmet } from "react-helmet";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signIn, { data, error, isError, isLoading, isSuccess }] =
    useSignInMutation();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(USER_LOGIN({ ...data, authenticated: true }));
      localStorage.setItem(
        "user",
        JSON.stringify({ ...data, authenticated: true })
      );
      localStorage.setItem(
        "auth",
        JSON.stringify({
          accessToken: data?.accessToken,
          refreshToken: data?.refreshToken,
          authenticated: true,
        })
      );
      data?.role === "admin" ? navigate("/adminPanel") : navigate("/");
    }
  }, [isSuccess]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userObject = {
      email: data.get("email").trim(),
      password: data.get("password").trim(),
    };

    await signIn(userObject);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        marginTop: "5%",
      }}
    >
      <Helmet>
        <title>1511 Paris | Sign-in</title>
        {/* <link rel="canonical" href="https://1511.paris/signin" /> */}
        <meta
          name="keywords"
          content="1511 Paris, Sign In, Log In, Account Login, User Login, Customer Login, Secure Sign In, Secure Log In, Secure Account Login, Secure User Login, Secure Customer Login, Login, Account, User, Customer, 1511, Paris, Sign In to Your Account"
        />
        <meta
          name="description"
          content="Welcome to the 1511.paris Sign In page! Access your account to indulge in a world of beauty products and personalized experiences. Whether you're a loyal customer or new to our site, simply enter your login credentials to unlock exclusive benefits and features. Discover the latest beauty trends, effortlessly track your orders, curate your wishlist, and enjoy seamless shopping. Your transformative beauty journey begins here at 1511.paris. Sign in now and immerse yourself in a realm of unrivaled beauty."
        />
        <meta
          property="og:description"
          content="Welcome to the 1511.paris Sign In page! Access your account to indulge in a world of beauty products and personalized experiences. Whether you're a loyal customer or new to our site, simply enter your login credentials to unlock exclusive benefits and features. Discover the latest beauty trends, effortlessly track your orders, curate your wishlist, and enjoy seamless shopping. Your transformative beauty journey begins here at 1511.paris. Sign in now and immerse yourself in a realm of unrivaled beauty."
        ></meta>
      </Helmet>
      <Box className="login_container">
        <Avatar sx={{ m: 1, bgcolor: "#C4BBC6" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "Optima",
            color: "#696969",
          }}
        >
          SIGN IN
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            InputLabelProps={{
              style: { color: "#C4BBC6" },
            }}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C4BBC6",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#C4BBC6",
                },
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            InputLabelProps={{
              style: { color: "#C4BBC6" },
            }}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C4BBC6",
              },
            }}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={
              <Typography
                sx={{
                  fontFamily: "Optima",
                  color: "#696969",
                }}
              >
                Remember me
              </Typography>
            }
          />
          {!isLoading && isError ? (
            <Alert severity="error">{error?.data?.message}</Alert>
          ) : (
            <></>
          )}
          {isLoading ? (
            <Box className="spinner">
              <CircularProgress />
            </Box>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                fontFamily: "Optima",
                backgroundColor: "#C4BBC6",
                color: "black",
                fontWeight: "500",
                "&:hover": {
                  backgroundColor: "#C4BBC6",
                },
              }}
            >
              Sign In
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <button className="link_btn" onClick={() => navigate("/forgot")}>
                Forgot password
              </button>
            </Grid>
            <Grid item>
              <button
                className="link_btn"
                onClick={() => navigate("/register")}
              >
                {"Don't have an account? Sign Up"}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
