import { CircularProgress } from "@mui/material";
import { useGetReturnsQuery } from "api/returns";
import ReturnsTable from "components/AdminPanel/Returns/ReturnsTable";
import { useEffect, useState } from "react";
import Modal from "components/Reusable/Modal/Modal";
import ReturnItemModal from "pages/profile/ReturnItemModal";
import { useGetOrdersQuery, useLazyGetAllOrdersQuery } from "api/orders";

const Returns = () => {
  const { data, isLoading, error } = useGetReturnsQuery();
  const [getAllOrders, { data: allOrders }] = useLazyGetAllOrdersQuery();
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [returnItem, setReturnItem] = useState(false);
  const orders = allOrders?.orders;

  const getTheOrder = (id, returnId) => {
    let order = orders?.find((order) => order.id === id);
    let copy = data?.returns?.find((el) => el._id === returnId);
    const objCopy = JSON.parse(JSON.stringify(order));
    objCopy.items = copy.items.map((el) => {
      return {
        ...el.itemToReturn,
        returnStatus: "pending",
      };
    });

    setReturnItem(objCopy);
    setOpen(true);
  };

  useEffect(() => {
    getAllOrders({
      limit: 99999,
      page: 1,
    });
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <h2 style={{ padding: "16px 0" }}>Returns</h2>
      {data?.returns?.length > 0 ? (
        <ReturnsTable
          allReturns={data}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          limit={limit}
          setLimit={setLimit}
          getTheOrder={getTheOrder}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? <CircularProgress /> : <h4>No returns yet</h4>}
        </div>
      )}
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div>
          <ReturnItemModal
            returnPage
            returnItem={returnItem}
            setOpen={setOpen}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Returns;
