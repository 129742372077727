import React from "react";
import "components/ShoppingBag/Table.scss";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { INCRESE_QUANTITY } from "store/slices/CartSlice";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const MobileTable = ({
  products,
  deleteCartItem,
  subTotal,
  taxes,
  details = false,
  discount = 0,
  total,
  dbItemsOutOfStock = [],
}) => {
  const dispatch = useDispatch();
  const maxdQuantity = (product) => {
    const value = product.size.find((item) => {
      if (Number(item.value) === Number(product.selectedSize)) {
        return item;
      }
    });

    if (value) {
      return value.quantity;
    } else {
      return 0;
    }
  };
  const isOrderPage = window.location.pathname.includes("orders");
  return (
    <>
      <table>
        {/* HEAD */}
        <tbody>
          {products?.map((product) => {
            const itemNotInStock = dbItemsOutOfStock.filter((item) => {
              return item._id === product._id;
            });
            const isOutOfStock = itemNotInStock[0]?.stockAvailable <= 0;
            const alternativeStock =
              !isOutOfStock &&
              itemNotInStock[0]?.stockRequested >
                itemNotInStock[0]?.stockAvailable;
            const isItemReturned = !!product?.returnStatus;
            return (
              <tr key={uuidv4()}>
                <td>
                  <img
                    className="shopping_bag_image"
                    style={{
                      filter: isItemReturned ? "grayscale(100%)" : "none",
                    }}
                    src={product.primaryPhoto || product.photos[0].url}
                  ></img>
                </td>
                <td
                  className="shopping_bag_product_details"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <div>
                    <p>{product.designer}</p>
                  </div>
                  <div>
                    <p>
                      <strong>{product.shortDescription}</strong>
                    </p>
                  </div>
                  <div>
                    {product?.category !== "Perfumes" ? (
                      <p>
                        Size: {product.selectedSize} - Color:{" "}
                        {product.selectedColor || "Default"}
                      </p>
                    ) : null}
                    {product?.category === "Perfumes" &&
                    product?.fragrances?.length > 0 ? (
                      <p>Fragrance: {product?.fragrances?.toString()}</p>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "flex-start",
                      gap: "0.5rem",
                      position: "relative",
                    }}
                  >
                    <p>Quantity:</p>
                    {!isOrderPage && (
                      <RemoveIcon
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          dispatch(
                            INCRESE_QUANTITY({
                              ...product,
                              quantity: Number(product.quantity) - 1,
                            })
                          );
                        }}
                      ></RemoveIcon>
                    )}
                    {product?.quantity}
                    {!isOrderPage && (
                      <AddIcon
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          dispatch(
                            INCRESE_QUANTITY({
                              ...product,
                              quantity: Number(product.quantity) + 1,
                            })
                          );
                        }}
                      ></AddIcon>
                    )}
                    {product.quantity >= maxdQuantity(product) &&
                      !isOrderPage && (
                        <p
                          style={{
                            color: "#999",
                            cursor: "default",
                          }}
                        >
                          max
                        </p>
                      )}
                  </div>
                  {isItemReturned && isOrderPage ? (
                    <p
                      style={{
                        marginTop: "25px",
                        color: "#555",
                        whiteSpace: "nowrap",
                        fontWeight: "600",
                      }}
                    >
                      This item was returned
                    </p>
                  ) : null}
                  {!details ? (
                    <div
                      className="shopping_bag_product_buttons"
                      style={{ margin: "1rem 0" }}
                    >
                      <span>
                        <button onClick={() => deleteCartItem(product)}>
                          Remove from cart
                        </button>
                        <button>Add to wishlist</button>
                      </span>

                      {/* Out of stock message */}
                      {isOutOfStock ? (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "600",
                            marginTop: "5px",
                          }}
                        >
                          This item is out of stock.
                          <br /> Consider removing it from cart.
                        </p>
                      ) : null}
                      {/* Not enough stock message */}
                      {alternativeStock ? (
                        <p
                          style={{
                            color: "red",
                            fontWeight: "600",
                            marginTop: "5px",
                          }}
                        >
                          {`Only ${itemNotInStock[0]?.stockAvailable} product${
                            itemNotInStock[0]?.stockAvailable > 1 ? "s" : ""
                          } in stock`}
                        </p>
                      ) : null}
                    </div>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "10px 0",
          }}
        >
          <div
            style={{
              flex: "2",
              textAlign: "right",
            }}
          >
            <p>Price</p>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <span> {subTotal.format(2, 3, " ", ",")} €</span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            padding: "10px 0",
          }}
        >
          <div
            style={{
              flex: "2",
              textAlign: "right",
            }}
          >
            <p>Taxes</p>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <span> {Math.round(taxes).format(2, 3, " ", ",")} €</span>
          </div>
        </div>

        {discount > 0 ? (
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              padding: "10px 0",
            }}
          >
            <div
              style={{
                flex: "2",
                textAlign: "right",
              }}
            >
              <p>Discount</p>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <span>-{discount.format(2, 3, " ", ",")} €</span>
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            padding: "10px 0",
          }}
        >
          <div
            style={{
              flex: "2",
              textAlign: "right",
            }}
          >
            <p>
              <strong>Total price</strong>
            </p>
          </div>
          <div style={{ flex: 1, textAlign: "right" }}>
            {total ? (
              <span className="ta-right">{total.format(2, 3, " ", ",")} €</span>
            ) : (
              <span className="ta-right">
                {Math.ceil(subTotal + taxes).format(2, 3, " ", ",")} €
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileTable;
