// FILTERS WORKFLOW
// 1. User selects filter
// 2. Filter is added to a query string USING REACT ROUTER DOM "useSearchParams" below
// 3. "queryString" on this filter page is what being sent to the backend
// 4. When we change react router dom url query inside each filter, we updating "queryString" on this page
//    with "useEffect". When "queryString" is updated, we are sending it to the backend to get new products

import React, { useEffect } from "react";
import CategoriesFilter from "components/Reusable/Filters/children/CategoriesFilter";
import "components/Reusable/Filters/filters.scss";
import { useLazyGetCategoryProductsQuery } from "api/products";
import { useParams, useSearchParams } from "react-router-dom";
import PriceFilter from "components/Reusable/Filters/children/PriceFilter";
import ColorFilter from "components/Reusable/Filters/children/ColorFilter";
import SizeFilter from "components/Reusable/Filters/children/SizeFilter";
import ExclusiveFilter from "components/Reusable/Filters/children/ExclusiveFilter";

const Filters = ({
  setFilteredProducts,
  page,
  queryString,
  setQueryString,
  setPage,
}) => {
  let { category, subCategory, designer } = useParams();
  const [getProducts, { data: products, isLoading, isError, error }] =
    useLazyGetCategoryProductsQuery(
      subCategory ? { subCategory } : { category }
    );
  const [searchParams, setSearchParams] = useSearchParams();

  if (!category) subCategory = null;
  //FILTER PRODUCTS BASED ON FILTERS PARAMETRS
  const filterProducts = (products) => {
    if (products === undefined) return;
    let filtered = [];
    if (subCategory) {
      filtered.push(
        ...products?.products?.filter((product) => {
          return product?.category === subCategory;
        })
      );
    } else {
      filtered.push(...products?.products);
    }

    setFilteredProducts({
      products: filtered,
      maxProducts: products?.maxProducts,
    });
  };

  //TAKING QUERY STRING FROM URL AND SETTING IT TO STATE QUERY FOR FILTERING PRODUCTS
  useEffect(() => {
    const queryObject = {};
    searchParams.forEach((value, key) => {
      if (value !== "") {
        if (queryObject[key]) {
          queryObject[key] = [...queryObject[key], value];
        } else {
          queryObject[key] = [value];
        }
      }
    });
    if (Object.keys(queryObject).length === 0) setQueryString({});
    else {
      setQueryString(queryObject);
    }

    setSearchParams(queryObject);
  }, [searchParams, category, subCategory]);

  //GOING OVER QUERY STRING AND GETTING PRODUCTS
  //IF PAGE IS > 1, WE ARE SETTING PAGE TO 1 AND THAT TRIGGERS REFETCH IN CATALOGUE PAGE
  //OTHERWISE IF WE ARE SETTING FILTER AND PAGE IS ONE WE FETCH NEW PRODUCTS
  useEffect(() => {
    if (Object.keys(queryString).length > 0) {
      if (page !== 1) {
        setPage(1);
      } else {
        getProducts({
          category,
          subCategory,
          page: 1,
          filter: JSON.stringify(queryString),
          designer,
        });
      }
    } else {
      if (page !== 1) setPage(1);
      else getProducts({ category, subCategory, page: page, designer });
    }
  }, [queryString]);

  //RESET QUERY STRING WHEN CATEGORY OR SUBCATEGORY CHANGES
  // useEffect(() => {
  //   setQueryString({});
  // }, [category, subCategory]);

  //RESETTING PRODUCTS WHEN CATEGORY OR SUBCATEGORY CHANGES
  useEffect(() => {
    filterProducts(products);
  }, [products, category, subCategory]);

  const sizeFilterBlacklist = ["perfumes", "accessories"];

  return (
    <div className="filters_container">
      <div className="filters">
        {category && category.toLocaleLowerCase() !== "perfumes" ? (
          <CategoriesFilter />
        ) : null}
        {!sizeFilterBlacklist.includes(category) && <SizeFilter />}
        {category !== "perfumes" && <ColorFilter />}
        <ExclusiveFilter />
        <PriceFilter />
        {/* <DesignerFilter queryString={queryString} /> */}
      </div>
    </div>
  );
};

export default Filters;
