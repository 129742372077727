import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SET_PRODUCT } from "store/slices/NewProductSlice";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const TaxShipVideo = () => {
  const dispatch = useDispatch();
  const { vat, shippingInfo } = useSelector((state) => state.newProduct);
  const preOrderDelay = {
    0: "No delays",
    1: "1 week",
    2: "2 weeks",
    3: "3 weeks",
    4: "4 weeks",
    5: "5 weeks",
    6: "6 weeks",
    7: "7 weeks",
    8: "8 weeks",
    9: "9 weeks",
    10: "10 weeks",
    11: "11 weeks",
    12: "12 weeks",
  };

  const handleVatChange = (event) => {
    dispatch(SET_PRODUCT({ vat: event.target.value }));
  };
  const handleShippingChange = (key, event) => {
    let obj = {};
    obj[key] = Number(event.target.value);
    dispatch(SET_PRODUCT({ shippingInfo: { ...shippingInfo, ...obj } }));
  };

  return (
    <div>
      <Box sx={{ boxShadow: 2 }} className="taxshipvideo_container">
        {/* VAT SECTION */}
        <div className="taxshipvideo_container_vat">
          <h5>VAT applied</h5>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={vat}
            onChange={handleVatChange}
            sx={{ marginTop: "10px", width: "250px" }}
          >
            <MenuItem value={5}>TVA - 5%</MenuItem>
            <MenuItem value={10}>TVA - 10%</MenuItem>
            <MenuItem value={20}>TVA - 20%</MenuItem>
            <MenuItem value={30}>TVA - 30%</MenuItem>
          </Select>
        </div>
        {/* SHIPPING SECTION */}
        <div style={{ marginTop: "35px" }}>
          <h5 style={{ margin: 0 }}>Shipping information</h5>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="shipping_info_pre_order">
              <label className="shipping_info_pre_order_label">
                PreOrder delays
              </label>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={shippingInfo?.preOrderDelay}
                onChange={(event) => handleShippingChange("orderDelay", event)}
                defaultValue={0}
                sx={{ marginTop: "10px", width: "250px" }}
              >
                {Object.keys(preOrderDelay).map((key) => (
                  <MenuItem key={key} value={key}>
                    {preOrderDelay[key]}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="shipping_info_weight">
              <label className="shipping_info_weight_label">Weight</label>
              <TextField
                style={{ marginTop: "10px" }}
                placeholder="pounds/lb"
                onChange={(event) => handleShippingChange("weight", event)}
                value={!!shippingInfo?.weight ? shippingInfo?.weight : ""}
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default TaxShipVideo;
