import React, { useEffect, useState } from "react";
import "components/AdminPanel/Merchandising/Merchandising.scss";
import TextField from "@mui/material/TextField";
import { useGetFilteredProducts } from "utils/hooks/useGetFilteredProducts";
import {
  useAddMerchandiseMutation,
  useGetAllMerchandiseQuery,
} from "api/merchandising";
import { Button } from "@mui/material";
import { successToast, errorToast } from "utils/toasts";
import { ListManager } from "react-beautiful-dnd-grid";

const Merchandising = () => {
  const { handleProductFilter, filteredProducts, filter } =
    useGetFilteredProducts(true);

  //API CALLS
  const { data: merchandisingData } = useGetAllMerchandiseQuery();
  const [addMerchandise, { isSuccess, isError }] = useAddMerchandiseMutation();
  //Main local array for merchandising
  const [newMerchandise, setNewMerchandise] = useState([]);
  //Initial array for merchandising for comparison
  const [initialArray, setInitialArray] = useState([]);
  //Default array for merchandising for comparison
  const [defaultMerchandise, setDefaultMerchandise] = useState([]);

  //Detecting any changes in the local merchandising array
  const [change, setChange] = useState(false);
  useEffect(() => {
    if (merchandisingData) {
      setNewMerchandise(merchandisingData[0]?.products);
      setInitialArray(merchandisingData[0]?.products);
      setDefaultMerchandise(merchandisingData[0]?.products);
    }
  }, [merchandisingData]);

  useEffect(() => {
    if (isSuccess) {
      successToast();
    }
    if (isError) {
      errorToast();
    }
  }, [isSuccess, isError]);

  //Check if the local merchandising array has changed for the glow animation
  //on "Save Layout" button
  const compareIfArraysAreEqual = (arr1, arr2) => {
    const arr3 = [...arr1];
    const arr4 = [...arr2];

    for (let i = 0; i < arr3.length; i++) {
      if (arr3[i] !== arr4[i]) {
        return true;
      }
    }
    return false;
  };

  //Truncate the string to a certain length
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  //Save new merchandising layout on the backend
  const handleSubmitNewMerch = () => {
    const prompt = window.confirm("Are you sure you want to save this layout?");
    if (!prompt) return;
    addMerchandise(newMerchandise);
    setChange(false);
  };

  //Add item to the local merchandising array
  const handleAddToMerch = (item) => {
    setNewMerchandise([...newMerchandise, item]);
    setDefaultMerchandise([...defaultMerchandise, item]);
    successToast();
    setChange(true);
  };

  //Reshuffle the local merchandising array
  const reshuffleNewMerch = () => {
    let newMerch = [...newMerchandise];
    const shuffled = newMerch.sort(() => 0.5 - Math.random());
    setNewMerchandise([...shuffled]);
    setChange(true);
  };

  //Reset the local merchandising array to the default one
  const resetDefaultMerch = () => {
    if (defaultMerchandise.length === 0) return;
    setNewMerchandise([...defaultMerchandise]);
    setChange(compareIfArraysAreEqual(defaultMerchandise, initialArray));
  };

  //Remove item from the local merchandising array
  const handleRemoveFromMerch = (item) => {
    const prompt = window.confirm(
      "Are you sure you want to remove this item from the storefront?"
    );
    if (!prompt) return;
    let newMerch = [...newMerchandise];
    const index = newMerch.findIndex((el) => el._id === item._id);
    const defaultIndex = defaultMerchandise.findIndex(
      (el) => el._id === item._id
    );
    if (index !== -1) {
      newMerch.splice(index, 1);
      setNewMerchandise([...newMerch]);
      setChange(compareIfArraysAreEqual(newMerch, initialArray));
      successToast();
    }
    if (defaultIndex !== -1) {
      defaultMerchandise.splice(defaultIndex, 1);
      setDefaultMerchandise([...defaultMerchandise]);
    }
  };

  //Start over with the local merchandising array by refreshing the page
  const handleStartOver = () => {
    window.location.reload();
  };

  const dragEnd = (sourceIndex, destinationIndex) => {
    //re-arrange items array based on sourceIndex and destinationIndex
    const newItems = [...newMerchandise];
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);
    setNewMerchandise(newItems);
  };

  return (
    <div className="merchandising_container">
      <h2>Merchandising</h2>
      <div>
        <h4>Product search</h4>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            onChange={(e) => handleProductFilter(e.target.value)}
            value={filter}
            size="small"
            placeholder="Name of the item"
          />
          <Button onClick={() => handleProductFilter("")}>Clear</Button>
        </div>
      </div>
      <div className="merchandising_grid_container">
        {filteredProducts?.products?.slice(0, 9).map((item) => {
          return (
            <div className="merchandising_item">
              <div className="merchandising_item_image">
                <img src={item.photos[0]?.url} alt={item.shortDescription} />
              </div>
              <div className="merchandising_item_info">
                <div className="merchandising_item_info_name">
                  <h5>{truncate(item.shortDescription, 15)}</h5>
                </div>
                <div className="merchandising_item_info_sku">
                  <p>SKU: {truncate(item.sku, 15)}</p>
                </div>
                <div className="merchandising_item_info_designer">
                  <p>Designer: {truncate(item.designer, 10)}</p>
                </div>
                <div className="merchandising_item_info_color">
                  <p>Color: {item.color}</p>
                </div>
                <Button
                  className="merchandising_item_info_btn"
                  variant="outlined"
                  onClick={() => handleAddToMerch(item)}
                >
                  Merchandise
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <div className="merchandising_store_container">
        <h4>Storefront</h4>
        <div className="merchandising_store_btn_container">
          <div>
            <Button
              className={`merchandising_store_btn_save`}
              style={{
                animation: change
                  ? "glow 1s ease-in-out infinite alternate"
                  : "",
              }}
              variant="outlined"
              onClick={handleSubmitNewMerch}
            >
              Save layout
            </Button>
            <Button variant="outlined" onClick={handleStartOver}>
              Start over
            </Button>
          </div>
          <div className="merchandising_store_btn_reshuffle">
            <Button variant="outlined" onClick={reshuffleNewMerch}>
              Randomize
            </Button>
            <Button variant="contained" onClick={resetDefaultMerch}>
              Reset default
            </Button>
          </div>
        </div>
        <div className="merchandising_store_grid_container">
          <ListManager
            items={newMerchandise}
            direction="horizontal"
            maxItems={1}
            render={(item) => (
              <div className="merchandising_item">
                <div className="merchandising_item_image">
                  <img
                    src={item?.photos[0]?.url}
                    alt={item?.shortDescription}
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="merchandising_item_info">
                  <h5>{truncate(item?.shortDescription, 18)}</h5>
                </div>
                <Button
                  onClick={() => handleRemoveFromMerch(item)}
                  sx={{ borderColor: "red", color: "red", width: "100%" }}
                  variant="outlined"
                >
                  Remove
                </Button>
              </div>
            )}
            onDragEnd={dragEnd}
          />
        </div>
      </div>
    </div>
  );
};

export default Merchandising;
