import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { USER_LOGIN } from "store/slices/UserSlice";
import { store } from "store/";

let token;
let refreshToken;
//FORMING THE BASE QUERY FOR THE API
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    if (getState().user.accessToken || getState().user.refreshToken) {
      token = getState().user.accessToken;
      refreshToken = getState().user.refreshToken;
    } else {
      const userAuth = localStorage.getItem("auth");
      const data = JSON.parse(userAuth);
      token = data?.accessToken;
      refreshToken = data?.refreshToken;
    }

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      headers.set("refreshToken", refreshToken);
    }
    return headers;
  },
});

//EXPORTING THE AUTH QUERY THAT INCORPORATES BASEQUERY AS WELL AS CHECKING FOR VALID AUTH AGAINST THE SERVER
export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // if (!token && !refreshToken) return result;
  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery(
      "/auth/refreshToken",
      api,
      extraOptions
    );

    if (refreshResult.data) {
      store.dispatch(
        USER_LOGIN({
          accessToken: refreshResult.data.id_token,
          refreshToken: refreshResult.data.refresh_token,
          authenticated: true,
        })
      );
      localStorage.setItem(
        "auth",
        JSON.stringify({
          accessToken: refreshResult.data.id_token,
          refreshToken: refreshResult.data.refresh_token,
        })
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      store.dispatch(
        USER_LOGIN({
          authenticated: false,
        })
      );
      localStorage.setItem(
        "auth",
        JSON.stringify({
          authenticated: false,
        })
      );
    }
  }
  return result;
};
