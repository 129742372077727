import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sku: "",
  category: "",
  parentCategory: "",
  designer: "",
  season: "none",
  vat: 20,
  comingSoon: false,
  shippingInfo: { orderDelay: 0, weight: 0 },
  dressCode: [],
};

const NewProductSlice = createSlice({
  name: "newProduct",
  initialState,
  reducers: {
    SET_PRODUCT: (state, action) => {
      if (action?.payload) {
        for (const key in action.payload) {
          if (state.hasOwnProperty(key)) {
            state[key] = action.payload[key];
          }
        }
      }
    },
    RESET_NEW_PRODUCT: () => initialState,
  },
});

export const { SET_PRODUCT, RESET_NEW_PRODUCT } = NewProductSlice.actions;
export default NewProductSlice.reducer;
