import { useEffect, useRef } from "react";

const useScrollToItem = (page, setPage) => {
  const previousPage = JSON.parse(localStorage.getItem("page"));
  const mainPageItemRef = useRef(null);
  const isMobile = window.innerWidth < 768;

  //SCROLL FUNCTIONALITY ITSELF
  const scrollToPreviousPosition = (id) => {
    if (id === JSON.parse(localStorage.getItem("productScrollRef"))) {
      if (mainPageItemRef && mainPageItemRef.current) {
        const y =
          mainPageItemRef.current.getBoundingClientRect().top +
          window.scrollY -
          90;
        setTimeout(
          () => {
            window.scrollTo({ top: y, behavior: "smooth" });
          },
          isMobile ? 1000 : 150
        );
        localStorage.removeItem("productScrollRef");
      }
    }
  };
  const thePassedId = (id) => {
    if (id) {
      localStorage.setItem("productScrollRef", JSON.stringify(id));
    }
  };
  useEffect(() => {
    //INITIALIZATION IF WE DONT HAVE PAGE IN LOCALHOST AT ALL
    if (!previousPage) {
      localStorage.setItem("page", JSON.stringify(page));
    }

    //IF WE HAVE PAGE IN LOCALHOST AND WE ARE COMING BACK TO THE MAIN PAGE WE NEED TO SETPAGE TO MATCH THE PAGE IN LOCALHOST
    if (
      previousPage &&
      previousPage !== page &&
      !localStorage.getItem("productScrollRef")
    ) {
      localStorage.setItem("page", JSON.stringify(page));
    } else {
      if (!previousPage) {
        setPage(1);
      } else {
        setPage(previousPage);
      }
    }
  }, [page]);

  //THIS IS WHEN NAVBAR MAIN LOGO CLICKED WE SET THE PAGE PAGINATION TO 1st PAGE
  useEffect(() => {
    if (previousPage < page && previousPage === 1 && page > 1) {
      setPage(1);
    }
  }, [previousPage]);

  return { scrollToPreviousPosition, thePassedId, mainPageItemRef };
};

export default useScrollToItem;
