const finalCategories = {};
export const prepareCategories = (categories) => {
  categories?.forEach((item) => {
    if (item.parent) {
      if (finalCategories[item.parent]) {
        finalCategories[item.parent].push(item);
      } else {
        finalCategories[item.parent] = [item];
      }
    } else {
      finalCategories[item.name] = [];
    }
  });
  return finalCategories;
};
