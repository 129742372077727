import React, { useEffect } from "react";
import { useLazyGetAllOrdersQuery } from "api/orders";
import OrdersTable from "components/AdminPanel/Orders/OrdersTable";
import { CircularProgress } from "@mui/material";

const Orders = () => {
  const [getAllOrders, { data: allOrders, isLoading, error }] =
    useLazyGetAllOrdersQuery();
  const [pageNumber, setPageNumber] = React.useState(1);
  const [limit, setLimit] = React.useState(20);

  useEffect(() => {
    getAllOrders({
      limit: limit,
      page: pageNumber,
    });
  }, [pageNumber]);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "0 16px",
      }}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <h2 style={{ padding: "16px 0" }}>Orders</h2>
          <OrdersTable
            allOrders={allOrders}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            limit={limit}
            setLimit={setLimit}
          />
        </>
      )}
    </div>
  );
};

export default Orders;
