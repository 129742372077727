import * as React from "react";
import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import Create from "@mui/icons-material/Create";
import SaveAs from "@mui/icons-material/SaveAs";
import Close from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import { successToast, errorToast } from "utils/toasts";
import "./designers.scss";

function createData(name, date, biography, id) {
  return {
    name,
    date,
    biography,
    id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Select all",
  },
  // {
  //   id: "date",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Created at",
  // },
  {
    id: "biography",
    numeric: true,
    disablePadding: false,
    label: "Biography information",
  },
];

//TABLE HEAD
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: "bold" }}
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  updateDesigners: PropTypes.func,
};

//TOOL BAR ON THE TOP OF THE TABLE
function EnhancedTableToolbar(props) {
  const {
    numSelected,
    handleDeleteDesigner,
    setEdit,
    edit,
    designers,
    selected,
    updateDesigners,
  } = props;
  const isLengthOne = numSelected === 1;
  const getDesigner = useCallback(
    (name) => {
      const designer = designers.find((designer) => designer.name === name);
      return {
        id: designer._id,
        name: designer.name,
        biography: designer.biography,
      };
    },
    [isLengthOne]
  );
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          All Designers
        </Typography>
      )}

      {numSelected === 1 && !edit.active ? (
        <>
          <Tooltip sx={{ marginRight: "5px" }} title="Edit">
            <IconButton
              onClick={() =>
                setEdit({ ...getDesigner(selected[0]), active: !edit.active })
              }
            >
              <Create />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteDesigner}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : numSelected === 1 && edit.active ? (
        <>
          <Tooltip title="Save">
            <IconButton
              onClick={() => {
                updateDesigners();
                setEdit({ ...edit, active: false });
              }}
            >
              <SaveAs />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              onClick={() => {
                setEdit({ ...edit, active: false });
                // setSelected([]);
              }}
            >
              <Close />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={handleDeleteDesigner}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={handleDeleteDesigner}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  setEdit: PropTypes.func.isRequired,
  edit: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  designers: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
};

//MAIN TABLE COMPONENT
export default function EnhancedTable({
  rowItems,
  designers,
  rows,
  deleteLoading,
  deleteDesigner,
  addLoading,
  isLoading,
  deleteError,
  updateDesigner,
  updateLoading,
  updateError,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [edit, setEdit] = React.useState({
    id: "",
    name: "",
    biography: "",
    active: false,
  });

  //Create data for table
  useEffect(() => {
    rowItems(createData);
  }, [designers, rowItems]);

  //Delete designer call
  const handleDeleteDesigner = async () => {
    const consent = window.confirm(
      "Are you sure you want to delete this item?"
    );
    if (consent) {
      await deleteDesigner(selected);
      !deleteLoading && !deleteError && setSelected([]);
      !deleteLoading && !deleteError && successToast();
      return;
    } else {
      return;
    }
  };

  //Update designer call
  const updateDesigners = async () => {
    const consent = window.confirm(
      "Are you sure you want to update this item?"
    );
    if (consent) {
      const res = await updateDesigner(edit);
      if (res?.data?.status === 200) {
        !updateLoading && !updateError && setSelected([]);
        !updateLoading && !updateError && successToast();
        return;
      } else {
        !updateLoading && updateError && errorToast();
      }
    } else {
      return;
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <div className="designer_table_container">
      {!addLoading && !deleteLoading && !isLoading && rows?.length ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              handleDeleteDesigner={() => handleDeleteDesigner()}
              numSelected={selected.length}
              setEdit={setEdit}
              edit={edit}
              setSelected={setSelected}
              designers={designers}
              selected={selected}
              updateDesigners={updateDesigners}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
               rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => {
                            if (
                              selected?.length === 1 &&
                              row?.name === selected[0] &&
                              edit?.active
                            ) {
                              return;
                            }
                            if (row?.name !== selected[0] || !edit?.active) {
                              handleClick(event, row.name);
                            }
                            if (selected?.length === 1 && edit?.active) {
                              setEdit({ ...edit, active: false });
                            }
                          }}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          {(row.name !== selected[0] || !edit.active) && (
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.name}
                            </TableCell>
                          )}
                          {edit.active &&
                            selected?.length === 1 &&
                            row.name === selected[0] && (
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                <TextField
                                  id="outlined-multiline-flexible"
                                  // label="Biography"
                                  multiline
                                  minRows={2}
                                  maxRows={10}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={edit.name}
                                  onChange={(e) =>
                                    setEdit({
                                      ...edit,
                                      name: e.target.value,
                                    })
                                  }
                                />
                              </TableCell>
                            )}
                          {/* <TableCell align="right">{row.date}</TableCell> */}
                          {(row.name !== selected[0] || !edit.active) && (
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "250px",
                                verticalAlign: "left",
                              }}
                            >
                              {row.biography}
                            </TableCell>
                          )}
                          {edit.active &&
                            selected?.length === 1 &&
                            row.name === selected[0] && (
                              <TableCell
                                align="left"
                                sx={{
                                  maxWidth: "250px",
                                  verticalAlign: "left",
                                }}
                              >
                                <TextField
                                  id="outlined-multiline-flexible"
                                  // label="Biography"
                                  multiline
                                  minRows={2}
                                  maxRows={10}
                                  sx={{
                                    width: "100%",
                                  }}
                                  value={edit.biography}
                                  onChange={(e) =>
                                    setEdit({
                                      ...edit,
                                      biography: e.target.value,
                                    })
                                  }
                                />
                              </TableCell>
                            )}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      ) : (
        <Box className="designer_table_loader">
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
