import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const homeModulesApi = createApi({
  reducerPath: "homeModulesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["homeModules"],
  endpoints: (builder) => ({
    getHomeModules: builder.query({
      query: () => `/homeModules`,
      providesTags: ["homeModules"],
    }),
    addHomeModules: builder.mutation({
      query: (homeModules) => ({
        url: `/homeModules`,
        method: "POST",
        body: homeModules,
      }),
      invalidatesTags: ["homeModules"],
    }),
    uploadPhotos: builder.mutation({
      query: (body) => ({
        url: `/admin/homeModules/imageUpload`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useAddHomeModulesMutation,
  useGetHomeModulesQuery,
  useUploadPhotosMutation,
} = homeModulesApi;
