import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN } from "./store/slices/UserSlice";
import { ADD_TO_CART } from "store/slices/CartSlice";
import "assets/font/OptimaLTStd.otf";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartOpen } = useSelector((state) => state.cart);

  //TO DO: MAKE A PROPER STATE MANAGEMENT FOR USER LOGIN
  const token = localStorage.getItem("user");
  const data = JSON.parse(token);

  //POPULATING REDUX STORE WITH CART DATA FROM LOCAL STORAGE
  //REDUX REMAINS THE MAIN SOURCE OF TRUTH FOR CART DATA
  //LOCALSTORAGE IS USED JUST TO PERSIST DATA
  //COMPONENTS => PRODUCT => DESCRIPTION IS WHERE ITEMS SET TO LOCALSTORAGE; CART IS WHERE THEY ARE DELETED
  //Utils => localStorageHelper.js is where the localstorage custom hook defined and used throughout the app
  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    if (cart) {
      dispatch(ADD_TO_CART(cart.products));
    }
  }, []);

  useEffect(() => {
    if (data?.accessToken && data?.authenticated) {
      dispatch(USER_LOGIN(data));
    }
  }, [token]);

  //BLOCKING SCROLL ON THE BODY ELEMENT IF CART IS OPEN
  useEffect(() => {
    if (cartOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "scroll";
  }, [cartOpen]);

  return (
    <>
      <Layout />
    </>
  );
}

export default App;
