import React from "react";
import "components/Footer/Footer.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";

const Footer = () => {
  return (
    <div className="footer_container">
      <p>
        For all enquiries please email us at{" "}
        <a href="mailto:advisor@1511.paris">advisor@1511.paris</a>{" "}
      </p>
      <p style={{ marginTop: 0 }}>
        or give us a call at{" "}
        <a href="tel:+33-1-86-91-99-23">+33 1 86 91 99 23</a>
      </p>
      <div className="footer_social_container">
        <a target={"_blank"} href="https://www.instagram.com/1511.paris/">
          <InstagramIcon />
        </a>
        <a target={"_blank"} href="https://vimeo.com/user97732821">
          <img
            style={{
              width: "20px",
            }}
            src={require("../../assets/icons/vimeo.png")}
          />
        </a>
        <a target={"_blank"} href="https://www.pinterest.fr/lessuitesofficial/">
          <PinterestIcon />
        </a>
      </div>
      <div className="footer_copyright_container">
        <span>© 2023 boutiquelessuites.com Tous droits réservés </span>
        <a href="/terms-of-service">Terms and conditions</a>
        <a href="/faq">F.A.Q.</a>
        <a href="/">Privacy policy</a>
      </div>
    </div>
  );
};

export default Footer;
