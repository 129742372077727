import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import "components/Product/imageSection/GalleryModal.scss";
import PlayBtn from "assets/icons/play-btn.svg";

const GalleryModal = ({ selectedImage, setGalleryModal, photos }) => {
  const [activeImage, setActiveImage] = useState(selectedImage);
  const mainImageRef = useRef([]);

  useEffect(() => {
    mainImageRef.current[activeImage].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    return () => {
      mainImageRef.current = [];
    };
  }, []);

  return (
    <div
      onScroll={(e) => {
        const offset = mainImageRef?.current[1]?.offsetTop - 32;
        const targetScrollTop = e.target.scrollTop;
        if (
          Math.floor(e.target.scrollTop / offset) === activeImage + 1 ||
          Math.floor(e.target.scrollTop / offset) === activeImage - 1
        ) {
          setActiveImage(Math.round(targetScrollTop / offset));
        }
      }}
      className="gallery_modal_container"
    >
      <div className="gallery_modal_wrapper">
        {/* GALLERY THUMBNAILS */}
        <div className="gallery_modal_thumbnails_wrapper">
          {photos?.map((image, index) => {
            if (image?.url?.includes("vimeo")) {
              return (
                <div
                  onClick={() => {
                    mainImageRef.current[index].scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }}
                >
                  <img
                    className="product_image_thumbnail_player"
                    key={index}
                    src={PlayBtn}
                    style={{ opacity: index === activeImage ? 1 : 0.5 }}
                  ></img>
                  <img
                    style={{ opacity: index === activeImage ? 1 : 0.5 }}
                    className="gallery_modal_thumbnail"
                    key={index}
                    src={photos[0]?.url}
                  ></img>
                </div>
              );
            }
            return (
              <img
                onClick={() => {
                  mainImageRef.current[index].scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }}
                style={{ opacity: index === activeImage ? 1 : 0.5 }}
                key={image?._id}
                className="gallery_modal_thumbnail"
                src={image?.url}
                alt={image?.alt || "image"}
              ></img>
            );
          })}
        </div>
        {/* GALLERY MAIN IMAGE */}
        <div className="gallery_modal_main_image_wrapper">
          {photos?.map((image) => {
            if (image?.url?.includes("vimeo")) {
              return (
                <div
                  key={image?.url}
                  ref={(element) => mainImageRef.current.push(element)}
                  className="video_container"
                >
                  <iframe
                    // className="video_container"
                    allow="autoplay"
                    frameborder="0"
                    src={image?.url}
                  ></iframe>
                </div>
              );
            } else {
              return (
                <img
                  ref={(element) => mainImageRef.current.push(element)}
                  key={image?._id}
                  className="gallery_modal_main_image"
                  src={image?.url}
                  alt={image?.alt || "image"}
                ></img>
              );
            }
          })}
          <button
            className="gallery_modal_close_btn"
            onClick={() => {
              document.body.style.overflow = "auto";
              setGalleryModal(false);
            }}
          >
            Close X
          </button>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
