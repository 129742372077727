import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "components/WishList/WishList.scss";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useCreateWishListMutation } from "api/wishlist";

const AdminWishList = () => {
  const [shared, setShared] = useState(false);
  const [listName, setListName] = useState("");
  const [createWishList, { data, isLoading, error }] =
    useCreateWishListMutation();

  useEffect(() => {
    if (!data || error) return;
    setListName("");
    setShared(false);
  }, [data]);

  const handleCreate = () => {
    if (!listName) return;
    createWishList({ name: listName, shared });
  };

  return (
    <div>
      <div className="admin_wish_create_container">
        <h5>Create new wish list</h5>
        <FormControlLabel
          control={
            <Switch checked={shared} onChange={() => setShared(!shared)} />
          }
          label="Is sharable?"
        />
        <TextField
          placeholder="Wish list name"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
        ></TextField>
        <Button
          disabled={isLoading}
          onClick={() => {
            handleCreate();
          }}
        >
          {isLoading ? "Creating..." : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AdminWishList;
