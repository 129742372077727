import React, { useEffect } from "react";
import "pages/profile/CheckoutSuccess.scss";
import { NavLink, useParams } from "react-router-dom";
import useLocalStorage from "utils/localStorageHelper";
import { useDispatch, useSelector } from "react-redux";
import { EMPTY_CART } from "store/slices/CartSlice";

const CheckoutSuccess = () => {
  const { order_id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useLocalStorage("cart", { products: [] });
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    if (cart?.products?.length > 0) {
      setValue({
        products: [],
      });
      dispatch(EMPTY_CART());
    }
  }, [cart]);

  return (
    <div className="checkout_success_container">
      <div className="checkout_success_praise">
        <h2>Thank you for your order!</h2>
        <p>We will send you an email with your order details.</p>
        <p>
          Your order number is: <strong>{order_id}</strong>
        </p>
      </div>
      <div className="checkout_success_links">
        <NavLink to="/profile/orders">View your orders</NavLink>
        <NavLink to="/">Back to main page</NavLink>
      </div>
    </div>
  );
};

export default CheckoutSuccess;
