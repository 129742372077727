import React, { useState, useEffect } from "react";
import "pages/profile/OrderDetails.scss";
import { useParams } from "react-router-dom";
import { useGetOrderQuery } from "api/orders";
import useWindowDimensions from "utils/windowWidth";
import MobileTable from "components/ShoppingBag/MobileTable";
import WebTable from "components/ShoppingBag/WebTable";
import { Helmet } from "react-helmet";

const OrderDetails = () => {
  const { order_id } = useParams();
  // const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const { data, isError, isLoading, error } = useGetOrderQuery(order_id);
  const products = data?.items;
  const { width } = useWindowDimensions();
  let price_total = data?.price_total / 100;
  let price_beforeTax = data?.price_subtotal / 100 / 1.2;
  let price_discount = data?.price_discount / 100;
  let price_subTotal = data?.price_subtotal / 100;
  //Calculate tax
  let tax_price = price_subTotal - price_beforeTax;

  useEffect(() => {
    if (products?.length > 0) {
      setTaxes(data?.price_total);
    }
  }, [products]);

  //   MAIN CONTENT
  const renderContent = () => {
    return (
      <div className="order_details">
        <Helmet>
          <title>1511 Paris | Order Details</title>
          {/* <link rel="canonical" href="https://1511.paris/profile/orders/:id" /> */}
          <meta
            name="keywords"
            content="1511 Paris Order Summary, 1511 Paris Purchase Details, Order Information, Shipping Details, Order Confirmation, Purchase Summary"
          />
          <meta
            name="description"
            content="Welcome to your Order Details page! Dive into the specifics of your beauty product order and explore all the relevant information. View order items, quantities, pricing, and shipping details in one convenient location. Stay informed about your purchase and track its progress from placement to delivery. Your Order Details page provides a comprehensive overview of your beauty journey and ensures a smooth and transparent shopping experience at 1511.paris."
          />
          <meta
            property="og:description"
            content="Welcome to your Order Details page! Dive into the specifics of your beauty product order and explore all the relevant information. View order items, quantities, pricing, and shipping details in one convenient location. Stay informed about your purchase and track its progress from placement to delivery. Your Order Details page provides a comprehensive overview of your beauty journey and ensures a smooth and transparent shopping experience at 1511.paris."
          ></meta>
        </Helmet>
        {products?.length > 0 ? (
          <div className="shopping_bag_wrapper">
            <h2>Order#: {order_id}</h2>
            <h3>Summary</h3>
            {width > 768 ? (
              <WebTable
                details={true}
                products={products}
                subTotal={price_beforeTax}
                discount={price_discount}
                total={price_total}
                taxes={tax_price}
              />
            ) : (
              <MobileTable
                products={products}
                subTotal={price_beforeTax}
                taxes={tax_price}
                discount={price_discount}
                total={price_total}
                details={true}
              />
            )}
          </div>
        ) : (
          <div className="shopping_bag_empty"> Your order is not found </div>
        )}
      </div>
    );
  };

  //   SHIPPING ADDRESS
  const renderAddress = () => {
    return (
      <div className="order_details_address">
        <h2>Shipping Address</h2>
        <div className="order_details_address_content">
          <div className="order_details_address_content_left">
            <p>
              <strong>{data?.shipping_address?.name}</strong>
            </p>
            <p>{data?.shipping_address?.line1}</p>
            <p>{data?.shipping_address?.city}</p>
            <p>{data?.shipping_address?.state}</p>
            <p>{data?.shipping_address?.country}</p>
            <p>{data?.shipping_address?.postal_code}</p>
            <p>{data?.shipping_address?.phone}</p>
          </div>
        </div>
      </div>
    );
  };

  //   RETURNS
  const renderReturns = () => {
    return (
      <div className="order_details_returns">
        <h2>Returns and exchanges</h2>
        <div className="order_details_returns_content">
          <p>
            For any return or exchange request, please contact customer service:
            <a href="tel:+33 1 86 91 99 23">+33 1 86 91 99 23</a>{" "}
            <a href="mailto:advisor@1511.paris">advisor@1511.paris</a>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="order_details_container">
      {renderContent()}
      {renderAddress()}
      {renderReturns()}
    </div>
  );
};

export default OrderDetails;
