import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { successToast } from "utils/toasts";
import "./designers.scss";

const AddCategory = ({ addDesigner, addLoading, addError }) => {
  const [designerName, setDesignerName] = useState("");
  const [biography, setBiography] = useState("");

  //Add designer call
  const handleAddDesigner = useCallback(async () => {
    const name = designerName.trim();
    const resume = biography.trim();

    if (name === "") return alert("Designer name is required");
    if (resume === "") return alert("Biography is required");

    await addDesigner({
      name: name,
      biography: resume,
    });
    !addLoading && !addError && setDesignerName("");
    !addLoading && !addError && setBiography("");
    !addLoading && !addError && successToast();
  });

  return (
    <Box className="add_designer_container">
      <label style={{ fontSize: "18px" }}>New designer</label>
      <TextField
        onChange={(e) => setDesignerName(e.target.value)}
        value={designerName}
        size="small"
        placeholder="Name of the category"
      />
      <TextField
        id="outlined-multiline-flexible"
        label="Biography"
        multiline
        minRows={2}
        maxRows={10}
        sx={{ marginTop: "15px" }}
        value={biography}
        onChange={(e) => setBiography(e.target.value)}
      />
      <Button onClick={handleAddDesigner} sx={{ mt: 2 }} variant="contained">
        Add
      </Button>
    </Box>
  );
};

export default AddCategory;
