import React from "react";
import Box from "@mui/material/Box";
import "components/Reusable/CustomLoader/CustomLoader.scss";

const CustomLoader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <div className="loader"></div>
    </Box>
  );
};

export default CustomLoader;
