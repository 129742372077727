import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  totalPrice: 0,
  cartOpen: false,
};

function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

function getMaxQuantity(state, action, elPositionInTheArray) {
  return state.products[elPositionInTheArray].size.find((size) => {
    if (state.products[elPositionInTheArray].size?.length === 1) {
      return size.quantity;
    } else {
      if (Number(size.value) === Number(action.payload.selectedSize)) {
        return size.quantity;
      }
    }
  });
}

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART: (state, action) => {
      if (action.payload instanceof Array) {
        state.products = action.payload;
      } else {
        //Checking if the product is already in the cart and it is exactly the same product as the payload (not only the id but the whole object)
        //It matters because products can have same id but things like size, color, etc can be different
        const elPositionInTheArray = state.products.findIndex((item) => {
          //We are only interested in the object without the quantity property because what we send in the payload doesn't have it
          const sliceStateWithoutQuantity = { ...item };
          delete sliceStateWithoutQuantity.quantity;
          return deepEqual(sliceStateWithoutQuantity, action.payload);
        });
        console.log("el position =", elPositionInTheArray);
        if (elPositionInTheArray === -1) {
          state.products = [
            ...state.products,
            { ...action.payload, quantity: 1 },
          ];
        } else {
          //Check if MAX quantity is not exceeded
          const maxdQuantity = getMaxQuantity(
            state,
            action,
            elPositionInTheArray
          );

          if (maxdQuantity.quantity < action.payload.quantity) return;

          //Increase the quantity of the product
          state.products[elPositionInTheArray] = {
            ...action.payload,
            quantity: state.products[elPositionInTheArray].quantity + 1,
          };
        }

        //Calculate the total price
        state.totalPrice = state.products.reduce((acc, item) => {
          return (acc += item.finalPrice * item.quantity);
        }, 0);
      }
    },

    DELETE_CART_ITEM: (state, action) => {
      const elPositionInTheArray = state.products.findIndex((item) => {
        //Filterring the product by the whole object (deepEqual function is used to compare the objects)
        return deepEqual(item, action.payload);
      });

      if (elPositionInTheArray === -1) return;

      state.products = state.products.filter((item, index) => {
        return index !== elPositionInTheArray;
      });

      //Calculate the total price
      state.totalPrice = state.products.reduce((acc, item) => {
        return (acc += item.finalPrice * item.quantity);
      }, 0);
    },

    SET_CART_OPEN: (state, action) => {
      state.cartOpen = action.payload;
    },

    EMPTY_CART: (state) => {
      state.products = [];
      state.totalPrice = 0;
      // state.cartOpen = false;
    },

    INCRESE_QUANTITY: (state, action) => {
      if (action.payload instanceof Array) {
        state.products = action.payload;
      } else {
        const elPositionInTheArray = state.products.findIndex((item) => {
          //Removing quantity property from both objects as the quantity is different in the payload from the state value
          const sliceStateWithoutQuantity = { ...item };
          const payloadWithoutQuantity = { ...action.payload };

          delete sliceStateWithoutQuantity.quantity;
          delete payloadWithoutQuantity.quantity;

          //Comparing two objects to find the matching one
          return deepEqual(sliceStateWithoutQuantity, payloadWithoutQuantity);
        });

        if (elPositionInTheArray === -1) return;
        if (action.payload.quantity < 1) return;

        //Check if MAX quantity is not exceeded
        const maxdQuantity = getMaxQuantity(
          state,
          action,
          elPositionInTheArray
        );

        if (maxdQuantity.quantity < action.payload.quantity) return;

        //Increase the quantity of the product
        state.products[elPositionInTheArray] = {
          ...action.payload,
        };

        //Calculate the total price
        state.totalPrice = state.products.reduce((acc, item) => {
          return (acc += item.finalPrice * item.quantity);
        }, 0);
      }
    },
  },
});

export const {
  ADD_TO_CART,
  SET_CART_OPEN,
  DELETE_CART_ITEM,
  EMPTY_CART,
  INCRESE_QUANTITY,
} = CartSlice.actions;
export default CartSlice.reducer;
