import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  status: null,
  role: null,
  accessToken: null,
  refreshToken: null,
  wishLists: null,
  expiresIn: null,
  emailVerified: null,
  authenticated: false,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    CHANGE_USER_STATUS: (state, action) => {
      state.status = action.payload;
    },
    CHANGE_USER_ROLE: (state, action) => {
      state.role = action.payload;
    },
    CHANGE_USER_NAME: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    USER_LOGIN: (state, action) => {
      for (let key in action.payload) {
        if (state.hasOwnProperty(key)) {
          state[key] = action.payload[key];
        }
      }
    },
    USER_LOGOUT: (state) => {
      for (let key in state) {
        state[key] = null;
      }
    },
  },
});

export const {
  CHANGE_USER_STATUS,
  CHANGE_USER_ROLE,
  CHANGE_USER_NAME,
  USER_LOGIN,
  USER_LOGOUT,
} = UserSlice.actions;

export default UserSlice.reducer;
