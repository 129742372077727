import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { successToast } from "utils/toasts";
import "./categories.scss";

const AddCategory = ({ rows, addCategory, addLoading, addError }) => {
  const [categoryName, setCategoryName] = useState("");
  const [parentCategory, setParentCategory] = useState(null);

  //Add category call
  const handleAddCategory = useCallback(async () => {
    if (categoryName === "") return alert("Category name is required");
    await addCategory({
      name: categoryName,
      parent: parentCategory?.label,
    });
    !addLoading && !addError && setCategoryName("");
    !addLoading && !addError && setParentCategory(null);
    !addLoading && !addError && successToast();
  });

  return (
    <Box className="add_category_container">
      <label style={{ fontSize: "18px" }}>New category</label>
      <TextField
        onChange={(e) => setCategoryName(e.target.value)}
        value={categoryName}
        size="small"
        placeholder="Name of the category"
      />
      <Autocomplete
        disablePortal
        value={parentCategory}
        size="small"
        onChange={(event, newValue) => setParentCategory(newValue)}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        id="combo-box-demo"
        options={rows?.map((item) => ({
          label: item.name,
          id: item.id,
        }))}
        sx={{ mt: 2 }}
        renderInput={(params) => {
          return <TextField {...params} label="Category parent" />;
        }}
      />
      <Button onClick={handleAddCategory} sx={{ mt: 2 }} variant="contained">
        Add
      </Button>
    </Box>
  );
};

export default AddCategory;
