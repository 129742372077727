import * as React from "react";

const SvgComponent = (props) => (
  <svg
    width={800}
    height={800}
    viewBox="-3 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    className="cf-icon-svg"
    {...props}
  >
    <path d="M12.02 8.474c-.072.322-.12.617-.211.91a4.991 4.991 0 0 1-2.177 2.915 3.733 3.733 0 0 1-3.007.33 2.127 2.127 0 0 1-.824-.54c-.074-.092-.138-.217-.222-.302l-.296 1.166c-.129.464-.2.96-.369 1.398a8.31 8.31 0 0 1-.802 1.575c-.162.247-.328.482-.5.716-.062.085-.113.235-.273.205-.13-.127-.094-.478-.13-.694a7.475 7.475 0 0 1 .045-2.336l1.126-4.77-.08-.21-.12-.437a3.19 3.19 0 0 1 .097-1.671c.208-.535.778-1.334 1.62-1.183A1.25 1.25 0 0 1 6.83 7.2c-.193.959-.549 1.75-.733 2.695a1.343 1.343 0 0 0 1.751 1.432c1.149-.29 1.678-1.413 2.012-2.53a7.742 7.742 0 0 0 .154-.812A4.717 4.717 0 0 0 9.9 5.756a3 3 0 0 0-2.12-1.887 7.085 7.085 0 0 0-.83-.13l-.347-.006a4.178 4.178 0 0 0-1.228.165 3.843 3.843 0 0 0-2.62 2.41A3.823 3.823 0 0 0 2.51 8.03a2.847 2.847 0 0 0 .312 1.023c.1.168.334.364.375.557a1.028 1.028 0 0 1-.096.49 1.8 1.8 0 0 1-.17.556c-.166.212-.542-.011-.689-.102A3.088 3.088 0 0 1 .918 8.269a4.033 4.033 0 0 1-.023-1.296 7.01 7.01 0 0 1 .182-.836 5.44 5.44 0 0 1 2.7-3.154 6.285 6.285 0 0 1 1.83-.592l.614-.073.285-.018a6.67 6.67 0 0 1 1.347.074 4.933 4.933 0 0 1 4.234 4.036 6.157 6.157 0 0 1-.068 2.064z" />
  </svg>
);

export default SvgComponent;
