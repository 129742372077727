import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: [],
  endpoints: (builder) => ({
    checkout: builder.query({
      query: (data) => ({
        url: `/payment/create-checkout-session`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLazyCheckoutQuery } = cartApi;
