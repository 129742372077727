import { useEffect } from "react";
import { cartErrorToasts } from "utils/toasts";
import { useGetActiveWishList } from "utils/hooks/useGetActiveWishList";
import { useUpdateWishListMutation } from "api/wishlist";

export function useWishList(item) {
  const { wishList } = useGetActiveWishList();
  const [updateWishList, { data, isLoading, isSuccess, isError, error }] =
    useUpdateWishListMutation();
  const user = JSON.parse(localStorage.getItem("user"));

  //CHECK IF ITEM IS IN WISHLIST
  const isInWishList =
    user?.role !== "admin"
      ? wishList[0]?.products?.find((wishItem) => wishItem.id === item.id)
      : null;

  //HANDLE ADD/REMOVE TO/FROM WISHLIST
  const handleStarClick = (listId) => {
    // listId is only passed to this click event if admin is adding to wishlist
    // if user is adding to wishlist, listId is undefined
    if (!listId) {
      if (!isInWishList) {
        const newWishList = [...wishList[0]?.products, item];
        updateWishList({
          products: newWishList,
          _id: wishList[0]?._id,
        });
      } else {
        const newWishList = wishList[0]?.products.filter(
          (wishItem) => wishItem.id !== item.id
        );
        updateWishList({
          products: newWishList,
          _id: wishList[0]?._id,
        });
      }
    } else {
      const foundWishlist = wishList.find((list) => list._id === listId);
      const isItemInWishlist = foundWishlist?.products?.find(
        (product) => product.id === item?.id
      );

      if (!isItemInWishlist) {
        const newWishList = [...foundWishlist?.products, item];
        updateWishList({
          products: newWishList,
          _id: listId,
        });
      } else {
        const newWishList = foundWishlist?.products.filter(
          (wishItem) => wishItem.id !== item.id
        );
        updateWishList({
          products: newWishList,
          _id: listId,
        });
      }
    }
  };

  //ERROR HANDLING WISHLIST UPDATE
  useEffect(() => {
    if (error?.data?.message) {
      cartErrorToasts(error?.data?.message);
    }
  }, [error]);

  return {
    wishList,
    handleStarClick,
    isInWishList,
    isSuccess,
    isError,
    isLoading,
    data,
  };
}
