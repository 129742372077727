import React, { useEffect, useState, useRef } from "react";
import { useLazyGetProductsQuery } from "api/products";
import "./whatsnew.scss";
import ProductCard from "components/Reusable/ProductCard/ProductCard";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import VideoBlocks from "components/VideoBlocks/VideoBlocks";
import useScrollToItem from "utils/hooks/useScrollToItem";
import useScrollToTopWhatsNew from "utils/hooks/useScrollToTopWhatsNew";
import { useLazyGetAllMerchandiseQuery } from "api/merchandising";

const Whatsnew = () => {
  // const [getProducts, { data, isLoading, isError }] = useLazyGetProductsQuery();
  let [getProducts, { data: merchData, isLoading, isError }] =
    useLazyGetAllMerchandiseQuery();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (merchData) {
      setData(merchData[0]);
    }
  }, [merchData]);
  //SCROLLING WHEN CHANGING PAGE WITH PAGINATION AT THE BOTTOM
  const { scrollRef } = useScrollToTopWhatsNew(page, getProducts);

  //SCROLL TO PREVIOUS POSITION IF COMING BACK TO THE PAGE FROM A PRODUCT
  const { scrollToPreviousPosition, thePassedId, mainPageItemRef } =
    useScrollToItem(page, setPage);

  const localPageScrollItem = JSON.parse(
    localStorage.getItem("productScrollRef")
  );

  if (!data?.products || isLoading)
    return (
      <div className="loading_container">
        <h2>
          <CircularProgress />
        </h2>
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <VideoBlocks />
      <div ref={scrollRef} className="whatsnew_container">
        <h1>Our Collection</h1>
        <div className="whatsnew_wrapper">
          {data?.products?.map((item) => {
            if (item.id === localPageScrollItem) {
              setTimeout(() => {
                scrollToPreviousPosition(item.id);
              }, 250);
            }
            return (
              item?.productActive && (
                <div
                  key={item.id}
                  ref={item.id === localPageScrollItem ? mainPageItemRef : null}
                >
                  <ProductCard
                    thePassedId={thePassedId}
                    key={item.id}
                    item={item}
                  />
                </div>
              )
            );
          })}
        </div>
        {/* <div className="whatsnew_pagination">
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={Math.ceil(data?.maxProducts / 30)}
            variant="outlined"
            shape="rounded"
          />
        </div> */}
      </div>
    </div>
  );
};

export default Whatsnew;
