import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const fragranceApi = createApi({
  reducerPath: "fragranceApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Fragrance"],

  endpoints: (builder) => ({
    getFragrances: builder.query({
      query: () => `/fragrance`,
      method: "GET",
      providesTags: ["Fragrance"],
    }),
  }),
});

export const { useGetFragrancesQuery } = fragranceApi;
