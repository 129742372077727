import { useLazyGetProductsQuery } from "api/products";
import { useState, useEffect } from "react";

export function useGetFilteredProducts(merchandising = false) {
  const [getProducts, { data: products, isLoading, isError }] =
    useLazyGetProductsQuery();
  const [filter, setFilter] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (!products) {
      getProducts({});
    }
  }, []);

  useEffect(() => {
    if (products) {
      if (merchandising) {
        setFilteredProducts([]);
      } else {
        setFilteredProducts(products);
      }
    }
  }, [products]);

  const handleProductFilter = (product) => {
    //SHORT DESCRIPTION

    const shortDescFilter = products?.products?.filter((item) => {
      return item.shortDescription
        .toLowerCase()
        .includes(product.toLowerCase());
    });

    //SKU
    const skuFilter = products?.products?.filter((item) => {
      return item.sku.toLowerCase().includes(product.toLowerCase());
    });

    //DESIGNER
    const designerFilter = products?.products?.filter((item) => {
      return item.designer.toLowerCase().includes(product.toLowerCase());
    });

    //COLOR
    const colorFilter = products?.products?.filter((item) => {
      return item.color.toLowerCase().includes(product.toLowerCase());
    });

    const filteredRes = [
      ...new Set([
        ...shortDescFilter,
        ...designerFilter,
        ...skuFilter,
        ...colorFilter,
      ]),
    ];
    if (merchandising && product?.trim()?.length === 0) setFilteredProducts([]);
    else if (filteredRes) setFilteredProducts({ products: filteredRes });

    setFilter(product);
  };

  return {
    handleProductFilter,
    filter,
    setFilter,
    filteredProducts,
    setFilteredProducts,
    isLoading,
  };
}
