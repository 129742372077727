import Whatsnew from "components/Whatsnew/WhatsNew";
import React from "react";
import "../styles/HomePage.css";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div className="home_container">
      <Helmet>
        <title>
          1511 Paris | Exclusive clothing and perfume store in Paris
        </title>
        {/* <link rel="canonical" href="https://1511.paris/" /> */}
        <meta
          name="keywords"
          content="1511 Paris, Jewellery, Accessorie, Shops, Fashion, Designers, Perfume, Artisans, Necklace, Earrings, Clutch bag, clothing boutique, perfume boutique, clothing store, perfume store, clothing paris, perfume paris, Exceptional, Sensorial experience, Handmade in France, Craftsmanship, Unique, Exclusive, One-of-a-kind, Les Suites"
        />
        <meta
          name="description"
          content="1511 brings together unique artisans from around the world to form multidisciplinary collaborations. Each artistic project gives rise to an exceptional sensorial experience, be it a poetic film, a sculpture, a piece of jewellery, an evening gown, decor, or a perfume."
        />
        <meta
          property="og:description"
          content="1511.paris — 1511 brings together unique artisans from around the world to form multidisciplinary collaborations. Each artistic project gives rise to an exceptional sensorial experience, be it a poetic film, a sculpture, a piece of jewellery, an evening gown, decor, or a perfume."
        ></meta>

        <meta
          name="facebook-domain-verification"
          content="0280zf3a2z6kevmdl7v6xvcvteu2dx"
        />
      </Helmet>
      <Whatsnew />
    </div>
  );
};

export default Home;
