import React, { useEffect } from "react";
import "components/Reusable/Cart/Cart.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DELETE_CART_ITEM,
  INCRESE_QUANTITY,
  SET_CART_OPEN,
} from "store/slices/CartSlice";
import { cartToasts } from "utils/toasts";
import useLocalStorage from "utils/localStorageHelper";
import { uuidv4 } from "@firebase/util";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const CartModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { products, totalPrice } = useSelector((state) => state.cart);
  const [value, setValue] = useLocalStorage("cart", { products: [] });

  const handleProductNavigate = (product) => {
    //CHECKING IF PRODUCT HAS PARENT CATEGORY
    if (
      product?.category &&
      product?.parentCategory &&
      product?.category !== product?.parentCategory
    ) {
      navigate(
        `/product/${product?.parentCategory}/${product?.category}/${product?.id}`
      );
    } else {
      navigate(`/product/${product?.category}/${product?.id}`);
    }
  };

  //UPDATING LOCAL STORAGE CART
  //USING USEEFFECT TO AVOID FILTER WHEN DELETING IT IN THE FUNCTION BELOW
  //SINCE FILTERRING IS ALREADY DONE IN THE REDUCER
  useEffect(() => {
    setValue({
      products,
    });
  }, [products]);

  const deleteCartItem = (product, e) => {
    e.stopPropagation();
    dispatch(DELETE_CART_ITEM(product));
    cartToasts("Deleted");
  };

  const maxdQuantity = (product) => {
    const value = product.size.find((item) => {
      if (Number(item.value) === Number(product.selectedSize)) {
        return item;
      }
    });

    if (value) {
      return value.quantity;
    } else {
      return 0;
    }
  };

  const renderCartProduct = () => {
    return products?.map((product) => {
      return (
        <div key={uuidv4()} className="cart_product_container">
          <div
            onClick={() => handleProductNavigate(product)}
            className="cart_product_image"
          >
            <img src={product?.photos[0]?.url} alt="product" />
          </div>
          <div className="cart_product_details_container">
            <div className="cart_product_details">
              <p className="cart_product_name">{product?.designer}</p>
              <p className="cart_product_price">{product?.shortDescription}</p>
              {product?.selectedSize ? (
                <p className="cart_product_size">
                  Size: {product?.selectedSize}
                </p>
              ) : null}
              {product?.selectedColor ? (
                <p className="cart_product_size">
                  Color: {product?.selectedColor}
                </p>
              ) : null}
              {product?.fragrances && product?.fragrances?.length > 0 ? (
                <p className="cart_product_size">
                  Fragrance: {product?.fragrances?.toString()}
                </p>
              ) : null}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <p>Quantity:</p>
                <RemoveIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      INCRESE_QUANTITY({
                        ...product,
                        quantity: Number(product.quantity) - 1,
                      })
                    );
                  }}
                ></RemoveIcon>
                {product?.quantity}
                <AddIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    dispatch(
                      INCRESE_QUANTITY({
                        ...product,
                        quantity: Number(product.quantity) + 1,
                      })
                    );
                  }}
                ></AddIcon>
                {product.quantity >= maxdQuantity(product) && (
                  <p style={{ color: "#999", cursor: "default" }}>max</p>
                )}
              </div>
            </div>
          </div>
          <div className="cart_product_price_container">
            <p className="cart_product_price">
              {product?.finalPrice.format(2, 3, " ", ",")} €
            </p>
            <i onClick={(e) => deleteCartItem(product, e)}>
              <DeleteIcon className="cart_product_delete" />
            </i>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="cart_modal_wrapper">
      <div className="cart_modal">
        <div className="cart_modal_header">
          <h1>Shopping Bag ({products?.length})</h1>
        </div>
        <div
          style={{ overflowY: products?.length === 0 ? "hidden" : "auto" }}
          className="cart_modal_body"
        >
          {/* CART PRODUCTS */}
          {products?.length > 0 ? (
            renderCartProduct()
          ) : (
            <p className="empty_cart">Your cart is empty</p>
          )}
        </div>
        <div className="cart_modal_footer">
          <div className="footer_total">
            <p className="total_text">Total</p>
            {products?.length === 0 ? (
              <div />
            ) : (
              <p className="total_price">
                {totalPrice.format(2, 3, " ", ",")} €
              </p>
            )}
          </div>
        </div>
        <div className="order_btn">
          <button
            onClick={() => {
              dispatch(SET_CART_OPEN(false));
              navigate("/profile/shopping-bag");
            }}
          >
            <p>Order</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
