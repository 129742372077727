import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const returnsApi = createApi({
  reducerPath: "returnsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Returns"],

  endpoints: (builder) => ({
    getReturns: builder.query({
      query: () => `/returns/getAllReturns`,
      providesTags: ["Returns"],
    }),
    createReturn: builder.mutation({
      query: (data) => ({
        url: `/returns/addNewReturn`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Returns"],
    }),
    updateReturn: builder.mutation({
      query: (data) => ({
        url: `/returns/updateReturn`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Returns"],
    }),
  }),
});

export const {
  useGetReturnsQuery,
  useCreateReturnMutation,
  useUpdateReturnMutation,
} = returnsApi;
