import React, { useEffect } from "react";
import "components/VideoBlocks/VideoBlocks.scss";

import { Link } from "react-router-dom";
import { useGetHomeModulesQuery } from "api/homeModules";
import { v4 } from "uuid";

const VideoBlocks = () => {
  const ItemBlocks = ({ item, styleVideoZoom }) => {
    return (
      <>
        {/* <div className="first_video_border" /> */}
        {item?.redirectLink?.includes("https") ? (
          <a className="rest_redirect_link" href={item.redirectLink}></a>
        ) : (
          <Link
            to={{ pathname: `${item?.redirectLink}` || "" }}
            query={item?.titles}
            className="rest_redirect_link"
          ></Link>
        )}
        {item?.title || item?.subTitle ? (
          <div className="rest_text_block">
            <h1>{item?.title}</h1>
          </div>
        ) : null}
        {item?.imgUrl ? (
          <img src={`${item?.imgUrl}`} />
        ) : (
          <iframe
            style={{
              width: styleVideoZoom ? "200%" : "100%",
              right: styleVideoZoom ? "-50%" : "0",
            }}
            className="rest_video_block"
            allow="autoplay"
            frameborder="0"
            src={item?.videoUrl || ""}
          ></iframe>
        )}
      </>
    );
  };
  const { data } = useGetHomeModulesQuery();
  const [videoBlocks, setVideoBlocks] = React.useState([]);
  const [firstVideo, setFirstVideo] = React.useState(null);
  useEffect(() => {
    if (data?.modules) {
      setVideoBlocks(data?.modules);
      setFirstVideo(data?.modules[0][0]);
    }
  }, [data]);

  if (!videoBlocks || !firstVideo) return null;

  return (
    <div className="container_block">
      {/* FIRST VIDEO BLOCK */}
      {videoBlocks[0][0]?.firstVideo === true ? (
        <div
          key={v4()}
          className="first_video_container_block"
          style={{
            paddingBottom:
              !firstVideo?.videoUrl && firstVideo?.imgUrl ? "0px" : "56.25%",
          }}
        >
          {/* <div className="first_video_border" /> */}
          {firstVideo?.redirectLink?.includes("https") ? (
            <a
              className="rest_redirect_link"
              href={firstVideo.redirectLink || ""}
            ></a>
          ) : (
            <Link
              to={{ pathname: `${firstVideo?.redirectLink}` }}
              query={firstVideo?.titles}
              className="rest_redirect_link"
            ></Link>
          )}
          {firstVideo?.title || firstVideo?.subTitle ? (
            <div className="rest_text_block">
              <h1>{firstVideo?.title}</h1>
            </div>
          ) : null}
          {firstVideo?.videoUrl ? (
            <iframe
              className="first_video_block"
              allow="autoplay"
              frameborder="0"
              src={firstVideo?.videoUrl || ""}
            ></iframe>
          ) : (
            <img
              src={`${firstVideo?.imgUrl}`}
              style={{
                width: "100%",
              }}
            />
          )}
        </div>
      ) : null}
      {/* THE REST OF THE BLOCKS */}
      {videoBlocks.map((item, index) => {
        if (item[0]?.firstVideo || item?.length === 0) return;
        return (
          // BASED ON WHAT IS IN THE OBJECT, RENDER EITHER A VIDEO OR AN IMAGE BLOCK
          <>
            <div
              key={v4()}
              style={{
                overflow: "hidden",
                marginTop: index === 1 ? "1rem" : "0",
                marginBottom:
                  index === videoBlocks?.length - 1 ? "2.5rem" : "1rem",
                padding: 0,
                gridTemplateColumns:
                  index % 2 === 1 ? "66.3% 33%" : "33% 66.3%",
              }}
              className="grid_container"
            >
              <div
                className={
                  item[0]?.imgUrl ? "item_card" : "rest_video_container"
                }
              >
                <ItemBlocks
                  styleVideoZoom={
                    index % 2 !== 1 && item[0]?.videoUrl ? true : false
                  }
                  item={item[0]}
                />
              </div>
              <div
                className={
                  item[1]?.imgUrl ? "item_card" : "rest_video_container"
                }
              >
                <ItemBlocks
                  styleVideoZoom={
                    index % 2 === 1 && item[1]?.videoUrl ? true : false
                  }
                  item={item[1]}
                />
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default VideoBlocks;
