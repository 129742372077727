import React from "react";
import WishList from "components/WishList/WishList";
import "pages/profile/WishListPage.scss";
import { Helmet } from "react-helmet";

const WhishListPage = () => {
  return (
    <div className="wish_list_page_container">
      <Helmet>
        <title>1511 Paris | Wishlist</title>
        {/* <link rel="canonical" href="https://1511.paris/profile/address" /> */}
        <meta
          name="keywords"
          content="1511 Paris Wish List, 1511 Paris Favorites, Saved Items, Personalized Wishlist, Save for Later, Account Wishlist, wishlist, favorites, saved items, personalized wishlist, save for later, account wishlist"
        />
        <meta
          name="description"
          content="Welcome to your Wish List page! Create and curate your personalized collection of desired beauty products. Explore our extensive selection, save your favorite items, and easily access them whenever inspiration strikes. Stay organized and keep track of products you love, making future purchases a breeze. Your Wish List is your beauty inspiration board, helping you discover and indulge in the perfect products at 1511.paris."
        />
        <meta
          property="og:description"
          content="Welcome to your Wish List page! Create and curate your personalized collection of desired beauty products. Explore our extensive selection, save your favorite items, and easily access them whenever inspiration strikes. Stay organized and keep track of products you love, making future purchases a breeze. Your Wish List is your beauty inspiration board, helping you discover and indulge in the perfect products at 1511.paris."
        ></meta>
      </Helmet>
      <h1>Wish List</h1>
      <WishList />
    </div>
  );
};

export default WhishListPage;
