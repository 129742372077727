import React, { useEffect, useState } from "react";
import { useGetProductQuery } from "api/products";
import { useParams } from "react-router-dom";
import "components/Product/imageSection/ImageSection.scss";
import GalleryModal from "components/Product/imageSection/GalleryModal";
import useWindowDimensions from "utils/windowWidth";
import PlayBtn from "assets/icons/play-btn.svg";

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const ImageSection = () => {
  const [galleryModal, setGalleryModal] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const { product_id } = useParams();
  const { data: productData } = useGetProductQuery({ product_id });
  let product = productData?.product;
  const { width } = useWindowDimensions();
  const copy = structuredClone(product);

  if (copy?.productVideo) {
    copy?.photos?.splice(1, 0, {
      url: copy?.productVideo,
    });
  }
  const [frame, setFrame] = useState(false);
  React.useEffect(() => {
    if (frame) {
    }
  }, [frame]);

  return (
    <>
      {width > 768 ? (
        <>
          <div className="product_image_wrapper">
            {/* THUMBNAILS */}
            <div className="product_image_thumbnail_wrapper">
              {copy?.photos?.map((image, index) => {
                if (index > 6) return;
                if (index === 6)
                  return (
                    <button
                      key={"more_btn"}
                      className="product_image_more_thumbnail"
                      onClick={() => {
                        document.body.style.overflow = "hidden";
                        setGalleryModal(true);
                      }}
                      style={{
                        backgroundColor:
                          product?.webColorSchema?.trim()?.length > 0
                            ? product?.webColorSchema
                            : "#000",
                      }}
                    >
                      More
                    </button>
                  );
                if (image?.url?.includes("vimeo")) {
                  return (
                    <div
                      key={image?.url}
                      onClick={() => {
                        setActiveImage(index);
                      }}
                    >
                      <img
                        className="product_image_thumbnail_player"
                        src={PlayBtn}
                      ></img>
                      <img
                        className="product_image_thumbnail"
                        src={copy?.photos[0]?.url}
                      ></img>
                    </div>
                  );
                }
                return (
                  <div key={image?._id}>
                    <img
                      onClick={() => {
                        setActiveImage(index);
                      }}
                      className="product_image_thumbnail"
                      src={image?.url}
                      alt={image?.alt || "image"}
                    ></img>
                  </div>
                );
              })}
            </div>
            {/* MAIN IMAGE */}
            {product?.exclusive &&
            !copy?.photos[activeImage]?.url?.includes("vimeo") ? (
              <div className="whatsnew_product_exclusive_wrapper">
                <div className="whatsnew_product_exclusive_triangle"></div>
                <div className="whatsnew_product_exclusive_text">
                  <p>Exclusive</p>
                </div>
              </div>
            ) : null}
            <div
              onClick={() => {
                document.body.style.overflow = "hidden";
                setGalleryModal(true);
              }}
              className="product_main_image_container"
              style={{
                maxHeight: copy?.photos[activeImage]?.url?.includes("vimeo")
                  ? "750px"
                  : "inherit",
              }}
            >
              {/* EXCLUSIVE */}
              {copy?.photos[activeImage]?.url?.includes("vimeo") ? (
                <iframe
                  className="video_container"
                  allow="autoplay; fullscreen; picture-in-picture"
                  frameborder="0"
                  src={copy?.photos[activeImage]?.url}
                ></iframe>
              ) : (
                <img
                  className="product_image_main"
                  src={copy?.photos[activeImage]?.url}
                  alt={copy?.photos[activeImage]?.alt || "image"}
                ></img>
              )}
            </div>
          </div>
          {galleryModal ? (
            <GalleryModal
              selectedImage={activeImage}
              photos={copy?.photos}
              setGalleryModal={setGalleryModal}
            />
          ) : null}
        </>
      ) : (
        <Swiper
          spaceBetween={50}
          slidesPerView={1.075}
          modules={[Pagination]}
          pagination={true}
        >
          {product?.photos?.map((image, index) => {
            return (
              <SwiperSlide>
                {index === 0 && product?.exclusive && (
                  <div className="whatsnew_product_exclusive_wrapper">
                    <div className="whatsnew_product_exclusive_triangle"></div>
                    <div className="whatsnew_product_exclusive_text">
                      <p>Exclusive</p>
                    </div>
                  </div>
                )}
                <img
                  style={{
                    width: "330px",
                    height: "100%",
                    minHeight: "500px",
                  }}
                  key={image?._id}
                  src={image?.url}
                  alt={image?.alt || "image"}
                  loading="eager"
                ></img>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};

export default ImageSection;
