import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  useRemoveWishListMutation,
  useUpdateWishListMutation,
} from "api/wishlist";
import { useLocation, useNavigate } from "react-router-dom";

const AdminWishList = ({ list }) => {
  const [shared, setShared] = useState(list?.shared);
  const navigate = useNavigate();
  const [update, { isLoading }] = useUpdateWishListMutation();
  const [deleteList, { isLoading: isDeleting }] = useRemoveWishListMutation();

  const handleWishlistUpdate = () => {
    update({ ...list, shared });
  };

  useEffect(() => {
    if (isLoading) return;
    if (shared !== list?.shared) handleWishlistUpdate();
  }, [shared]);

  const handleDelete = () => {
    const prompt = window.confirm("Are you sure you want to delete this list?");
    if (!prompt) return;
    deleteList({ wishListId: list._id });
  };

  if (!list) return null;

  //GET THE BASE URL FOR LINK COPY
  const browserUrl = window.location.href;
  const url = new URL(browserUrl);

  return (
    <div className="wish_list_admin_container">
      <h4>{list?.name}</h4>
      <span>Items in the lsit: {list?.products?.length}</span>
      <div>
        <FormControlLabel
          control={
            <Switch
              disabled={isLoading}
              value={shared}
              checked={shared}
              onChange={() => setShared(!shared)}
            />
          }
          label="Is sharable?"
        />
        <Button
          onClick={() =>
            navigator.clipboard.writeText(
              `${url.origin}/profile/wishlist/${list?._id}`
            )
          }
        >
          Copy link
        </Button>
        <Button onClick={() => navigate(`${list?._id}`)}>View</Button>
        <Button
          disabled={isDeleting}
          onClick={handleDelete}
          className="wish_list_admin_delete"
        >
          {isDeleting ? (
            <CircularProgress sx={{ width: "15px", height: "15px" }} />
          ) : (
            "Delete"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AdminWishList;
