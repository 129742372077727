import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_CART_ITEM } from "store/slices/CartSlice";
import "styles/ShoppingBag.scss";
import { cartToasts } from "utils/toasts";

const ShoppingBag = () => {
  const { products } = useSelector((state) => state.cart);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!subTotal)
      setSubTotal(products.reduce((acc, product) => acc + product.priceHT, 0));
    setTaxes(subTotal * Number(`0.${products[0]?.vat}`));
  }, [subTotal, products]);
  const deleteCartItem = (product) => {
    dispatch(DELETE_CART_ITEM(product));
    cartToasts("Deleted");
  };
  const promoCodes = {
    WELCOME10: {
      discount: 10,
    },
    FIRSTTIME20: {
      discount: 20,
    },
  };
  const applyPromoCode = () => {
    if (promoCode && promoCodes[promoCode]) {
      setSubTotal(subTotal - (subTotal * promoCodes[promoCode].discount) / 100);
      cartToasts("Promo code applied");
      setPromoCodeApplied(true);
    }
  };

  return (
    <div className="shopping_bag_container">
      <h1 className="shopping_bag_heading">Order validation</h1>
      <div className="shopping_bag_wrapper">
        <h2>Summary</h2>
        <div className="shopping_bag_summary">
          <table>
            {/* HEAD */}
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>
                  <p>Quantity</p>
                </th>
                <th>
                  <p>Price</p>
                </th>
                <th>
                  <p>Total</p>
                </th>
              </tr>
            </thead>
            {/* BODY */}
            <tbody>
              {products?.map((product) => {
                return (
                  <tr>
                    <td>
                      <img
                        className="shopping_bag_image"
                        src={product.primaryPhoto || product.photos[0].url}
                      ></img>
                    </td>
                    <td className="shopping_bag_product_details">
                      <div>
                        <p>{product.designer}</p>
                      </div>
                      <div>
                        <p>
                          <strong>{product.shortDescription}</strong>
                        </p>
                      </div>
                      <div>
                        <p>
                          Size: {product.selectedSize} - Color:{" "}
                          {product.selectedColor}
                        </p>
                      </div>
                      <div>
                        <span>
                          <button
                            onClick={() => deleteCartItem(product)}
                            className="shopping_bag_buttons"
                          >
                            Remove from cart
                          </button>
                          <button>Add to wishlist</button>
                        </span>
                      </div>
                    </td>
                    <td className="ta-right">{product.quantity}</td>
                    <td className="ta-right">
                      {product.finalPrice.format(2, 3, " ", ",")} €
                    </td>
                    <td className="ta-right">
                      <b>{product.finalPrice.format(2, 3, " ", ",")} €</b>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan="3">
                  <div className="shopping_bag_promo">
                    <input
                      onChange={(e) => setPromoCode(e.target.value)}
                      valu={promoCode}
                      type="text"
                      placeholder="Promo code"
                    />
                    <button onClick={applyPromoCode}>Apply</button>
                  </div>
                </td>
                <td className="ta-right">Promo</td>
                <td className="ta-right">
                  {promoCodeApplied ? promoCodes[promoCode].discount : 0} %
                </td>
              </tr>
            </tbody>
            {/* FOOTER */}
            <tfoot>
              <tr>
                <td colSpan="4" className="ta-right">
                  <p>Subtotal</p>
                </td>
                <td className="ta-right">
                  {subTotal.format(2, 3, " ", ",")} €
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="ta-right">
                  <p>Taxes</p>
                </td>
                <td className="ta-right">{taxes.format(2, 3, " ", ",")} €</td>
              </tr>
              <tr>
                <td colSpan="4" className="ta-right">
                  <p>
                    <strong>Total price</strong>
                  </p>
                </td>
                <td className="ta-right">
                  {(subTotal + taxes).format(2, 3, " ", ",")} €
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="shopping_bag_action_buttons">
          <button>Continue Shopping</button>
          <button>Next step</button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingBag;
