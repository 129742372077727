import img1 from "assets/blogs/editorials/blogImage1.webp";
import img2 from "assets/blogs/editorials/img2.webp";
import img3 from "assets/blogs/editorials/img3.webp";

//DAY BY THE SEA
// import img5 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_1.jpg";
// import img6 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_2.jpg";
// import img7 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_3.jpg";
// import img8 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_4.jpg";
// import img9 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_5.jpg";
// import img10 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_6.jpg";
// import img11 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_7.jpg";
// import img12 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_8.jpg";
// import img13 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_9.jpg";
// import img14 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_10.jpg";
// import img15 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_11.jpg";
// import img16 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_12.jpg";
// import img17 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_13.jpg";
// import img18 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_14.jpg";
// import img19 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_15.jpg";
// import img20 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_16.jpg";
// import img21 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_17.jpg";
// import img22 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_18.jpg";
// import img23 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_19.jpg";
// import img24 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_20.jpg";
// import img25 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_21.jpg";
// import img26 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_22.jpg";
// import img27 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_23.jpg";
// import img28 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_24.jpg";
// import img29 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_25.jpg";
// import img30 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_26.jpg";
// import img31 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_27.jpg";
// import img32 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_28.jpg";
// import img33 from "assets/blogs/editorials/Day by the sea/LeHourdel_br_29.jpg";

//VINUS MIMOSA
import img34 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_01.jpg";
import img35 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_02.jpg";
import img36 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_04.jpg";
import img37 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_05.jpg";
import img38 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_06.jpg";
import img39 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_07.jpg";
import img40 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_08.jpg";
import img41 from "assets/blogs/editorials/Venus mimosa/VinusMimosa_09.jpg";

//ROUGE
import img42 from "assets/blogs/editorials/Rouge/Editorial_9_SM_01.jpg";
import img43 from "assets/blogs/editorials/Rouge/Editorial_9_SM_02.jpg";
import img44 from "assets/blogs/editorials/Rouge/Editorial_9_SM_03.jpg";
import img45 from "assets/blogs/editorials/Rouge/Editorial_9_SM_04.jpg";
import img46 from "assets/blogs/editorials/Rouge/Editorial_9_SM_05.jpg";
import img47 from "assets/blogs/editorials/Rouge/Editorial_9_SM_06.jpg";
import img48 from "assets/blogs/editorials/Rouge/Editorial_9_SM_07.jpg";
import img49 from "assets/blogs/editorials/Rouge/Editorial_9_SM_08.jpg";
import img50 from "assets/blogs/editorials/Rouge/Editorial_9_SM_09.jpg";
import img51 from "assets/blogs/editorials/Rouge/Editorial_9_SM_10.jpg";
import img52 from "assets/blogs/editorials/Rouge/Editorial_9_SM_11.jpg";
import img53 from "assets/blogs/editorials/Rouge/Editorial_9_SM_12.jpg";
import img54 from "assets/blogs/editorials/Rouge/Editorial_9_SM_13.jpg";
import img55 from "assets/blogs/editorials/Rouge/Editorial_9_SM_14.jpg";
import img56 from "assets/blogs/editorials/Rouge/Editorial_9_SM_15.jpg";
import img57 from "assets/blogs/editorials/Rouge/Editorial_9_SM_16.jpg";
import img58 from "assets/blogs/editorials/Rouge/Editorial_9_SM_17.jpg";

//WARM SENTIMENTS
import img59 from "assets/blogs/editorials/Warm sentiments/Editorial_08_01.jpg";
import img60 from "assets/blogs/editorials/Warm sentiments/Editorial_08_02.jpg";
import img61 from "assets/blogs/editorials/Warm sentiments/Editorial_08_03.jpg";
import img62 from "assets/blogs/editorials/Warm sentiments/Editorial_08_04.jpg";
import img63 from "assets/blogs/editorials/Warm sentiments/Editorial_08_05.jpg";
import img64 from "assets/blogs/editorials/Warm sentiments/Editorial_08_06.jpg";
import img65 from "assets/blogs/editorials/Warm sentiments/Editorial_08_07.jpg";
import img66 from "assets/blogs/editorials/Warm sentiments/Editorial_08_08.jpg";
import img67 from "assets/blogs/editorials/Warm sentiments/Editorial_08_09.jpg";
import img68 from "assets/blogs/editorials/Warm sentiments/Editorial_08_10.jpg";

const blocks = [
  {
    id: "98c47e63-a902-487e-8c1e-3c5b6a4d6390",
    mainPhotos: null,
    mainVideoUrl: `https://player.vimeo.com/video/826683280`,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/826683280?background=1&autoplay=1&loop=1&byline=0&title=0`,
    title: "HIDDEN WORLD",
    description: "An extraordinary universe. A sensorial journey.",
    category: "editorials",
    shopUrl: "/perfumes",
  },
  {
    id: "qdw11",
    mainPhotos: [
      {
        img1: img34,
        img2: img35,
      },
      {
        img1: img36,
        img2: img37,
      },
      {
        img1: img38,
        img2: img39,
      },
      {
        img1: img40,
        img2: img41,
      },
    ],
    mainVideoUrl: null,
    previewImageUrl: img1,
    previewVideoUrl: null,
    title: "VENUS MIMOSA",
    description: `1511 collaborated with photographer and collage artist Anna Muller. She splendidly blends her refined
    aesthetics and radiant haute couture ensembles with a dash of colourful humour. Based in Paris, Anna
    uses cutouts of bright opulent ensembles against ironic contrasting elements. Celebrating the colours of
    spring, the series highlights her unique aesthetic sense in her art of collage making.`,
    category: "editorials",
    shopUrl: "/blog-collage",
    collageElements: [
      "4s70DKExEe8AguPwKdQ2w",
      "kIk-JGz3qmLdOwkxphTTB",
      "E7LIBgNNoYQTr8i8jbdUz",
      "VCKSESlV66kjT3O_W-tao",
    ],
  },
  {
    id: "12e12d3fv",
    mainPhotos: null,
    mainVideoUrl: `https://player.vimeo.com/video/340621311`,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/340622715?background=1&autoplay=1&loop=1&byline=0&title=0`,
    title: "DAY BY THE SEA",
    description:
      "1511 travels to France's serene northern coast. Photographed by Anna Muller, models Dasha and Louise are featured in standout Victorian looks from international designers curated especially for this series.",
    category: "editorials",
    shopUrl: "/blog-collage",
    collageElements: [
      "TPzhqO_TM6u6ChbFFQx2c",
      "Dt-Qqc6vCCIuoo27WzSZR",
      "jbt83GUxTN909VtSsZGN-",
      "r4CIdh0lIznBHPoCirgQO",
      "V7FIfCeeN3bMp3uGQw2fb",
      "RulTOAC6Vb6925lnk3kIS",
      "kIk-JGz3qmLdOwkxphTTB",
      "pN9daY3EWWXRPBcunGqBx",
      "4VZPXBMg_m68JAxksfsWY",
      "fn4Dofwot6tOQH4qA9v7v",
      "vZisyOQLokIjYhlRRKvF3",
      "VtETVrHgc6QRzxI10umr8",
      "VesXxsmT_lbNjm95ApiQ2",
      "zmQ14O5AhecgRE_ClrYd3",
      "qVaYzPApH4KoDf5zAJ16f",
      "3jKrWtydy_6hMjQ-kSaIz",
    ],
  },
  {
    id: "234fd24",
    mainPhotos: [
      {
        img1: img42,
        img2: img43,
      },
      {
        img1: img44,
        img2: img45,
      },
      {
        img1: img46,
        img2: img47,
      },
      {
        img1: img48,
        img2: img49,
      },
      {
        img1: img50,
        img2: img51,
      },
      {
        img1: img52,
        img2: img53,
      },
      {
        img1: img54,
        img2: img55,
      },
      {
        img1: img56,
        img2: img57,
      },
      {
        img1: img58,
        img2: null,
      },
    ],
    mainVideoUrl: null,
    previewImageUrl: img2,
    previewVideoUrl: null,
    title: "ROUGE",
    description: "",
    category: "editorials",
    shopUrl: "/blog-collage",
    collageElements: [
      "x2qe1tkPs8V90LjMyFMNk",
      "g7rYIHw5TZPtotpfe_d7f",
      "VKWNg5yETr4fOXozwNefX",
      "7-rLZhGsCOvnNm8S72FHl",
    ],
  },
  {
    id: "2f4423f",
    mainPhotos: null,
    mainVideoUrl: `https://player.vimeo.com/video/361218737`,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/361218975?background=1&autoplay=1&loop=1&byline=0&title=0`,
    title: "UNE FEMME A PARIS",
    description: "",
    category: "editorials",
    shopUrl: "/blog-collage",
    collageElements: [
      "3KB0xLKc5FfkGf0skzI_U",
      "f0oEkBmKk00u4J40I1k5z",
      "oEUxiTV5mGiO1G-a86XKr",
      "GOB-452sNaiYGSgaboqd9",
      "u4blfcp0CeCWrDEW_skNV",
      "r4Ug2RNWMdPtZhuQfAs8E",
      "qhmVino0Y15URsZdddG7N",
      "0sLtwLQDjNkT8Dc-dqHBV",
      "t0dwHG89EnD5MQUr3bwUN",
      "XCBEgoq2xKeFLxOIcWfkU",
    ],
  },
  {
    id: "3gt42",
    mainPhotos: null,
    mainVideoUrl: `https://player.vimeo.com/video/334157860`,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/337604915?background=1&autoplay=1&loop=1&byline=0&title=0`,
    title: "EVANESCENCE",
    description: `The editorial combines the languid elegance of a long summer day and the ephemerality of a dream and
    explores unadulterated and refined lines inspired by the natural world to reveal our selection of
    designers' talent for sophistication and simplicity.`,
    category: "editorials",
    shopUrl: "/clothing",
  },
  {
    id: "f3443",
    mainPhotos: [
      {
        img1: img59,
        img2: img60,
      },
      {
        img1: img61,
        img2: img62,
      },
      {
        img1: img63,
        img2: img64,
      },
      {
        img1: img65,
        img2: img66,
      },
      {
        img1: img67,
        img2: img68,
      },
    ],
    mainVideoUrl: null,
    previewImageUrl: img3,
    previewVideoUrl: null,
    title: "WARM SENTIMENTS",
    description: `Warm Sentiments is a visual influence to embrace the art of being in the now. The lightweight silky sheers
    and waves of opulent botanical prints gather as exquisite ensembles, representing the present, a moment
    as it happens. As the beaming light enters through diaphanous pastels, working it’s way through the
    memory of each fold, fragile moments are spun into an alluring necklace of time, worn charmingly as a
    testament of now. Floriferous neck pieces and scintillatingly serene stones glisten and gleam against light,
    revealing a whole another universe revolving in it’s own timeless space, devoid of the past and the future.
    Only then do we realise that the significance of now, leaves nothing but warm sentiments behind.`,
    category: "editorials",
    shopUrl: "/blog-collage",
    collageElements: [
      "dhHeK8rYLDz6WJNCDPRso",
      "U4LswifBxguApuQCfdVzh",
      "rbqVqT45JKHNuGguZPf11",
      "8Q8qHTkEuhOa7etlXpXlL",
      "G9ehXFvwqFKNhXg6hauG_",
      "DKGdkuozPH9-iXy1JnjQ4",
      "4sAn0rPKj4FCZ62cJReF2",
      "hLqO-zyCSYC0vomjyQfem",
      "P8IQABv-4Q-PJ3AshyJZO",
      "y0O98y1MSqt9jUQk6-vWD",
      "T21tgZW4Fem2SmClDv7iq",
      "SR1zGb97af3DgC9gyrX9T",
      "iu2QevoLkvnsZuxtYIA_Z",
      "Kmhe4sVHY0IfAYEbIHmyE",
      "hmFzUIhW8TjBIm9RkYNIA",
      "kn9-kxQZPdcoAqzSt0s0-",
      "slJ08aF894UqMEdEJI68u",
      "Q1-ClvS9imSNi4END8B6o",
      "pyncfUdiNa0WLIuY3tM4t",
      "T2eWnJNZcPHr3FkbtqTE4",
      "nxALpdHeM5JT9NxBL7WuF",
    ],
  },
  {
    id: "23f42fvds",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/826688364?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/826682267`,
    title: "1511 GUESTS",
    description: `Dive into an olfactive universe with our special guests for a heartwarming experience of 1511 perfumes.`,
    category: "stories",
    shopUrl: "/perfumes",
  },
  {
    id: "2d32d3qq",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/385504412?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/385067570`,
    title: "HEAVEN TANUDIREDJA",
    description: "",
    category: "stories",
    shopUrl: "/designers/HEAVEN%20TANUDIERDJA",
  },
  {
    id: "cwe34311",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/439565361?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/439543530`,
    title: "LAVIATE",
    description: `For its latest STORIES, 1511 follows the Greek designer duo of Laviate, into their enchanting world of
    craftsmanship. Known for designs created with the pret-a-couture philosophy using hand-drawn prints,
    creative fabrics and unordinary silhouettes, Laviate twin sisters Demetra and Eirini Skalidaki take
    inspiration from the diverse field of biology. The designers are trained biologists who blend science and
    fashion with their intensive research, unique design process and couture expertise. Flirting with new
    technologies while experimenting with female identity, Laviate influences women of today to find their
    comfort of being authentic and individual.`,
    category: "stories",
    shopUrl: "/designers/LAVIATE",
  },
  {
    id: "23r23t2",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349773013?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349773013`,
    title: "FROLOV",
    description: `Frolov had his first catwalk show at Ukrainian Fashion Week in 2014 after graduating in design technology
    and construction at Kiev's National University of Technology and Design. On this occasion, Vogue Ukraine
    called him "the most promising Ukrainian designer" and his brand went on to win Breakthrough of the
    Year at the 2014 Best Fashion Awards. The designer's leitmotif of "intelligent provocation" explores the
    boundaries of sexuality and gender relations through his creations. "I want people to feel comfortable,
    liberated and ready for anything in my designs -whoever the wearer and wherever the day or night might
    lead.`,
    category: "stories",
    shopUrl: "/designers/FROLOV",
  },
  {
    id: "3v435gg",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/337607294?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/336541125`,
    title: "LENA LUMELSKY",
    description: `1511 first STORIES delves into the universe of the Crimean-born, Antwerp’s Royal Academy of Fine Arts
    educated designer Lena Lumelsky and takes us to the core of her creative process in her Atelier in Riga,
    Latvia. Like her highly sought-after garments, Lena Lumelsky is a multilayered character that can be
    enigmatic and perspicuous, dark and whimsical. In her Stories, Lena discusses the notion of fashion, as an
    intersection of craftsmanship, embodiment, social and cultural practice.`,
    category: "stories",
    shopUrl: "/designers/LENA%20LUMELSKY",
  },
  {
    id: "24f3vvvv",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349747991?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349747991`,
    title: "HEAVEN TANUDIREDJA NECKLACE",
    description: `1511 travels to Tokyo and with its latest STORIES explores the awe-inspiring world behind the handmade
    creations of the Indonesian born designer Heaven Tanudiredja. After training from the prestigious
    Antwerps Royal Academy of Fine Arts, Heaven launched his namesake brand with intricate sculptural
    accessories, and has now expanded to mesmerizing womenswear. Heaven believes that fashion is to
    express one’s true emotions. He thrives to bring out the best even from the darkest moments and create
    pieces that are beautiful, pure and unique. In his Stories, the enigmatic yet humble designer shares his
    inspirations, his personal journey and his core beliefs that brought him into limelight.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "h576j75",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349747295?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349747295`,
    title: "ROKSANDA SKIRT",
    description: `Roksanda Ilincic’s signature color-blocking and monumental shapes collections have always been
    interpreted as a contemporary equivalent of the Cubism art movement, to which she cleverly and
    refreshingly incorporates her own version of airiness.
    Ilincic sculpts her ballgown diamond embroidered shapes turquoise silk organza, as much as she cuts it
    from lightweight organza. Resulting in a solid silhouette with yet something carefree and indolent about
    the way the maxi drop waist skirt puddles on the floor. Always a grandeur, though, this skirt can be paired
    with a piece as straightforward as a white poplin shirt.
    Statuesque beauty taking Ilincic voluminous aesthetic, bold use of colour, luxurious fabrics and clean,
    elegant lines in a captivating new direction.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "5g6g675j",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/347027550?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/347027550`,
    title: "KAROLINE LANG SKIRT",
    description: `Created exclusively for 1511, Karoline Lang’s ethereal creation is meticulously crafted from a mosaic of
    intricate macramé lace, delicately coating the translucent chiffon. Bound together by a hand stitched
    leather piping, the elaborately structured wave-cut skirt gathers into a series of soft drapes, thus unveiling
    Beirut designers’ fondness for fine fabrics, geometric cuts and couture techniques.
    Pure lines elegantly accentuated by sophisticated details, complex leatherwork and delicate volumes,
    illustrate an example of an Object of modern grace.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "f34f311s",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349747843?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349747843`,
    title: "NEO-CLASSICAL ARMCHAIR",
    description: `Rummaged by 1511 curator Eka Iukuridze and architect Etienne Herpin from Saint Ouen flea market, the
    largest antiques market in the world, rare 19th century armchair was one of the first pieces of furniture
    bought for the boutique.
    Designed in the spirit of the 18th century, this neoclassic piece of furniture features a exceptional wood
    carving work displayed all around the frame of the back, seat and legs. Combining a still-curving but more
    restrained outline with ornaments such as interlaced bands, pearls, and acanthus leaves according to the
    18th century transitional style, the round back, seat and padded arms have been reupholstered with a
    contrasting shinny pink satin in a Parisian atelier.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "24f4321",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349747884?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349747884`,
    title: "MAISON BAGUES COFFEE TABLES",
    description: `In the prestigious Ivory Suite adorned with Versailles parquet floor and "bas lambris" woodwork walls, sit
    two exquisite 1930's coffee tables from the famous French Maison Ramsey, specialized in glass and gilded
    metal work furniture and decoration.
    With their thin and delicate curvy brass legs in the shape of blossoming branches supporting a fumed
    glass table top these vintage tables combine the timeless sophistication and elegance of Neo-Classicism
    and the nature inspired and sense of geometry of Art Nouveau.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "d423fg3b",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349747386?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349747386`,
    title: "ULYANA SERGEENKO GOWN",
    description: `Created exclusively for 1511, Ulyana Sergeenko’s revisited pink silk satin column gown features an extra
    low bare back draping down to form a one-panel gathered body skimming skirt with an elegant floorsweeping
    hemline. The high round neckline ties at the back with a ribbon while the short sleeves
    gracefully end up as spaghetti straps attached to a ribbon waist band structuring and closing the bodice
    at the back.
    Classic yet destructured silhouette with visible seams and cuts revealing the Russian designer’s fascination
    for unique handmade pieces, careful attention to fabrication secrets perpetuating garment souls and
    traditional crafts.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "2d32t4232d3qq",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/335865024?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/335865024`,
    title: "19TH CENTURY CHANDELIER",
    description: `At the ground floor, an exceptional antique rock crystal pendants chandelier dating back from the end of
    the 19th century, crowned Les Suites ’ mirror room as a superb ornament.
    The original cast brass 7 - light birdcage, chains and ceiling rose form the authentic part of the chandelier,
    as half of the rock crystals were damaged and had to be replaced. Les Suites’ architect Etienne Herpin and
    rock crystal chandelier expert Laurent Vanlian conducted extensive researches to find the missing genuine
    rock crystal pendants and succeeded to restore its original beauty.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
  {
    id: "e21dawsdqwzzz",
    mainPhotos: null,
    mainVideoUrl: null,
    previewImageUrl: null,
    previewVideoUrl: `https://player.vimeo.com/video/349748040?background=1&autoplay=1&loop=1&byline=0&title=0`,
    mainVideoUrl: `https://player.vimeo.com/video/349748040`,
    title: "IRADJ MOINI NECKLACE",
    description: `Created exclusively for 1511, Iradj Moini’s aventurine, garnet, ruby and Swarovski crystals versatile Bow
    Necklace is a tribute to Yves Saint Laurent Haute Couture women’s tuxedo suit. The blend of colors and
    shape form an innovative and luxurious work of art, as the bow can be removed and turned into a pin. The
    large, exquisitely detailed, and three-dimensional creation is handmade by dedicated artisans each
    mastering a specific technique exemplifying world-class craftsmanship.
    Drawing his inspiration from historical characters, nature and artistry, the visionary designer infuses every
    piece with his wondrous imagination to tell a story, revealing each woman’s uniqueness and audacity.`,
    category: "objects",
    shopUrl: "/blog-collage",
    collageElements: [
      "MH2AltwmaF9RhwjmG4jcT",
      "D7b_xLKaUMoKwEWki7lxh",
      "nHQ75pZUzAKWBc0QgZQgS",
      "PQuxUedyo_qrMVq87xOYN",
      "eumZAMwaNJtwnez3Pg-jC",
      "Po9AjUBqINVOGWIQ4tYrD",
      "0yzq8B5QYd7mfKUVjPl64",
      "yHckA3pQhbSSb8KQjaoeY",
      "mmO77VxMJR98OqHRQ87Vk",
      "7R3t76JXeeehJASscSD8-",
      "HrOwBZ7DO3NBCK9GcCmA-",
      "HPdSGqjHnDI_sS2V-iH0o",
      "1s2B3_RBQ2NyU2jwlxrPs",
      "8CN10TxOs_Z9GT5EN1dXZ",
      "kyGHwFuw36muZB8JISNl6",
      "aWlSWHAJlaob5WmpnPXry",
      "zVPbs8okBCQMvOJklDTWO",
      "7nXv5PkrAviOOVd9CRbUX",
      "TPzhqO_TM6u6ChbFFQx2c",
      "r4CIdh0lIznBHPoCirgQO",
      "qVaYzPApH4KoDf5zAJ16f",
    ],
  },
];

const categories = [
  {
    title: "Editorials",
    subtitle: "Artistic projects inspired by our collection",
  },
  {
    title: "Stories",
    subtitle: "Documentaries about our designers",
  },
  {
    title: "Objects",
    subtitle: "Highlights of our exceptional pieces",
  },
];

export { blocks, categories };
