import React from "react";
import "components/Reusable/FilterDrawer/FilterDrawer.scss";

import Drawer from "@mui/material/Drawer";
import Filters from "components/Reusable/Filters/Filters";

const Modal = ({
  setFilteredProducts,
  page,
  queryString,
  setQueryString,
  setPage,
}) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <div
            onClick={toggleDrawer(anchor, true)}
            className="catalogue_mobile_filters_container"
          >
            <span className="mobile_filters_button">Filter & sort</span>
          </div>
          <Drawer
            sx={{ zIndex: 2001 }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Filters
              page={page}
              setPage={setPage}
              queryString={queryString}
              setQueryString={setQueryString}
              setFilteredProducts={setFilteredProducts}
            />
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
  //   return (
  //     <div
  //       style={{
  //         position: "fixed",
  //         top: 0,
  //         left: 0,
  //         width: "100%",
  //         height: "100%",
  //         background: "rgba(255,255,255,0.90)",
  //         zIndex: 9999999,
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <span>THI IS THE MODAL</span>
  //     </div>
  //   );
};

export default Modal;
