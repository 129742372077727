import { createSlice, current } from "@reduxjs/toolkit";
import { nanoid } from "@reduxjs/toolkit";

const initialState = {
  products: [
    {
      id: nanoid(),
      activeTab: true,
      productActive: true,
      title: "New product",
      shortDescription: "",
      longDescription: "",
      details: "",
      color: "",
      size: [{ id: nanoid(), value: 0, quantity: 0 }],
      shoeSize: [{ id: nanoid(), value: 0, quantity: 0 }],
      webColorSchema: "",
      exclusive: false,
      priceHT: 0,
      priceTTC: 0,
      finalPrice: 0,
      discount: 0,
      shortVideo: "",
      productVideo: "",
      photos: [],
      primaryPhoto: "",
      deletePhotos: [],
    },
  ],
};

const NewProductItemSlice = createSlice({
  name: "newProductItem",
  initialState,
  reducers: {
    ADD_ITEM_TAB: (state) => {
      state.products = state.products.map((item) => ({
        ...item,
        activeTab: false,
      }));
      state.products.push({
        id: nanoid(),
        activeTab: true,
        productActive: true,
        title: "New product",
        shortDescription: "",
        longDescription: "",
        details: "",
        color: "",
        size: [{ id: nanoid(), value: 0, quantity: 0 }],
        shoeSize: [{ id: nanoid(), value: 0, quantity: 0 }],
        webColorSchema: "red",
        exclusive: false,
        priceHT: 0,
        priceTTC: 0,
        finalPrice: 0,
        discount: 0,
        shortVideo: "",
        productVideo: "",
        photos: [],
        primaryPhoto: "",
      });
    },
    DELETE_ITEM_TAB: (state, action) => {
      const tab = action.payload;
      const itemPlace = state.products.findIndex((item) => item.id === tab.id);

      state.products = state.products.filter((item) => {
        if (
          state.products.length > 2 &&
          itemPlace !== state.products.length - 1
        )
          state.products[state.products.length - 1].activeTab = true;
        else state.products[state.products.length - 2].activeTab = true;
        return item.id !== tab.id;
      });
    },
    TAB_CHANGE: (state, action) => {
      const tab = action.payload;
      state.products = state.products.map((item) => ({
        ...item,
        activeTab: tab.id === item.id,
      }));
    },
    RESET_ACTIVE_TAB: (state) => {
      state.products = state.products.map((item) => ({
        ...item,
        activeTab: true,
      }));
    },
    ITEM_UPDATE: (state, action) => {
      state.products = state.products.map((item) => {
        const currItem = current(item);

        if (action?.payload?.id === currItem?.id || action.payload.update) {
          for (let key in action.payload) {
            if (action.payload.update) {
              item["id"] = action.payload["id"];
            }
            if (key !== "id") {
              item[key] = action.payload[key];
            }
          }
          return item;
        } else {
          return item;
        }
      });
    },
    REMOVE_PERFUME_SPECIFIC_ITEMS: (state, action) => {
      state.products = state.products.map((item) => {
        const currItem = current(item);
        const { perfumeSample, fragranceActive, ...rest } = currItem;

        return rest;
      });
    },
    ADD_PRODUCT_SIZE: (state, action) => {
      const { itemId } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].size = [
        ...state.products[objIndex].size,
        { id: nanoid(), value: 0, quantity: 0 },
      ];
    },
    UPDATE_PRODUCT_SIZE: (state, action) => {
      const { itemId, sizeId, value, quantity } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      const sizeIndex = state.products[objIndex].size.findIndex(
        (size) => size.id === sizeId
      );
      if (value === "" || value) {
        state.products[objIndex].size[sizeIndex].value = value;
      }
      if (quantity === "" || quantity) {
        state.products[objIndex].size[sizeIndex].quantity = quantity;
      }
    },
    DELETE_PRODUCT_SIZE: (state, action) => {
      const { itemId, sizeId } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].size = state.products[objIndex].size.filter(
        (size) => size.id !== sizeId
      );
    },
    ADD_SHOE_SIZE: (state, action) => {
      const { itemId } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].shoeSize = [
        ...state.products[objIndex].shoeSize,
        { id: nanoid(), value: 0, quantity: 0 },
      ];
    },
    UPDATE_SHOE_SIZE: (state, action) => {
      const { itemId, sizeId, value, quantity } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      const sizeIndex = state.products[objIndex].shoeSize.findIndex(
        (size) => size.id === sizeId
      );
      if (value === "" || value) {
        state.products[objIndex].shoeSize[sizeIndex].value = value;
      }
      if (quantity === "" || quantity) {
        state.products[objIndex].shoeSize[sizeIndex].quantity = quantity;
      }
    },
    DELETE_SHOE_SIZE: (state, action) => {
      const { itemId, sizeId } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].shoeSize = state.products[
        objIndex
      ].shoeSize.filter((size) => size.id !== sizeId);
    },
    SET_PRICE_TTC: (state, action) => {
      const { itemId, value, priceHT } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].priceTTC = value;
      if (value > 0) {
        state.products[objIndex].priceHT = priceHT;
      }
      if (state.products[objIndex].discount >= 0) {
        state.products[objIndex].finalPrice = Math.round(
          value * (1 - state.products[objIndex].discount / 100)
        );
      } else if (state.products[objIndex].discount === "") {
        state.products[objIndex].finalPrice = value;
      }
    },
    SET_PRICE_HT: (state, action) => {
      const { itemId, value, priceTTC } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].priceHT = value;
      if (value > 0) {
        state.products[objIndex].priceTTC = priceTTC;
      }
      // if (state.products[objIndex].discount > 0) {
      state.products[objIndex].finalPrice = Math.round(
        priceTTC * (1 - state.products[objIndex].discount / 100)
      );
      // }
    },
    SET_PRODUCT_ACTIVE: (state, action) => {
      const { itemId } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].productActive =
        !state.products[objIndex].productActive;
    },
    SET_PRODUCT_DISCOUNT: (state, action) => {
      const { itemId, value } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].discount = value;
      state.products[objIndex].finalPrice = Math.round(
        state.products[objIndex].priceTTC * (1 - value / 100)
      );
    },
    SET_PHOTOS: (state, action) => {
      const { itemId, photos, deletePhotos } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].photos = photos;

      if (deletePhotos?.length > 0) {
        state.products[objIndex].deletePhotos = [
          ...state.products[objIndex].deletePhotos,
          ...deletePhotos,
        ];
      }
    },
    RESET_NEW_PRODUCT_ITEM: (state) => {
      state.products = [{ ...initialState.products[0], id: nanoid() }];
    },
    SET_PRIMARY_PHOTO: (state, action) => {
      const { itemId, photo } = action.payload;
      const objIndex = state.products.findIndex((item) => item.id === itemId);
      state.products[objIndex].primaryPhoto = photo;
    },
  },
});

export const {
  ADD_ITEM_TAB,
  DELETE_ITEM_TAB,
  TAB_CHANGE,
  RESET_ACTIVE_TAB,
  ITEM_UPDATE,
  ADD_PRODUCT_SIZE,
  UPDATE_PRODUCT_SIZE,
  DELETE_PRODUCT_SIZE,
  SET_PRICE_TTC,
  SET_PRICE_HT,
  SET_PRODUCT_ACTIVE,
  SET_PRODUCT_DISCOUNT,
  SET_FINAL_PRICE,
  SET_PHOTOS,
  ADD_SHOE_SIZE,
  UPDATE_SHOE_SIZE,
  DELETE_SHOE_SIZE,
  RESET_NEW_PRODUCT_ITEM,
  SET_PRIMARY_PHOTO,
  REMOVE_PERFUME_SPECIFIC_ITEMS,
} = NewProductItemSlice.actions;
export default NewProductItemSlice.reducer;
