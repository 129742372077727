import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Products"],

  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({ page = 0, limit = 0 }) =>
        `/products?page=${page}&limit=${limit}`,
      method: "GET",
      providesTags: ["Products"],
      refetchOnMountOrArgChange: 900,
      keepUnusedDataFor: 900,
    }),
    getProduct: builder.query({
      query: (product) => ({
        url: `/product/${product.product_id}`,
        method: "GET",
        params: {
          id: product.product_id,
        },
      }),
    }),
    getProductsByIds: builder.query({
      query: (products) => ({
        url: `/products/getManyByIds`,
        method: "POST",
        body: { products },
      }),
    }),
    getSameSkuProduct: builder.query({
      query: (product) => {
        return {
          url: `/products/sku`,
          method: "GET",
          params: {
            sku: product.sku,
            id: product.id,
          },
        };
      },
    }),
    getCategoryProducts: builder.query({
      query: (category) => {
        if (category.designer) {
          return {
            url: `/products/designers?page=${category.page}`,
            method: "GET",
            params: { filter: category.filter, designer: category.designer },
          };
        }
        if (category.subCategory && category.page) {
          return {
            url: `/products/${category.category}/${category.subCategory}?page=${category.page}`,
            method: "GET",
            params: { filter: category.filter, designer: category.designer },
          };
        } else if (!category.subCategory && category.page) {
          return {
            url: `/products/${category.category}?page=${category.page}`,
            method: "GET",
            params: { filter: category.filter, designer: category.designer },
          };
        }
      },
    }),
    getProductColor: builder.query({
      query: (category) => ({
        url: `/products/colors`,
        method: "GET",
        params: {
          category: category.category,
          subCategory: category.subCategory,
          designer: category.designer,
        },
      }),
    }),
    getProductSize: builder.query({
      query: (category) => ({
        url: `/products/sizes`,
        method: "GET",
        params: {
          category: category.category,
          subCategory: category.subCategory,
          designer: category.designer,
        },
      }),
    }),
    addProducts: builder.mutation({
      query: (body) => ({
        url: `/admin/products/add`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Products"],
    }),

    deleteProducts: builder.mutation({
      query: (body) => ({
        url: `/admin/products/delete`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    deletePhotos: builder.mutation({
      query: (body) => ({
        url: `/admin/products/imageDelete`,
        method: "DELETE",
        body,
      }),
    }),
    updateProducts: builder.mutation({
      query: (body) => ({
        url: `/admin/products/update`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Products"],
    }),
    uploadPhotos: builder.mutation({
      query: (body) => ({
        url: `/admin/products/imageUpload`,
        method: "POST",
        body,
      }),
    }),
    getDesignerProducts: builder.query({
      query: ({ designer }) => ({
        url: `/products/designers/${designer}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductQuery,
  useGetSameSkuProductQuery,
  useGetCategoryProductsQuery,
  useLazyGetCategoryProductsQuery,
  useLazyGetProductColorQuery,
  useLazyGetProductSizeQuery,
  useAddProductsMutation,
  useDeleteProductsMutation,
  useUploadPhotosMutation,
  useUpdateProductsMutation,
  useDeletePhotosMutation,
  useLazyGetDesignerProductsQuery,
  useGetProductsByIdsQuery,
} = productsApi;
