import React, { useState } from "react";
import "components/Reusable/Filters/children/ExclusiveFilter.scss";
import { useSearchParams } from "react-router-dom";

const ExclusiveFilter = () => {
  const [hideFilter, setHideFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  //To get the size query from the url
  const exclusiveParams = searchParams.get("exclusive");
  const splitValues = exclusiveParams?.split(",");

  //Other than exclusive, to add to the filters below
  const allOtherQueris = {};
  searchParams.forEach((value, key) => {
    if (key !== "exclusive") {
      allOtherQueris[key] = value;
    }
  });

  return (
    <div className="exclusive_filter_container">
      <p
        onClick={() => setHideFilter(!hideFilter)}
        className="exclusive_filter_title"
      >
        Exclusive
      </p>
      {!hideFilter ? (
        <div
          //style={{ visibility: hideFilter ? "hidden" : "visible" }}
          className="exclusive_filter_container"
        >
          <div className="exclusive_filter_wrapper">
            <input
              checked={splitValues?.includes("exclusive")}
              className="exclusive_filter_checkbox"
              type="checkbox"
              id="exclusive"
              onClick={(e) => {
                if (splitValues?.includes(e.target.id)) {
                  //If we removing last size, then remove the entire size query from the url
                  if (splitValues?.length === 1) {
                    setSearchParams({ ...allOtherQueris });
                    return;
                  } else {
                    //If we removing size and it's not the last size item, we remove only that item
                    setSearchParams({
                      ...allOtherQueris,
                      exclusive: exclusiveParams
                        ?.split(",")
                        .filter((item) => item !== e.target.id)
                        .join(","),
                    });
                    return;
                  }
                } else {
                  //If we adding a new size and it doesn't exists on the url query
                  setSearchParams({
                    ...allOtherQueris,
                    exclusive: exclusiveParams
                      ? exclusiveParams + "," + e.target.id
                      : e.target.id,
                  });
                  return;
                }
              }}
            />
            <label htmlFor="exclusive">Filter</label>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExclusiveFilter;
