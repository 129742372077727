import React, { useState } from "react";
import CategoryTable from "./CategoryTable";
import AddCategory from "./AddCategory";
import {
  useGetCategoriesQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
} from "api/categories";
import "./categories.scss";

const Categories = () => {
  const [rows, setRows] = useState([]);

  //API CALLS
  const { data: categories, isLoading } = useGetCategoriesQuery();
  const [
    addCategory,
    { isSuccess: addSuccess, isLoading: addLoading, isError: addError },
  ] = useAddCategoryMutation();
  const [
    deleteCategory,
    {
      isSuccess: deleteSuccess,
      isLoading: deleteLoading,
      isError: deleteError,
    },
  ] = useDeleteCategoryMutation();
  const [
    updateCategory,
    {
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      isError: updateError,
    },
  ] = useUpdateCategoryMutation();
  //Shape the list of categories for render
  const rowItems = async (createData) => {
    if (categories?.length) {
      setRows(
        categories.map((item) =>
          createData(
            item.name,
            item.createdAt,
            item.properties?.length,
            item._id,
            item.parent
          )
        )
      );
    }
  };

  return (
    <div className="categories_tab_container">
      <AddCategory
        rows={rows}
        addCategory={addCategory}
        addSuccess={addSuccess}
        addLoading={addLoading}
        addError={addError}
      />
      <CategoryTable
        rowItems={rowItems}
        categories={categories}
        rows={rows}
        deleteCategory={deleteCategory}
        deleteLoading={deleteLoading}
        deleteSuccess={deleteSuccess}
        addLoading={addLoading}
        isLoading={isLoading}
        deleteError={deleteError}
        updateCategory={updateCategory}
        updateLoading={updateLoading}
        updateError={updateError}
      />
    </div>
  );
};

export default Categories;
