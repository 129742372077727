import React, { useEffect, useState, useRef } from "react";
import ProductsTable from "./ProductsTable";
import Button from "@mui/material/Button";
import "./products.scss";
import { useNavigate } from "react-router-dom";
import { useLazyGetProductsQuery } from "api/products";
import TextField from "@mui/material/TextField";
import { useGetFilteredProducts } from "utils/hooks/useGetFilteredProducts";

const Products = () => {
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const { handleProductFilter, filteredProducts, filter, isLoading } =
    useGetFilteredProducts();

  return (
    <div className="products_tab_container">
      <div className="products_header">
        <h4 className="products_title_text">Products</h4>
        <Button
          ref={scrollRef}
          className="products_add_btn"
          onClick={() => navigate("/adminpanel/products/add")}
          sx={{ mt: 2 }}
          variant="contained"
        >
          + Add new product
        </Button>
      </div>
      <TextField
        onChange={(e) => handleProductFilter(e.target.value)}
        value={filter}
        size="small"
        placeholder="Name of the item"
      />
      <ProductsTable products={filteredProducts} isLoading={isLoading} />
    </div>
  );
};

export default Products;
