import React, { useEffect, useState, useRef } from "react";
import "pages/designers/landing.scss";
import BreadCrumb from "components/Reusable/BreadCrumbs/BreadCrumb";
import { useGetDesignersQuery } from "api/designers";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const DesignersLanding = () => {
  const { data: designers, isLoading, isError, error } = useGetDesignersQuery();
  const [designersFilter, setDesignersFilter] = useState([]);
  const [sortedDesigners, setSortedDesigners] = useState({});
  const navigate = useNavigate();

  const designElements = {
    Bags: "Bag designers",
    Clothing: "Clothing designers",
    Accessories: "Accessory designers",
    Jewellery: "Jewellery designers",
  };

  useEffect(() => {
    if (designers?.length > 0) {
      const sortedDesignersInner = {};
      if (designersFilter?.length > 0) {
        //IF WE HAVE A FILTER SELECTED WE WILL FILTER DESIGNERS BASED ON THAT
        let localFilter = [];
        designersFilter.forEach((filter) => {
          designers.filter((designer) => {
            if (designer.clothingCategories.includes(filter)) {
              localFilter.push(designer.name);
            }
          });
        });
        for (let i = 0; i < localFilter.length; i++) {
          const firstLetter = localFilter[i][0].toUpperCase();

          if (sortedDesignersInner[firstLetter]) {
            sortedDesignersInner[firstLetter].push(localFilter[i]);
          } else {
            sortedDesignersInner[firstLetter] = [localFilter[i]];
          }
        }
        setSortedDesigners(sortedDesignersInner);
      } else {
        //IF WE DONT HAVE ANY FILTER SELECTED WE WILL SHOW ALL DESIGNERS
        for (let i = 0; i < designers.length; i++) {
          const firstLetter = designers[i].name[0].toUpperCase();

          if (sortedDesignersInner[firstLetter]) {
            sortedDesignersInner[firstLetter].push(designers[i].name);
          } else {
            sortedDesignersInner[firstLetter] = [designers[i].name];
          }
        }
        setSortedDesigners(sortedDesignersInner);
      }
    }
  }, [designers, designersFilter]);

  //GET REF OF ALL THE LETTERS
  const letterRefs = useRef([]);

  //SCROLL TO SPECIFIC LETTER WHEN CLICKED
  const scrollToSpecificLetter = (letter) => {
    letterRefs.current.forEach((element, i) => {
      if (element.innerHTML === letter) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + -75;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    });
  };

  return (
    <div className="designers_landing_container">
      <Helmet>
        <title>
          1511 Paris | Discover Designers: Exploring Creative Fashion
          Collections
        </title>
        {/* <link rel="canonical" href="https://1511.paris/designers" /> */}
        <meta
          name="keywords"
          content="1511 Paris Designers, 1511 Paris Designer Collections, designers, clothing designers, decor designers, Designer Collections, Fashion Labels, Design, Modern decor, Handcrafted, Handmade, Exclusive, Unique, Craftsmanship, Biyan, Delpozo, Djaba Diassamidze, Frolov, Giambattista Valli, Haider Ackermann, Iradj Moini, Janashia, Khoon Hooi, Laviate, Lena Lumeslky, Maison Rabih Kayrouz, Martin Grant, Nina Ricci, Oscar de La Renta, Rochas, Tiger in the Rain, Vika Gazinskaya, Heaven Tanudiredja"
        />
        <meta
          name="description"
          content="Welcome to our 'Discover Designers' page, where you can explore a curated selection of talented clothing, perfume, decor designers and their exquisite fashion collections. Immerse yourself in the world of creativity and craftsmanship as you browse through a diverse range of designer brands and discover their unique styles. From renowned fashion labels to emerging designers, our platform is your gateway to the latest trends and innovative designs. Explore the portfolios, profiles, and products of these designers, and find inspiration for your own fashion journey. Unleash your style and embrace the allure of fashion with our carefully curated selection of designers at your fingertips."
        />
        <meta
          property="og:description"
          content="Welcome to our 'Discover Designers' page, where you can explore a curated selection of talented clothing, perfume, decor designers and their exquisite fashion collections. Immerse yourself in the world of creativity and craftsmanship as you browse through a diverse range of designer brands and discover their unique styles. From renowned fashion labels to emerging designers, our platform is your gateway to the latest trends and innovative designs. Explore the portfolios, profiles, and products of these designers, and find inspiration for your own fashion journey. Unleash your style and embrace the allure of fashion with our carefully curated selection of designers at your fingertips."
        ></meta>
      </Helmet>
      <BreadCrumb category={"designers"} />
      <h1>DESIGNERS</h1>
      <div className="designers_main_container">
        {/* FILTERS */}
        <div className="designers_filters">
          {Object.entries(designElements).map((element) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDesignersFilter([...designersFilter, element[0]]);
                      } else {
                        setDesignersFilter(
                          designersFilter.filter((el) => el !== element[0])
                        );
                      }
                    }}
                    checked={designersFilter.includes(element[0])}
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 20 },
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
                label={<Typography>{element[1]}</Typography>}
              ></FormControlLabel>
            );
          })}
        </div>
        {/* MAIN CONTENT */}
        {!isLoading ? (
          <div>
            {/* ALPHABET LETTERS */}
            <div className="letters_container">
              {Object.keys(sortedDesigners)
                .sort((a, b) => {
                  // Check if a and b are both numbers or both letters
                  const aIsLetter = isNaN(a.charAt(0));
                  const bIsLetter = isNaN(b.charAt(0));

                  if (!aIsLetter && !bIsLetter) {
                    // If both are numbers, sort numerically
                    return a - b;
                  } else if (aIsLetter && bIsLetter) {
                    // If both are letters, sort alphabetically
                    return a.localeCompare(b);
                  } else if (aIsLetter) {
                    // If only a is a letter, put it second
                    return 1;
                  } else {
                    // If only b is a letter, put it second
                    return -1;
                  }
                })
                .map((letter) => {
                  return (
                    <div
                      onClick={() => scrollToSpecificLetter(letter)}
                      className="letter"
                      key={letter}
                    >
                      <span>{letter}</span>
                    </div>
                  );
                })}
            </div>
            {/* DESIGNERS MAIN CONTENT*/}
            <div
              style={{
                textAlign: "center",
              }}
            >
              <ul className="letter_container">
                {Object.keys(sortedDesigners)
                  .sort((a, b) => {
                    // Check if a and b are both numbers or both letters
                    const aIsLetter = isNaN(a.charAt(0));
                    const bIsLetter = isNaN(b.charAt(0));

                    if (!aIsLetter && !bIsLetter) {
                      // If both are numbers, sort numerically
                      return a - b;
                    } else if (aIsLetter && bIsLetter) {
                      // If both are letters, sort alphabetically
                      return a.localeCompare(b);
                    } else if (aIsLetter) {
                      // If only a is a letter, put it second
                      return 1;
                    } else {
                      // If only b is a letter, put it second
                      return -1;
                    }
                  })
                  .map((letter, index) => {
                    return (
                      <li className="letter_wrapper" key={letter}>
                        <h2
                          ref={(el) => (letterRefs.current[index] = el)}
                          style={{ marginTop: index === 0 ? 0 : "2.5rem" }}
                        >
                          {letter}
                        </h2>

                        {sortedDesigners[letter].map((designer) => {
                          return (
                            <div className="letter">
                              <Link
                                to={`/designers/${designer}`}
                                state={{
                                  designerInfo: designers.find(
                                    (item) => item.name === designer
                                  ),
                                }}
                              >
                                <span>{designer.toLowerCase()}</span>
                              </Link>
                            </div>
                          );
                        })}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: "2rem" }}>
            <CircularProgress />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignersLanding;
