import React, { useEffect } from "react";
import { useGetAllSubCategoriesMutation } from "api/categories";
import { NavLink, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import "components/Reusable/Filters/children/CategoriesFilter.scss";

const CategoriesFilter = () => {
  const { category } = useParams();
  const [getSubCategories, { data: subCategories }] =
    useGetAllSubCategoriesMutation();
  useEffect(() => {
    getSubCategories(category);
  }, [category]);

  return (
    <div className="category_filter_container">
      <div>
        <NavLink
          style={{
            fontWeight: "600",
            color: "#3e3e3e",
          }}
          to={`/${category}`}
        >
          {capitalizeFirstLetter(category)}
        </NavLink>
      </div>
      {subCategories?.categories?.map((subCategory) => {
        return (
          <div key={subCategory?.name} className="category_list_item">
            <NavLink
              style={({ isActive }) =>
                isActive
                  ? {
                      textDecoration: "underline",
                      marginLeft: "10px",
                      color: "#3e3e3e",
                    }
                  : { marginLeft: "10px", color: "#3e3e3e" }
              }
              to={`/${category}/${subCategory?.name}`}
            >
              {subCategory?.name}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default CategoriesFilter;
