import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { ITEM_UPDATE } from "store/slices/NewProductItemSlice";
import { useDispatch } from "react-redux";
import { REMOVE_PERFUME_SPECIFIC_ITEMS } from "store/slices/NewProductItemSlice";

const Perfume = ({ item }) => {
  const dispatch = useDispatch();
  const itemUpdate = (fieldName, value, item) => {
    let obj = {};
    obj[fieldName] = value;
    obj.id = item.id;
    dispatch(ITEM_UPDATE(obj));
  };

  useEffect(() => {
    itemUpdate("perfumeSample", item?.perfumeSample || 0, item);
    itemUpdate("fragranceActive", item?.fragranceActive || false, item);

    //REMOVING samples and fragrances from the item if another category chosen
    return () => dispatch(REMOVE_PERFUME_SPECIFIC_ITEMS());
  }, []);

  return (
    <div className="perfumes_sample_container">
      <h5 className="dropdowns_label" htmlFor="sku">
        Perfumes
      </h5>
      {item?.perfumeSample !== null ? (
        <div className="perfumes_sample_item">
          <h6>Enter sample value</h6>
          <span>0 = item is not a sample it is a perfume</span>
          <span>1,2,3,4,5 etc. = amount of perfume samples</span>
          <TextField
            value={item?.perfumeSample}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                itemUpdate("perfumeSample", 0, item);
              } else {
                itemUpdate("perfumeSample", Number(e.target.value), item);
              }
            }}
          />
        </div>
      ) : null}
      {item?.perfumeSample === 0 ? (
        <div className="perfumes_sample_activate">
          <h6>Add this perfume to the list of Fragrances for samples?</h6>
          <Switch
            checked={item?.fragranceActive}
            onChange={(e) =>
              itemUpdate("fragranceActive", !item?.fragranceActive, item)
            }
            name="fragranceActive"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Perfume;
