import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Orders", "userOrders"],
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: ({ limit, page }) =>
        `/orders/getAllOrders?page=${page}&limit=${limit}`,
      providesTags: ["Orders"],
    }),
    getOrders: builder.query({
      query: () => `/orders`,
      providesTags: ["userOrders"],
    }),
    getOrder: builder.query({
      query: (orderId) => `/orders/${orderId}`,
      providesTags: (result, error, orderId) => [
        { type: "Orders", id: orderId },
      ],
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({
        url: `/orders/deleteSpecificOrder`,
        method: "DELETE",
        body: { orderId },
      }),
    }),
    modifyOrder: builder.mutation({
      query: (body) => ({
        url: `/orders/modifyOrder`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderQuery,
  useDeleteOrderMutation,
  useLazyGetAllOrdersQuery,
  useModifyOrderMutation,
} = ordersApi;
