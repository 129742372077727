import React, { useEffect } from "react";
import "pages/profile/ReturnItemModal.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress, FormControl } from "@mui/material";
import { useCreateReturnMutation } from "api/returns";
import { errorToast, successToast } from "utils/toasts";
import { v4 as uuidv4 } from "uuid";
import useWindowDimensions from "utils/windowWidth";

const ReturnItemModal = ({ returnItem, setOpen, returnPage = false }) => {
  const names = [
    "Wrong Item",
    "The item is defective",
    "Does not fit",
    "Not as described",
    "Changed my mind",
  ];
  const [reasons, setReasons] = React.useState({});
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [reasonAlert, setReasonAlert] = React.useState([]);
  const [activePage, setActivePage] = React.useState(1); // 1 - return items, 2 - return success
  const allItemsReturned = returnItem?.items?.every((item) => {
    return item?.returnStatus;
  });
  const [isTimeLeft, setIsTimeLeft] = React.useState(true);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const discount_percentage =
    (returnItem?.price_discount / returnItem?.price_subtotal) * 100;

  //API CALLS
  const [createReturn, { data, error, isLoading, isSuccess }] =
    useCreateReturnMutation();

  const handleChange = (event, id) => {
    if (event.target.name === id) {
      const {
        target: { value },
      } = event;
      if (value.length !== 0 && reasonAlert?.length !== 0) {
        setReasonAlert(reasonAlert.filter((item) => item !== id));
      }
      if (reasons[id]) {
        setReasons({
          ...reasons,
          [id]: [...value],
        });
      } else {
        setReasons({ ...reasons, [id]: value });
      }
    }
  };

  const handleSelectClick = (e) => {
    e.preventDefault();
    const {
      target: { name },
    } = e;

    if (reasons[name]) {
      const { [name]: value, ...rest } = reasons;
      setReasons({ ...rest });
    } else {
      setReasons({ ...reasons, [name]: [] });
    }
  };

  const handleReturnSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(reasons)?.length === 0)
      return alert("Please select an item for return");
    if (
      Object.keys(reasons)
        .map((key) => {
          if (reasons[key].length === 0) {
            setReasonAlert([...reasonAlert, key]);
            return true;
          } else {
            return false;
          }
        })
        .includes(true)
    )
      return alert("Please select a reason for return");

    const itemsForReturn = returnItem?.items
      .map((item) => {
        if (reasons[item?.id + item?.selectedSize]) {
          return {
            itemToReturn: item,
            returnReason: reasons[item?.id + item?.selectedSize],
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    const returnData = {
      orderId: returnItem?.id,
      items: itemsForReturn,
      userId: returnItem.userId,
      amount: totalPrice,
    };

    createReturn(returnData);
  };

  useEffect(() => {
    const createdDate = new Date(returnItem?.createdAt);
    const returnDate = createdDate.setDate(createdDate.getDate() + 14);
    const now = new Date();
    const diff = returnDate - now;

    if (diff > 0) {
      setIsTimeLeft(true);
    } else {
      setIsTimeLeft(false);
    }
  }, []);

  useEffect(() => {
    let price = 0;
    returnItem?.items?.forEach((item) => {
      if (Object.keys(reasons).length === 0) return setTotalPrice(0);
      Object.keys(reasons).forEach((id) => {
        if (item?.id + item.selectedSize === id) {
          price += item.finalPrice * item.quantity;
        }
      });
    });
    setTotalPrice(price);
  }, [reasons]);

  useEffect(() => {
    if (returnPage) {
      // calculate totalPrice
      let price = 0;
      returnItem?.items?.forEach((item) => {
        if (item?.returnStatus) {
          price += item.finalPrice * item.quantity;
        }
      });
      setTotalPrice(price);
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      successToast("Return request submitted");
      setActivePage(2);
    }

    if (error) {
      if (error?.message === "ORDER_EXPIRED") {
        setIsTimeLeft(false);
      }
      errorToast();
    }
  }, [isSuccess, error]);

  const itemsLeftToReturn = returnItem?.items?.filter((itm) =>
    returnPage
      ? itm?.returnStatus !== undefined
      : itm?.returnStatus === undefined
  );

  const renderDescription = (item) => {
    return (
      <div
        style={{
          color: `${
            reasonAlert.includes(item?.id + item?.selectedSize)
              ? "red"
              : "black"
          }`,
          opacity: `${item?.returnStatus ? "0.5" : "1"}`,
        }}
        className="return_modal_item_details"
      >
        <p style={{ fontWeight: "600" }}>{item?.designer}</p>
        <p style={{ fontWeight: "600" }}>{item?.shortDescription}</p>
        {item?.size?.length > 0 ? <p>Size: {item?.selectedSize}</p> : null}
        {item?.color ? <p>Color: {item?.color}</p> : null}

        <p>Quantity: {item?.quantity}</p>
        <p>
          {(Number(item?.finalPrice) * Number(item?.quantity)).format(
            2,
            3,
            " ",
            ","
          )}{" "}
          €
        </p>
      </div>
    );
  };

  return activePage === 1 ? (
    <div className="return_modal_container">
      {/* <div className="return_modal_wrapper"> */}
      <h2 className="return_modal_title">
        Order#: <span className="return_modal_subtitle">{returnItem?.id}</span>
      </h2>
      <div className="return_modal_content_container">
        {isTimeLeft &&
          (returnPage ? (
            <h3>Items included in this return:</h3>
          ) : itemsLeftToReturn?.length > 0 ? (
            <h3>Choose items you would like to return</h3>
          ) : null)}
        {itemsLeftToReturn?.length > 0 && isTimeLeft ? (
          <form
            onSubmit={handleReturnSubmit}
            className="return_modal_content_wrapper"
          >
            {itemsLeftToReturn.map((item) => (
              <div key={uuidv4()} className="return_modal_item">
                <div className="return_modal_item_mobile">
                  <label className="return_modal_item_checkbox">
                    <input
                      disabled={
                        item?.returnStatus || item?.category === "Perfumes"
                      }
                      type="checkbox"
                      className="return_modal_checkbox"
                      name={item?.id + item?.selectedSize}
                      onChange={handleSelectClick}
                      checked={
                        Object.keys(reasons).includes(
                          item?.id + item?.selectedSize
                        ) || !!item?.returnStatus
                      }
                    />
                  </label>
                  <div className="return_modal_item_image">
                    <img
                      className={`${
                        (item?.returnStatus || item?.category === "Perfumes") &&
                        "grayscale"
                      } `}
                      src={item?.photos[0]?.url}
                      alt={item?.photos[0]?.alt}
                    />
                  </div>
                  {/* PRODUCT DESCRIPTION MOBILE*/}
                  {isMobile ? renderDescription(item) : null}
                </div>
                <div className="return_modal_item_dropdown">
                  {/* PRODUCT DESCRIPTION WEB*/}
                  {!isMobile ? renderDescription(item) : null}
                  {item?.category !== "Perfumes" && !item?.returnStatus ? (
                    <FormControl sx={{ width: 250, mt: 3 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        Why would you like to return this item?
                      </InputLabel>
                      <Select
                        disabled={item?.returnStatus}
                        sx={{ zIndex: 10100 }}
                        style={{ zIndex: 10100 }}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={reasons[item?.id + item?.selectedSize] || []}
                        onChange={(e) => {
                          handleChange(e, item?.id + item?.selectedSize);
                        }}
                        name={item?.id + item?.selectedSize}
                        input={
                          <OutlinedInput label="Why would you like to return this item?" />
                        }
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {names?.map((name) => (
                          <MenuItem
                            sx={{ zIndex: 10100 }}
                            key={name}
                            value={name}
                          >
                            <Checkbox
                              checked={
                                reasons[item?.id + item?.selectedSize]?.indexOf(
                                  name
                                ) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : item?.category === "Perfumes" ? (
                    <p className="return_modal_item_submitted">
                      Perfume items are final sale
                    </p>
                  ) : (
                    <p className="return_modal_item_submitted">
                      Product return submitted
                    </p>
                  )}
                </div>
              </div>
            ))}
            {/* TOTAL */}
            {discount_percentage ? (
              <>
                <div className="return_modal_original">
                  <p>Original Price: {totalPrice.format(2, 3, " ", ",")} €</p>
                </div>
                <div className="return_modal_discount">
                  <p>
                    Discount used at checkout: {discount_percentage.toFixed(2)}{" "}
                    %
                  </p>
                </div>{" "}
              </>
            ) : null}
            <div
              style={{ marginTop: `${discount_percentage ? 0 : "5rem"}` }}
              className="return_modal_total"
            >
              Total refund:{" "}
              {(totalPrice - (totalPrice / 100) * discount_percentage).format(
                2,
                3,
                " ",
                ","
              )}{" "}
              €
            </div>
            <div className="return_modal_submit_btn">
              {!returnPage ? (
                <button disabled={isLoading || allItemsReturned} type="submit">
                  {isLoading ? <CircularProgress size={25} /> : "Submit"}
                </button>
              ) : (
                <button onClick={() => setOpen(false)}>Close</button>
              )}
            </div>
          </form>
        ) : (
          <>
            <h3 style={{ textAlign: "center" }}>
              {!isTimeLeft
                ? `This order is no longer eligible for return.
                Please contact our customer service for more information.`
                : `Return request submitted. No other items are available for return.`}
            </h3>
            <div className="return_modal_submit_btn">
              <button onClick={() => setOpen(false)}>Close</button>
            </div>
          </>
        )}
      </div>
      {/* </div> */}
    </div>
  ) : (
    <div className="return_modal_container">
      <div className="return_success_container">
        <h2>Return success</h2>
        <p>
          Congratulations! Your return was submitted successfully. Our team will
          get back to you after the review, we will aslo update the status of
          your return to keep you posted.
        </p>
        <p className="return_id">
          Return id: <span>{data?.returnId}</span>
        </p>
        <div className="return_modal_submit_btn">
          <button onClick={() => setOpen(false)}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ReturnItemModal;

// COMPONENT CODE SETUP FOR SELECTED SIZE SPECIFICALLY (NOT 100% COMPLETE) VALIDATIONS ARE NEED TO BE TWEAKED BUT THE FUNCTIONALITY OF SELECTING ITEMS AND REASONS FOR RETURN IS WORKING
// LEAVING IT HERE IN CASE THERE WILL BE A NEED TO USE IT IN THE FUTURE

// //TODO:
// // 1. Connect checkboxes
// // 2. Calculate total price of return after checkbox are connected(need local state)
// // 3. Need to connect dropdown to a variable to keep the value of the dropdown
// // Maybe create a global array of elements for return and their correspondingvalues???

// import React, { useEffect } from "react";
// import "pages/profile/ReturnItemModal.scss";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";
// import { CircularProgress, FormControl } from "@mui/material";
// import { useCreateReturnMutation } from "api/returns";
// import { errorToast, successToast } from "utils/toasts";
// import { v4 as uuidv4 } from "uuid";
// // import { INCRESE_QUANTITY } from "store/slices/CartSlice";
// // import { useDispatch } from "react-redux";
// // import AddIcon from "@mui/icons-material/Add";
// // import RemoveIcon from "@mui/icons-material/Remove";

// const ReturnItemModal = ({ returnItem, setOpen, returnPage = false }) => {
//   const names = [
//     "Wrong Item",
//     "The item is defective",
//     "Does not fit",
//     "Not as described",
//     "Changed my mind",
//   ];
//   const [reasons, setReasons] = React.useState({});
//   const [totalPrice, setTotalPrice] = React.useState(0);
//   const [reasonAlert, setReasonAlert] = React.useState([]);
//   const [activePage, setActivePage] = React.useState(1); // 1 - return items, 2 - return success
//   const allItemsReturned = returnItem?.items?.every((item) => {
//     return item?.returnStatus;
//   });
//   const [isTimeLeft, setIsTimeLeft] = React.useState(true);

//   // const dispatch = useDispatch();
//   // const maxdQuantity = (product) => {
//   //   const value = product.size.find((item) => {
//   //     if (Number(item.value) === Number(product.selectedSize)) {
//   //       return item;
//   //     }
//   //   });

//   //   if (value) {
//   //     return value.quantity;
//   //   } else {
//   //     return 0;
//   //   }
//   // };

//   const discount_percentage =
//     (returnItem?.price_discount / returnItem?.price_subtotal) * 100;

//   //API CALLS
//   const [createReturn, { data, error, isLoading, isSuccess }] =
//     useCreateReturnMutation();

//   const handleChange = (event, id, propsItem) => {
//     const product = returnItem?.items.find(
//       (item) => item?.selectedSize == propsItem?.selectedSize
//     );

//     if (event.target.name === id) {
//       let {
//         target: { value },
//       } = event;
//       if (value.length !== 0 && reasonAlert?.length !== 0) {
//         setReasonAlert(reasonAlert.filter((item) => item !== id));
//       }

//       // if (Array.isArray(value)) {
//       //   value = value.flat(Infinity);
//       //   value = [...new Set(value)];
//       // }

//       if (reasons[id]) {
//         if (reasons[id].find((item) => item.size === product?.selectedSize)) {
//           setReasons({
//             ...reasons,
//             [id]: reasons[id]?.map((item) => {
//               if (item.size == product?.selectedSize) {
//                 return {
//                   ...item,
//                   reasons: value,
//                 };
//               } else {
//                 return item;
//               }
//             }),
//           });
//         } else {
//           setReasons({
//             ...reasons,
//             [id]: [
//               ...reasons[id],
//               {
//                 reasons: value,
//                 size: product?.selectedSize,
//               },
//             ],
//           });
//         }
//       } else {
//         setReasons({
//           [id]: [
//             {
//               reasons: value,
//               size: product?.selectedSize,
//             },
//           ],
//         });
//       }
//     }
//   };

//   //Handle product checkbox click
//   const handleSelectClick = (e, product) => {
//     e.preventDefault();
//     const reasonsArray = reasons[product?.id];

//     // if no reasons array exists for the product, create one
//     if (!reasonsArray) {
//       setReasons({
//         ...reasons,
//         [product?.id]: [
//           {
//             reasons: [],
//             size: product?.selectedSize,
//           },
//         ],
//       });
//     }

//     // if reasons array exists for the product, check if the size also exists
//     if (reasonsArray?.length > 0) {
//       const doesItemSizeAlsoExists = reasonsArray.find(
//         (item) => item?.size == product?.selectedSize
//       );

//       // if size does not exist, create a new one
//       if (!doesItemSizeAlsoExists) {
//         setReasons({
//           ...reasons,
//           [product?.id]: [
//             ...reasonsArray,
//             {
//               reasons: [],
//               size: product?.selectedSize,
//             },
//           ],
//         });
//       } else {
//         // if size exists, filter out the size
//         const filterOutItem = reasonsArray.filter(
//           (item) => item?.size !== product?.selectedSize
//         );

//         // if the size was the only one in the array, delete the product from the reasons object
//         if (filterOutItem.length === 0) {
//           const tempReasons = reasons;
//           delete tempReasons[product?.id];
//           setReasons({ ...tempReasons });
//         } else {
//           setReasons({
//             ...reasons,
//             [product?.id]: filterOutItem,
//           });
//         }
//       }
//     }
//   };

//   const handleReturnSubmit = (e) => {
//     e.preventDefault();

//     if (Object.keys(reasons)?.length === 0)
//       return alert("Please select an item for return");
//     if (
//       Object.keys(reasons)
//         ?.map((key) => {
//           reasons[key].map((item) => {
//             if (item?.reasons.length === 0) {
//               setReasonAlert([...reasonAlert, key]);
//               return true;
//             }
//             return false;
//           });
//         })
//         .includes(true)
//     )
//       return alert("Please select a reason for return");

//     const itemsForReturn = returnItem?.items
//       ?.map((item) => {
//         if (reasons[item?.id]) {
//           return {
//             itemToReturn: item,
//             returnReason: reasons[item?.id],
//           };
//         } else {
//           return null;
//         }
//       })
//       .filter((item) => item !== null);

//     const returnData = {
//       orderId: returnItem?.id,
//       items: itemsForReturn,
//       userId: returnItem.userId,
//       amount: totalPrice,
//     };

//     createReturn(returnData);
//   };

//   useEffect(() => {
//     const createdDate = new Date(returnItem?.createdAt);
//     const returnDate = createdDate.setDate(createdDate.getDate() + 14);
//     const now = new Date();
//     const diff = returnDate - now;

//     if (diff > 0) {
//       setIsTimeLeft(true);
//     } else {
//       setIsTimeLeft(false);
//     }
//   }, []);

//   useEffect(() => {
//     let price = 0;
//     returnItem?.items?.forEach((item) => {
//       if (Object.keys(reasons).length === 0) return setTotalPrice(0);
//       Object.keys(reasons).forEach((id) => {
//         if (item?.id === id) {
//           price += item.finalPrice * item.quantity;
//         }
//       });
//     });
//     setTotalPrice(price);
//   }, [reasons]);

//   useEffect(() => {
//     if (returnPage) {
//       // calculate totalPrice
//       let price = 0;
//       returnItem?.items?.forEach((item) => {
//         if (item?.returnStatus) {
//           price += item.finalPrice * item.quantity;
//         }
//       });
//       setTotalPrice(price);
//     }
//   }, []);

//   useEffect(() => {
//     if (isSuccess) {
//       successToast("Return request submitted");
//       setActivePage(2);
//     }

//     if (error) {
//       if (error?.message === "ORDER_EXPIRED") {
//         setIsTimeLeft(false);
//       }
//       errorToast();
//     }
//   }, [isSuccess, error]);

//   const itemsLeftToReturn = returnItem?.items?.filter((itm) =>
//     returnPage
//       ? itm?.returnStatus !== undefined
//       : itm?.returnStatus === undefined
//   );

//   return activePage === 1 ? (
//     <div className="return_modal_container">
//       {/* <div className="return_modal_wrapper"> */}
//       <h2 className="return_modal_title">
//         Order#: <span className="return_modal_subtitle">{returnItem?.id}</span>
//       </h2>
//       <div className="return_modal_content_container">
//         {isTimeLeft &&
//           (returnPage ? (
//             <h3>Items included in this return:</h3>
//           ) : itemsLeftToReturn?.length > 0 ? (
//             <h3>Choose items you would like to return</h3>
//           ) : null)}
//         {itemsLeftToReturn?.length > 0 && isTimeLeft ? (
//           <form
//             onSubmit={handleReturnSubmit}
//             className="return_modal_content_wrapper"
//           >
//             {itemsLeftToReturn?.map((item) => {
//               return (
//                 <div key={uuidv4()} className="return_modal_item">
//                   <div className="return_modal_item_mobile">
//                     <label className="return_modal_item_checkbox">
//                       <input
//                         disabled={
//                           item?.returnStatus || item?.category === "Perfumes"
//                         }
//                         type="checkbox"
//                         className="return_modal_checkbox"
//                         name={item?.id}
//                         onChange={(e) => handleSelectClick(e, item)}
//                         checked={
//                           !!reasons[item?.id]?.find(
//                             (prod) => prod?.size == item?.selectedSize
//                           )
//                         }
//                       />
//                     </label>
//                     <div className="return_modal_item_image">
//                       <img
//                         className={`${
//                           (item?.returnStatus ||
//                             item?.category === "Perfumes") &&
//                           "grayscale"
//                         } `}
//                         src={item?.photos[0]?.url}
//                         alt={item?.photos[0]?.alt}
//                       />
//                     </div>
//                   </div>
//                   <div className="return_modal_item_dropdown">
//                     <div
//                       style={{
//                         color: `${
//                           reasonAlert.includes(item?.id) ? "red" : "black"
//                         }`,
//                         opacity: `${item?.returnStatus ? "0.5" : "1"}`,
//                       }}
//                       className="return_modal_item_details"
//                     >
//                       <p style={{ fontWeight: "600" }}>{item?.designer}</p>
//                       <p style={{ fontWeight: "600" }}>
//                         {item?.shortDescription}
//                       </p>
//                       {item?.size?.length > 0 ? (
//                         <p>Size: {item?.selectedSize}</p>
//                       ) : null}
//                       {item?.color ? <p>Color: {item?.color}</p> : null}

//                       <p>Quantity: {item?.quantity}</p>
//                       <p>
//                         {(
//                           Number(item?.finalPrice) * Number(item?.quantity)
//                         ).format(2, 3, " ", ",")}{" "}
//                         €
//                       </p>
//                     </div>
//                     {item?.category !== "Perfumes" && !item?.returnStatus ? (
//                       <FormControl sx={{ width: 250, mt: 3 }}>
//                         <InputLabel id="demo-multiple-checkbox-label">
//                           Why would you like to return this item?
//                         </InputLabel>
//                         <Select
//                           disabled={item?.returnStatus}
//                           sx={{ zIndex: 10100 }}
//                           style={{ zIndex: 10100 }}
//                           labelId="demo-multiple-checkbox-label"
//                           id="demo-multiple-checkbox"
//                           multiple
//                           value={
//                             reasons[item?.id]
//                               ?.map((reasonItem) => {
//                                 if (reasonItem?.size == item?.selectedSize)
//                                   return reasonItem?.reasons;
//                               })
//                               ?.filter((filterItem) => filterItem !== undefined)
//                               .flat(Infinity) || []
//                           }
//                           onChange={(e) => {
//                             handleChange(e, item?.id, item);
//                           }}
//                           name={item?.id}
//                           input={
//                             <OutlinedInput label="Why would you like to return this item?" />
//                           }
//                           renderValue={(selected) => selected.join(", ")}
//                         >
//                           {names?.map((name) => (
//                             <MenuItem
//                               sx={{ zIndex: 10100 }}
//                               key={name}
//                               value={name}
//                             >
//                               <Checkbox
//                                 checked={
//                                   reasons[item?.id]
//                                     ?.find(
//                                       (reasonItem) =>
//                                         reasonItem?.size == item?.selectedSize
//                                     )
//                                     ?.reasons?.indexOf(name) > -1
//                                 }
//                               />
//                               <ListItemText primary={name} />
//                             </MenuItem>
//                           ))}
//                         </Select>
//                       </FormControl>
//                     ) : item?.category === "Perfumes" ? (
//                       <p className="return_modal_item_submitted">
//                         Perfume items are final sale
//                       </p>
//                     ) : (
//                       <p className="return_modal_item_submitted">
//                         Product return submitted
//                       </p>
//                     )}
//                   </div>
//                 </div>
//               );
//             })}
//             {/* TOTAL */}
//             {discount_percentage ? (
//               <>
//                 <div className="return_modal_original">
//                   <p>Original Price: {totalPrice.format(2, 3, " ", ",")} €</p>
//                 </div>
//                 <div className="return_modal_discount">
//                   <p>Discount used at checkout: {discount_percentage} %</p>
//                 </div>{" "}
//               </>
//             ) : null}
//             <div
//               style={{ marginTop: `${discount_percentage ? 0 : "5rem"}` }}
//               className="return_modal_total"
//             >
//               Total refund:{" "}
//               {(totalPrice - (totalPrice / 100) * discount_percentage).format(
//                 2,
//                 3,
//                 " ",
//                 ","
//               )}{" "}
//               €
//             </div>
//             <div className="return_modal_submit_btn">
//               {!returnPage ? (
//                 <button disabled={isLoading || allItemsReturned} type="submit">
//                   {isLoading ? <CircularProgress size={25} /> : "Submit"}
//                 </button>
//               ) : (
//                 <button onClick={() => setOpen(false)}>Close</button>
//               )}
//             </div>
//           </form>
//         ) : (
//           <>
//             <h3 style={{ textAlign: "center" }}>
//               {!isTimeLeft
//                 ? `This order is no longer eligible for return.
//                 Please contact our customer service for more information.`
//                 : `Return request submitted. No other items are available for return.`}
//             </h3>
//             <div className="return_modal_submit_btn">
//               <button onClick={() => setOpen(false)}>Close</button>
//             </div>
//           </>
//         )}
//       </div>
//       {/* </div> */}
//     </div>
//   ) : (
//     <div className="return_modal_container">
//       <div className="return_success_container">
//         <h2>Return success</h2>
//         <p>
//           Congratulations! Your return was submitted successfully. Our team will
//           get back to you after the review, we will aslo update the status of
//           your return to keep you posted.
//         </p>
//         <p className="return_id">
//           Return id: <span>{data?.returnId}</span>
//         </p>
//         <div className="return_modal_submit_btn">
//           <button onClick={() => setOpen(false)}>Close</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReturnItemModal;
