import React from "react";
import Button from "@mui/material/Button";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { ReactComponent as StarIcon } from "assets/star.svg";

import {
  SET_PHOTOS,
  SET_PRIMARY_PHOTO,
} from "store/slices/NewProductItemSlice";
import { useDispatch, useSelector } from "react-redux";

const Photos = ({ item }) => {
  const [images, setImages] = React.useState([]);
  const { primaryPhoto } = useSelector((state) => state.newProductItem);

  const { photos } = item;

  const dispatch = useDispatch();
  const imageSelector = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    input.multiple = true;
    input.onchange = async (e) => {
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      const tempArr = [...images, ...selectedFilesArray];

      const links = tempArr.map((file) => ({
        url: URL.createObjectURL(file),
        alt: file.name,
        primary: false,
      }));

      dispatch(SET_PHOTOS({ itemId: item.id, photos: [...photos, ...links] }));
    };
    input.click();
  };

  const deleteImage = (e, image) => {
    e.stopPropagation();
    const permission = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (permission) {
      const tempArr = photos.filter((img) => img !== image);
      dispatch(
        SET_PHOTOS({ itemId: item.id, photos: tempArr, deletePhotos: [image] })
      );
    }
  };

  const deleteAllImages = (e) => {
    e.stopPropagation();
    const permission = window.confirm(
      "Are you sure you want to delete all images?"
    );
    if (permission) {
      dispatch(SET_PHOTOS({ itemId: item.id, photos: [] }));
    }
  };

  const makePhotoPrimary = (itemId, image) => {
    dispatch(SET_PRIMARY_PHOTO({ itemId, photo: image }));
  };

  return (
    <div style={{ marginTop: "25px" }}>
      <div className="add_photo_wrapper">
        <div>
          <h6 className="dropdowns_label" htmlFor="sku">
            Photos of the product
          </h6>
        </div>
        <Button onClick={imageSelector}>+ Add more photos</Button>
      </div>
      <div className="photos_wrapper">
        {photos && photos?.length !== 0
          ? photos.map((image) => {
              return (
                <div key={image?.url} style={{ border: "1px solid #0e0e0" }}>
                  <div className="image_wrapper">
                    <img className="image" src={image.url}></img>
                    <button
                      onClick={() => makePhotoPrimary(item?.id, image?.url)}
                      className="primary_button"
                    >
                      <StarIcon
                        fill={
                          item.primaryPhoto === image.url ? "#1e88e5" : "#fff"
                        }
                        stroke={
                          item.primaryPhoto === image.url ? "#1e88e5" : "#000"
                        }
                        style={{
                          width: "25px",
                          height: "17px",
                        }}
                      />
                      Primary
                    </button>
                    <button
                      onClick={(e) => deleteImage(e, image)}
                      className="delete_button"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      {photos?.length > 0 ? (
        <button
          onClick={(e) => deleteAllImages(e)}
          className="delete_all_button"
        >
          <p className="delete_all_button_text">Delete all images</p>
        </button>
      ) : null}
    </div>
  );
};

export default Photos;
