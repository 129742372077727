import React from "react";
import { Helmet } from "react-helmet";

const AccountDetailsPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Helmet>
        <title>1511 Paris | Details</title>
        {/* <link rel="canonical" href="https://1511.paris/profile/address" /> */}
        <meta
          name="keywords"
          content="1511 Paris Account Details, 1511 Paris Personal Information, Saved Items, Profile Overview, account details, personal information, saved items, profile overview, 1511 paris account details, 1511 paris personal information, 1511 paris profile overview, 1511 paris account details page, 1511 paris personal information page, 1511 paris profile overview page"
        />
        <meta
          name="description"
          content="Welcome to your Account Details page! Manage and update your personal information to ensure a seamless and personalized experience. Update your name, email address, password, and other details to keep your account up-to-date. Take control of your account information and enjoy a customized journey tailored to your preferences. Your Account Details page is your gateway to a secure and personalized experience at 1511.paris."
        />
        <meta
          property="og:description"
          content="Welcome to your Account Details page! Manage and update your personal information to ensure a seamless and personalized experience. Update your name, email address, password, and other details to keep your account up-to-date. Take control of your account information and enjoy a customized journey tailored to your preferences. Your Account Details page is your gateway to a secure and personalized experience at 1511.paris."
        ></meta>
      </Helmet>
      <h1>Account Details</h1>
      <h4>Coming soon...</h4>
    </div>
  );
};

export default AccountDetailsPage;
