import React, { useState, useRef, useEffect } from "react";
import "components/Reusable/ProductButton/ProductButton.scss";
import { ReactComponent as Star } from "assets/star.svg";
import { useLocation } from "react-router-dom";
import CustomLoader from "components/Reusable/CustomLoader/CustomLoader";

// LOCAL VALUE USED TO KEEP TRACK OF LOCAL COMPONENT CHANGES
// isNaN USED TO CHECK IF WE ARE DEALING WITH COLORS OR SIZES
// BECAUSE COLORS WILL RETURN NAN AS IT IS A STRING

const ProductButton = ({
  data = null,
  openSelector = null,
  onPress = null,
  value = null,
  image = null,
  mainContainerStyle,
  textStyle,
  navigateToNewProduct,
}) => {
  const [localValue, setLocalValue] = useState(null);
  const myRef = useRef(null);
  const location = useLocation();

  //NAVIGATE TO A DIFFERENT PRODUCT IF COLOR CHANGED
  useEffect(() => {
    if (data) {
      if (isNaN(Number(data[0]))) {
        if (navigateToNewProduct) navigateToNewProduct(localValue);
      }
    }
  }, [localValue]);

  //WHEN LOCATION CHANGE RESET localValue (we are not resetting color as we set it down below)
  //Otherwise color will flicker when we change it which is not good UI experiense
  useEffect(() => {
    if (data?.length > 0) {
      if (isNaN(Number(data[0]))) return;
      else setLocalValue(null);
    }
  }, [location]);

  //SET DEFAULT COLOR IF PASSED IN
  useEffect(() => {
    if (data) {
      if (isNaN(Number(data[0])) && data[0] !== localValue)
        setLocalValue(data[0]);
      else return;
    }
  }, [data]);

  //CLOSE DROPDOWN WHEN CLICK OUTSIDE
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (myRef.current && !myRef.current.contains(e.target)) {
        onPress(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderImage = () => {
    switch (image.toLowerCase()) {
      case "star":
        return (
          <div style={{ position: "absolute", right: 10, marginTop: "3px" }}>
            <Star
              width={15}
              height={15}
              fill={"#fff"}
              stroke="rgba(0, 0, 0, 0.5)"
              className="whatsnew_product_star"
            />
          </div>
        );
      case "filledstar":
        return (
          <div style={{ position: "absolute", right: 10, marginTop: "3px" }}>
            <Star
              width={15}
              height={15}
              fill={"#000"}
              stroke="rgba(0, 0, 0, 0.5)"
              className="whatsnew_product_star"
            />
          </div>
        );
      case "loading":
        return (
          <div style={{ position: "absolute", right: 10, marginTop: "3px" }}>
            <CustomLoader />
          </div>
        );
    }
  };

  return (
    <div
      style={mainContainerStyle}
      onClick={() => {
        //IF DATA IS PASSED IN THEN WE KNOW WE ARE DEALING WITH SIZES OR COLORS AND WE NEED TO OPEN DROPDOWN
        //OTHERWISE WE RUN THE FUNCTION THAT WAS PASSED IN
        if (data) {
          if (!openSelector) onPress(true);
          else onPress(false);
        } else {
          onPress();
        }
      }}
      className="product_details_button_select"
    >
      {localValue && !!onPress ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p>{localValue}</p>
        </div>
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p style={textStyle}>{value}</p>
        </div>
      )}
      {openSelector && data ? (
        <div className="product_details_select" ref={myRef}>
          {data?.map((productSize) => {
            return (
              <div
                key={productSize}
                onClick={(e) => {
                  e.stopPropagation();
                  setLocalValue(productSize);
                  onPress(false, productSize);
                }}
                className="product_details_select_item"
              >
                <p>{productSize}</p>
              </div>
            );
          })}
        </div>
      ) : null}
      {image ? renderImage() : null}
    </div>
  );
};

export default ProductButton;
