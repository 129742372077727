import React from "react";
import "pages/terms/terms.scss";
import { Helmet } from "react-helmet";

const FAQ = () => {
  return (
    <div className="faq_contaienr">
      <Helmet>
        <title>1511 Paris | F.A.Q</title>
        {/* <link rel="canonical" href="https://1511.paris/faq" /> */}
        <meta
          name="keywords"
          content="FAQ, Frequently Asked Questions, Help, Support, Answers, Queries, Inquiries, Solutions, Information, Customer Support, Assistance, Common Questions, Troubleshooting, Guidelines, Knowledgebase, Help Center, Support Center"
        />
        <meta
          name="description"
          content="Our Terms and Conditions outline the legal agreement between you, the user, and our website/company. By accessing or using our website/services, you acknowledge and accept the terms of this agreement. It establishes the rules, obligations, and rights that govern your use of our platform. Please read our Terms and Conditions carefully to understand the conditions of use, your responsibilities, and the limitations of our services. It also includes important information about our Privacy Policy and other relevant policies. By accessing our website or using our services, you consent to abide by these terms and comply with the stated guidelines. In case of any dispute, the Terms and Conditions outline the applicable dispute resolution process and the governing jurisdiction. We encourage you to review our Terms and Conditions regularly to stay informed about any updates or changes. Your continued use of our website indicates your acceptance of the Terms and Conditions."
        />
        <meta
          property="og:description"
          content="Our Terms and Conditions outline the legal agreement between you, the user, and our website/company. By accessing or using our website/services, you acknowledge and accept the terms of this agreement. It establishes the rules, obligations, and rights that govern your use of our platform. Please read our Terms and Conditions carefully to understand the conditions of use, your responsibilities, and the limitations of our services. It also includes important information about our Privacy Policy and other relevant policies. By accessing our website or using our services, you consent to abide by these terms and comply with the stated guidelines. In case of any dispute, the Terms and Conditions outline the applicable dispute resolution process and the governing jurisdiction. We encourage you to review our Terms and Conditions regularly to stay informed about any updates or changes. Your continued use of our website indicates your acceptance of the Terms and Conditions."
        ></meta>
      </Helmet>
      <h1>Frequently Asked Questions</h1>

      <br />
      <h4>1. WHICH COUNTRIES DOES LES SUITES DELIVER TO?</h4>
      <p>
        LES SUITES uses a third-party transporter that delivers worldwide.
        However, please note that we are unable to deliver to forwarding
        addresses, which includes Aramex addresses or to Post Office boxes,
        apart from in the following countries: Bahrain, Jordan, Kuwait, Lebanon,
        Qatar, Saudi Arabia, and the United Arab Emirates.
      </p>

      <br />
      <h4>2. HOW LONG DOES DELIVERY TAKE?</h4>
      <p>
        The time for delivery will be indicated on your order confirmation. Most
        commonly, the delivery delays are approximately:
      </p>
      <ul>
        <li>For France and its border countries: 1-2 business days</li>
        <li>For the rest of Europe: 2-3 business days</li>
        <li>For Africa: 3 business days</li>
        <li>For the Middle East: 4 business days</li>
        <li>For the USA: 2 business days</li>
        <li>For South America: 3 business days</li>
        <li>For Island: 4 business days</li>
      </ul>
      <p>
        These delays are indicative and depend on many variables such as weather
        conditions and custom procedures. However, LES SUITES guarantees
        delivery within 30 days maximum.
      </p>

      <br />
      <h4>3. HOW MUCH DOES DELIVERY COST?</h4>
      <p>
        When you place your order, you can choose in-store pick up or home
        delivery.
      </p>
      <p>
        In-store pick up is free and allows you to pick up your order as soon as
        you receive your order confirmation.
      </p>
      <p>
        Home delivery is FREE for London and Paris. For other cities and
        countries, a flat fee will apply. The costs and conditions will be
        described before your order validation.
      </p>
      <ul>
        <li>Paris - free</li>
        <li>London - free</li>
        <li>France and its border countries - 8 EUR</li>
        <li>For non-bordering countries - 15 EUR</li>
        <li>Rest of the world - 25 EUR</li>
      </ul>

      <br />
      <h4>4. HOW CAN I TRACK MY ORDER?</h4>
      <p>
        When your order is ready to be shipped, LES SUITES will send you an
        email containing a tracking number you can use to follow up on the
        delivery process on the transporter's website.
      </p>

      <br />
      <h4>5. WHAT SHALL I DO IF MY ORDER IS LATE?</h4>
      <p>
        LES SUITES makes every effort to ensure a compliant and timely delivery.
      </p>
      <p>
        If you do not have your order within the time frame stated on your order
        confirmation, please check:
      </p>
      <ul>
        <li>Your package status on the transporter's website</li>
        <li>
          If the name and the delivery address on your order confirmation are
          correct. If your shipping information is incorrect, please contact our
          customer service
        </li>
        <li>
          If you have not received a notice from the transporter asking for
          instructions
        </li>
      </ul>
      <p>
        If none of the above provide you with satisfactory answers, please
        contact our customer service.
      </p>
      <p>
        In the event of late delivery less than 7 days after the time stated on
        the order confirmation, the shipping costs shall be reimbursed.
      </p>
      <p>
        In the event of a delay of more than 7 days, the entire Order shall be
        reimbursed.
      </p>

      <br />
      <h4>6. HOW CAN I CONTACT CUSTOMER SERVICE?</h4>
      <p>You can reach our customer service at:</p>
      <p>47 rue Pierre Charron – 75008 PARIS (France)</p>
      <p>Email: info@boutiquelessuites.com</p>
      <p>Phone: +33 (0)1 56 59 11 11</p>

      <br />
      <h4>7. IS MY PACKAGE INSURED AND DO I NEED TO SIGN FOR MY ORDER?</h4>
      <p>
        All your orders are insured against theft and accidental damage while in
        transit from LES SUITES to your shipping address. Once your package has
        been delivered, it is no longer covered.
      </p>
      <p>
        For some countries, the transporter will require proof of delivery
        implying your signature at the time of delivery. For the USA and UK, no
        signature will be required.
      </p>

      <br />
      <h4>8. HOW AND WHEN CAN I RETURN ITEMS?</h4>
      <p>
        Within a maximum period of 30 calendar days from the date of receipt of
        your order, you can freely return one or several Items delivered in
        compliance with our Terms and Conditions.
      </p>
      <p>
        Whatever the reason for the return, you must go online to our website,
        sign in to your account, and follow the instructions stated in the
        section "Returns".
      </p>
      <p>In the event of return by post:</p>
      <ul>
        <li>
          Use a parcel properly protected in accordance with the postal
          regulations
        </li>
        <li>
          Insert the Items in the parcel in perfect condition (not damaged, not
          used, soiled, or washed) in their original packaging (if any) and with
          their original labels still on
        </li>
        <li>
          Send the parcel using the prepaid label provided at the delivery
        </li>
      </ul>
      <p>In the event of return in the Store:</p>
      <ul>
        <li>
          Remit the Items returned in perfect condition (not harmed, not
          damaged, not used, soiled, or washed) in their original wrapping (if
          any) and with their original labels still on
        </li>
      </ul>
      <p>
        No return shall be accepted if all these obligations are not fulfilled.
      </p>
      <p>No return or retraction can be accepted for "tailor-made" Items.</p>
      <p>The shipping costs are paid by LES SUITES.</p>
      <p>
        The parcel or packaging costs and liability remain incumbent on the
        customer.
      </p>

      <br />
      <h4>9. HOW MUCH TAXES (VAT, CUSTOM TAXES) WILL I HAVE TO PAY?</h4>
      <p>
        Sales are subject to French VAT. The VAT amount will be described before
        your order validation.
      </p>
      <p>
        Custom taxes are paid by the customer upon delivery and directly to the
        transporter and/or the customs services.
      </p>

      <br />
      <h4>10. IS IT POSSIBLE TO EXCHANGE MY ORDER?</h4>
      <p>
        LES SUITES offers the possibility to the customer to exchange the size
        of the item delivered within a maximum period of 30 calendar days from
        the date of receipt of the order.
      </p>
      <p>
        Please go online to our website, sign in to your account, and follow the
        instructions stated in the item "Exchange".
      </p>
      <p>In the event of return by post:</p>
      <ul>
        <li>
          Use a parcel properly protected in accordance with the postal
          regulations
        </li>
        <li>
          Insert the Items in the parcel in perfect condition (not damaged, not
          used, soiled, or washed) in their original packaging (if any) and with
          their original labels still on
        </li>
        <li>
          Send the parcel using the prepaid label provided at the delivery
        </li>
      </ul>
      <p>In the event of return in the Store:</p>
      <ul>
        <li>
          Remit the Items exchanged in perfect condition (not harmed, not
          damaged, not used, soiled, or washed) in their original wrapping (if
          any) and with their original labels still on
        </li>
      </ul>
      <p>
        No exchange shall be accepted if all these obligations are not
        fulfilled.
      </p>
      <p>No exchange can be accepted for "tailor-made" Items.</p>
      <p>
        The shipping costs are paid by LES SUITES by means of the
        above-mentioned prepaid label.
      </p>
      <p>
        The parcel or packaging costs and liability remain incumbent on the
        customer.
      </p>

      <br />
      <h4>
        FOR ADDITIONAL INFORMATION PLEASE READ THE TERMS AND CONDITIONS ON OUR
        WEBSITE.
      </h4>

      <br />
      <h4>CONTACT US</h4>
      <p>Our Parisian boutique is open from 10:00 - 21:00</p>
      <p>47 Rue Pierre Charron 75008 Paris</p>
      <p>
        For all inquiries regarding Les Suites shopping, call us on
        0033156591111 or email us at advisor@1511.paris
      </p>
      <p>Press and media: press@boutiquelessuites.com</p>
      <p>Designers: designer@boutiquelessuites.com</p>
      <p>
        Potential contributors - photographers, fashion journalists, or editors:
        editor@boutiquelessuites.com
      </p>
      <p>Feedback and suggestions: feedback@boutiquelessuites.com</p>
      <p>
        If you are interested in working at LES SUITES or carrying out an
        internship with us: home@boutiquelessuites.com
      </p>
      <p>For all other inquiries: info@boutiquelessuites.com</p>
      <p>Please write in the English language.</p>
    </div>
  );
};

export default FAQ;
