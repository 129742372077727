import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "components/Reusable/Filters/children/PriceFilter.scss";

const PriceFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [hideFilter, setHideFilter] = useState(false);
  const price = searchParams.get("price");
  const price1 = price?.split(",").indexOf("1") > -1 ? true : false;
  const price2 = price?.split(",").indexOf("2") > -1 ? true : false;
  const price3 = price?.split(",").indexOf("3") > -1 ? true : false;

  const allOtherQueris = {};
  searchParams.forEach((value, key) => {
    if (key !== "price") {
      allOtherQueris[key] = value;
    }
  });

  return (
    <div className="price_filter_container">
      <p
        onClick={() => setHideFilter(!hideFilter)}
        className="price_filter_title"
      >
        Price
      </p>
      {!hideFilter && (
        <div style={{ marginTop: "5px" }}>
          <div>
            <input
              checked={price1}
              onChange={() => {
                if (price1 === false) {
                  setSearchParams({
                    ...allOtherQueris,
                    price: price ? price + "," + "1" : "1",
                  });
                } else if (price1) {
                  setSearchParams({
                    ...allOtherQueris,
                    price:
                      price?.split(",").indexOf("1") > -1
                        ? price
                            .split(",")
                            .filter((item) => item !== "1")
                            .join(",")
                        : price?.length < 0
                        ? price
                        : setSearchParams.delete("price"),
                  });
                }
              }}
              type="checkbox"
              id="price1"
            />
            <label style={{ marginLeft: "5px" }} htmlFor="price1">
              Up to 1000€
            </label>
          </div>
          <div>
            <input
              checked={price2}
              onChange={() => {
                if (price2 === false) {
                  setSearchParams({
                    ...allOtherQueris,
                    price: price ? price + "," + "2" : "2",
                  });
                } else {
                  setSearchParams({
                    ...allOtherQueris,
                    price:
                      price?.split(",").indexOf("2") > -1
                        ? price
                            .split(",")
                            .filter((item) => item !== "2")
                            .join(",")
                        : price,
                  });
                }
              }}
              type="checkbox"
              id="price2"
            />
            <label style={{ marginLeft: "5px" }} htmlFor="price2">
              1000€ to 5000€
            </label>
          </div>
          <div>
            <input
              checked={price3}
              onChange={() => {
                if (price3 === false) {
                  setSearchParams({
                    ...allOtherQueris,
                    price: price ? price + "," + "3" : "3",
                  });
                } else {
                  setSearchParams({
                    ...allOtherQueris,
                    price:
                      price?.split(",").indexOf("3") > -1
                        ? price
                            .split(",")
                            .filter((item) => item !== "3")
                            .join(",")
                        : price,
                  });
                }
              }}
              type="checkbox"
              id="price3"
            />
            <label style={{ marginLeft: "5px" }} htmlFor="price3">
              5000€ & more
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;
