import { createSlice } from "@reduxjs/toolkit";
import { blocks, categories } from "pages/blogs/mockData";

const initialState = {
  blocks,
  categories,
};

const BlogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    setBlogs: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setBlogs } = BlogsSlice.actions;
export default BlogsSlice.reducer;
