import React from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import BreadCrumb from "components/Reusable/BreadCrumbs/BreadCrumb";
import "pages/profile/ProfileNav.scss";
import useWindowDimensions from "utils/windowWidth";

const ProfileNav = () => {
  const location = useLocation();
  const pathName = location.pathname.split("/")[1];
  const pathName2 = location.pathname.split("/")[2];
  const pathName3 = location.pathname.split("/")[3];
  const noNavPaths = ["shopping-bag", "order-validation"];

  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BreadCrumb
        category={pathName}
        subCategory={pathName2}
        productId={pathName3} // this is for order details page in profile
        shortDescription={pathName3} // this is for order details page in profile
      />
      {!noNavPaths.includes(pathName2) && width > 768 ? (
        <div className="profile_nav_container">
          <div className="content_wrapper">
            {pathName2 !== "account" ? (
              <nav className="nav_wrapper">
                <ul>
                  <li>
                    <NavLink to="/profile/account">
                      <p>MY ACCOUNT</p>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "",
                        backgroundColor: isActive
                          ? "rgba(232, 240, 254, 0.9)"
                          : "",
                      })}
                      to="/profile/address"
                    >
                      <p>Address book</p>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "",
                        backgroundColor: isActive
                          ? "rgba(232, 240, 254, 0.9)"
                          : "",
                      })}
                      to="/profile/details"
                    >
                      <p>Account details</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "",
                        backgroundColor: isActive
                          ? "rgba(232, 240, 254, 0.9)"
                          : "",
                      })}
                      to="/profile/wishList"
                    >
                      <p>Wish list</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "",
                        backgroundColor: isActive
                          ? "rgba(232, 240, 254, 0.9)"
                          : "",
                      })}
                      to="/profile/orders"
                    >
                      <p>My orders</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      style={({ isActive }) => ({
                        fontWeight: isActive ? "bold" : "",
                        backgroundColor: isActive
                          ? "rgba(232, 240, 254, 0.9)"
                          : "",
                      })}
                      to="/profile/returns"
                    >
                      <p>Returns</p>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : null}
            <Outlet />
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default ProfileNav;
