import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_ITEM_TAB,
  DELETE_ITEM_TAB,
  TAB_CHANGE,
  RESET_ACTIVE_TAB,
} from "store/slices/NewProductItemSlice";

import Size from "./Size";
import ColorOthersSwitch from "./ColorOthersSwitch";
import Descriptions from "./Descriptions";
import Photos from "./Photos";
import Discount from "./Discount";
import Perfume from "./Perfume";

const Product = ({ edit }) => {
  const product = useSelector((state) => state.newProductItem.products);
  const productCategory = useSelector((state) => state.newProduct.category);
  const dispatch = useDispatch();

  const addProductTab = () => {
    dispatch(ADD_ITEM_TAB());
  };

  const deleteProductTab = (e, tab) => {
    e.stopPropagation();
    dispatch(DELETE_ITEM_TAB(tab));
  };

  const changeActiveTab = (tab) => {
    dispatch(TAB_CHANGE(tab));
  };

  useEffect(() => {
    if (product?.length === 1 && product[0]?.activeTab === false) {
      dispatch(RESET_ACTIVE_TAB());
    }
  }, [product]);

  return (
    <div style={{ marginTop: "35px" }}>
      {/* TAB BAR */}
      <div>
        <div className="tab_bar_container">
          {product?.map((item) => (
            <div
              key={item?.id}
              onClick={() => changeActiveTab(item)}
              className="tab_bar_item"
              style={{
                color: item?.activeTab ? "#fff" : "#000",
                backgroundColor: item?.activeTab
                  ? "#1e88e5"
                  : "rgba(0, 0, 0, 0.04)",
              }}
            >
              <div>{item?.title || "New product"}</div>
              {item?.activeTab && product?.length > 1 ? (
                <div
                  key={item.id}
                  onClick={(e) => deleteProductTab(e, item)}
                  className="tab_bar_item_delete"
                >
                  <DeleteIcon style={{ color: "red" }} />
                </div>
              ) : null}
            </div>
          ))}

          <div style={{ margin: "0 15px", cursor: "pointer" }}>
            <AddIcon
              style={{ color: edit ? "#d5d5d5" : "#1e88e5" }}
              onClick={edit ? null : addProductTab}
            />
          </div>
        </div>
      </div>
      {/* CONTENT AREA */}
      <Box sx={{ boxShadow: 2, display: "flex" }}>
        {product?.map((item) => {
          if (item?.activeTab) {
            return (
              <div key={item?.id} className="content_area_container">
                <div className="content_area_left_wrapper">
                  {/* DESCRIPTIONS */}
                  <Descriptions item={item} />
                  {/* SIZE */}
                  <Size item={item} />
                  {/* DEVIDER LINE */}
                  <div className="devider" />
                  {/* COLOR AND OTHER VALUES*/}
                  <ColorOthersSwitch item={item} />
                  {/* IF PERFUME CATEGORY */}
                  {productCategory.toLowerCase() === "perfumes" ? (
                    <Perfume item={item} />
                  ) : null}

                  <div className="devider" />
                  {/* PHOTOS */}
                  <Photos item={item} />
                </div>
                <div>
                  <Discount item={item} />
                </div>
              </div>
            );
          }
        })}
      </Box>
    </div>
  );
};

export default Product;
