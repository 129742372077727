import React from "react";
import "components/Product/Product.scss";
import { NavLink, useParams } from "react-router-dom";
import { useGetProductQuery } from "api/products";
import ImageSection from "components/Product/imageSection";
import Description from "components/Product/descriptionSection";
import Suggestions from "components/Product/Suggestions/Suggestions";

const Product = () => {
  const { category, subCategory, product_id } = useParams();
  const { data: productData, isLoading } = useGetProductQuery({ product_id });
  const product = productData?.product;

  return (
    <div className="product_container">
      <div className="product_top_wrapper">
        {/* IMAGE GROUP WRAPPER */}
        <ImageSection />
        {/* DESCRIPTION WRAPPER */}
        <Description />
      </div>
      {/* YOU MAY ALSO LIKE */}
      <Suggestions category={product?.category} productId={product?.id} />
    </div>
  );
};

export default Product;
