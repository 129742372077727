import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/admin/users`,
      providesTags: ["Users"],
    }),
    makeOrTakeAdmin: builder.mutation({
      query: (data) => ({
        url: `/admin/users/makeortakeadmin`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUsers: builder.mutation({
      query: (data) => ({
        url: `/admin/users/delete`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useMakeOrTakeAdminMutation,
  useDeleteUsersMutation,
} = usersApi;
