import React from "react";
import "pages/terms/terms.scss";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const TermsOfService = () => {
  return (
    <div className="terms_container">
      <Helmet>
        <title>1511 Paris | Terms and Conditions</title>
        {/* <link rel="canonical" href="https://1511.paris/terms-of-service" /> */}
        <meta
          name="keywords"
          content="Terms and Conditions, Policies, Legal, Agreement, User Agreement, Terms of Service, Terms of Use, Privacy Policy, Disclaimer, Consent, Obligations, Rights, Responsibilities, Acceptance, Conditions, Rules, Guidelines, Governing, Restrictions, Compliance, Binding, Limitations, Dispute Resolution, Jurisdiction"
        />
        <meta
          name="description"
          content="Welcome to our FAQ page, where you'll find answers to the most commonly asked questions about our products, services, and policies. We have compiled a comprehensive list of frequently asked questions to provide you with the information you need. Whether you have inquiries about ordering, shipping, returns, or any other aspect of our business, our FAQ page is designed to address your concerns and provide solutions. Browse through the various categories to find quick and helpful answers to common queries. If you can't find what you're looking for, our customer support team is always ready to assist you. Our FAQ page serves as a knowledgebase and a valuable resource to ensure a seamless experience for our customers."
        />
        <meta
          property="og:description"
          content="Welcome to our FAQ page, where you'll find answers to the most commonly asked questions about our products, services, and policies. We have compiled a comprehensive list of frequently asked questions to provide you with the information you need. Whether you have inquiries about ordering, shipping, returns, or any other aspect of our business, our FAQ page is designed to address your concerns and provide solutions. Browse through the various categories to find quick and helpful answers to common queries. If you can't find what you're looking for, our customer support team is always ready to assist you. Our FAQ page serves as a knowledgebase and a valuable resource to ensure a seamless experience for our customers."
        ></meta>
      </Helmet>
      <h1 className="terms_header">
        TERMS AND CONDITIONS OF USE and SALE ON THE WEBSITE <br />
        <Link target="_blank" to="/">
          www.1511.paris
        </Link>
      </h1>
      <p>
        The present general terms and conditions of use and sale (hereinafter
        “T&amp;C”) apply exclusively between any Customer and the LES SUITES
        company (hereinafter called “LES SUITES”).
      </p>
      <p>
        The T&amp;C are applicable without restriction or reservation to any use
        of the Site and to all the Items offered for sale on the Site.
      </p>
      <p>
        Every Order placed on the website www.1511.paris is subject beforehand
        to acknowledgement and acceptance of these T&amp;C.
      </p>
      <p>
        By ticking the box “I have read and accept the T&amp;C”, accessible on
        the page dedicated to the validation of the Order, the Customer declares
        that he accepts to be bound by all provisions of the T&amp;C from that
        time.
      </p>
      <p>
        In any case, any use of the Site by the Customer entails acceptance
        without reservation of the T&amp;C in effect at the time of this use.
      </p>
      <p>
        The Customer is abled at any time to print, download and keep a copy on
        a paper or electronic medium of the T&amp;C from the Site www.1511.paris
        by clicking on the tab “T&amp;C”.
      </p>
      <p>The present T&amp;C prevail over any other document.</p>
      <br />
      <br />
      <h4>1. STATUTORY INFORMATION</h4>
      <p>
        Publisher of the Site:
        <br /> LES SUITES S.A.S., whose registered office is located at 47 Rue
        Pierre Charron – 75008 PARIS, which is registered in the Paris Corporate
        Register under number 752 947 713.
      </p>
      <br />
      <p>
        Publishing director of the Site:
        <br /> Mrs Eka IUKURIDZE, Chairman of LES SUITES S.A.S., 47 Rue Pierre
        Charron – 75008 PARIS
      </p>
      <p>
        E-mail: info@boutiquelessuites.com Phone: +33 1 56 59 11 11 (cost of a
        local call from a land line).
      </p>
      <br />
      <p>
        Customer Service:
        <br /> 47 Rue Pierre Charron – 75008 PARIS
      </p>
      <p>
        E-mail: info@boutiquelessuites.com Phone: +33156591111 (cost of a local
        call from a land line)
      </p>
      <br />
      <p>
        Hosting company of the Site:
        <br />
        Amazon Web Services, Inc. 410 Terry Avenue North, Seattle, WA
        98109-5210, U.S.A.
      </p>
      <br />
      <h4>2. DEFINITIONS</h4>
      <p>
        The following words or expressions beginning with a capital letter shall
        herein have the following meaning:
      </p>
      <ul>
        <li>Item: Product offered for sale by LES SUITES on the Site</li>
        <li>
          Store: the retail store LES SUITES located at 47 rue Pierre Charron-
          75008 PARIS, which opening times are shown on the Site.
        </li>
        <li>
          Customer: an adult individual legally capable person (or minor
          represented by his legal representative) who is not a merchant making
          an Order on the Site
        </li>
        <li>
          Customer Service: Service provided to the Customer by LES SUITES,
          accessible from Monday to Friday (except bank holidays in France) from
          10.00 a.m. to 6.00 p.m. (Paris time)
        </li>
        <li>
          Order: agreement of the sale contract of one or several Items made
          between LES SUITES and the Customer on the Site according to the
          procedure described on the Site
        </li>
        <li>
          Site: website published by LES SUITES to present and sell Items to
          Customers
        </li>
      </ul>
      <br />
      <h4>3. PURPOSE</h4>
      <p>
        The purpose of the T&amp;C is to define the conditions applicable to any
        use of the Site and to all sales made on the Site.
      </p>
      <p>
        The Customer acknowledges having read, prior to any use of the Site or
        placing an Order, the T&amp;C and declares expressly that he accepts
        them without reservation.
      </p>
      <br />
      <h4>4. UPDATES OF THE T&amp;C</h4>
      <p>
        LES SUITES may update or modify these T&amp;C at any time without prior
        notice. The T&amp;C applicable to any Order are those available on the
        Site at the time of placing the Order.
      </p>
      <br />
      <h4>5. REGISTRATION</h4>
      <p>
        In order to place an Order, the Customer must be registered on the Site.
        Registration is free of charge.
      </p>
      <p>
        The Customer guarantees the accuracy and completeness of the information
        provided during the registration process. The Customer is responsible
        for updating his personal information in the “My Account” section.
      </p>
      <p>
        LES SUITES reserves the right to suspend or cancel any Order and/or
        delivery, whatever their nature and state of execution, in the event of
        non-compliance by the Customer with any obligation arising from these
        T&amp;C or in the event of any payment incident.
      </p>
      <br />
      <h4>6. CUSTOMER ACCOUNT</h4>
      <p>
        Every Customer of LES SUITES must create a Customer Account allowing him
        to identify himself before each Order.
      </p>
      <p>
        The creation of a Customer Account is made by filling in the form
        proposed under the heading "Create an account” of the Site (hereinafter
        “the Customer Account”).
      </p>
      <p>A Customer Account is strictly individual and personal.</p>
      <p>
        At the time of the creation of the Customer Account, the Customer
        provides data allowing his identification under his full responsibility.
        The Customer expressly undertakes that this information is complete and
        accurate and does not infringe the rights of third parties and, in
        particular, does not usurp the identity of a third party.
      </p>
      <p>
        At the time of the creation of a Customer Account, the Customer chooses
        his identifier (e-mail) and his password.
      </p>
      <p>
        If the identifier chosen has already been attributed, the Site will asks
        him to choose another one.
      </p>
      <p>
        The identifiers and passwords are personal and confidential. Even though
        LES SUITES takes all the precautions necessary and undertakes to protect
        this data, the Customer is informed of the necessity to keep them secret
        and undisclosed to any third party for any reason whatsoever.
      </p>
      <p>
        In the event of suspicion about the use of the identifier and password
        by a third party, the Customer must immediately notify LES SUITES and
        change his password on the Site and/or request on the Site or by e-mail
        sent to the Service Customer the closing of his Customer Account.
      </p>
      <p>
        Upon receipt of a request for closure of the Customer Account, LES
        SUITES will send a letter to the Customer to the postal address provided
        by him in his Customer Account informing him of the deactivation of his
        identifier and of his password and of the closure of his Customer
        Account.
      </p>
      <p>
        LES SUITES holds the possibility of closing any Customer Account without
        notice and of refusing consequently any sale to any Customer breaching
        the T&amp;C in anyway.
      </p>

      <br />
      <h4>7. ITEMS</h4>
      <p>On the Site LES SUITES offers 4 different categories of items:</p>
      <ul>
        <li>Regular retail items</li>
        <li>Made to order items</li>
        <li>Made to measure items</li>
        <li>Pre-order items</li>
      </ul>
      <p>
        A regular retail item is a ready confectioned item ordered by LES SUITES
        to its original designer/brand for retail in its Store and/or on the
        Site (hereinafter "Regular Retail Item").
      </p>
      <p>
        A Made to order item is an item ordered by LES SUITES to its original
        designer/brand upon a Customer's request (hereinafter "Made to Order").
      </p>
      <p>
        A Made to measure item is an item made to the client's individual size
        and taste by its original designer/brand and delivered to LES SUITES
        upon a Customer's custom order. A made to Measure order is established
        based on an appointment with the designer set up by LES SUITES
        (hereinafter "Made to Measure").
      </p>
      <p>
        Pre-order items are a selection of items the Customers can, within a
        limited timeframe, order on the Site before their official production
        and retail launching (hereinafter "Pre-Orders").
      </p>
      <p>
        All items available for sale are those that are shown on the Site. The
        offers are valid for as long as they are visible on the Site.
      </p>
      <p>
        They are proposed within the limit of the availability shown on the
        Site.
      </p>
      <p>
        LES SUITES holds the right to withdraw from sale at any time any Item
        shown on the Site and/or to replace or modify any information relating
        to the Items shown on this Site.
      </p>
      <p>
        The characteristics of the Items on the Site (photographs,
        representations, descriptions, etc.) are given indicatively and can vary
        over time.
      </p>
      <p>
        Only the characteristics of the Item displayed at the time of validation
        of the Order are valid.
      </p>

      <br />
      <h4>8. ORDER PROCESS</h4>
      <p>
        To place an Order, the Customer must follow the online purchasing
        procedure described on the Site. He must, in particular, verify the
        Items shown in his basket and his chosen delivery method, click on
        “Place order” to validate and place the Order and proceed to the payment
        according to the conditions stipulated in article 10 of the T&amp;C
        (hereinafter “the Order”).
      </p>
      <p>
        Every Order placed commits the Customer to full payment for the Order.
      </p>
      <p>
        Every Order is subject to the acceptance of LES SUITES and is only
        definitively confirmed after receipt by the Customer of an e-mail
        confirming the registration of the Order (hereinafter “the
        Confirmation”).
      </p>
      <p>
        The Confirmation shall include a reminder of the delivery method chosen
        and an Order number.
      </p>
      <p>
        It is recommended to the Customer to print the Confirmation of his
        Order.
      </p>
      <p>
        If the Customer does not receive any Confirmation for 14 days following
        his Order, he should contact the Customer Service.
      </p>
      <p>
        LES SUITES can in no event be held liable if the Customer has provided
        inaccurate information preventing the issuing of the Confirmation.
      </p>
      <p>
        The Customer is informed that any Order made in breach of the T&amp;C
        can be canceled by LES SUITES.
      </p>

      <br />
      <h4>9. PRICE OF THE ITEMS and SHIPPING COSTS</h4>
      <p>
        The prices of the Items are those that are shown on the Site at the time
        of the validation of each Order by the Customer.
      </p>
      <p>
        The prices of the Items are shown on the Site in Euros, all sales taxes
        included, excluding shipping costs.
      </p>
      <p>
        They include any potential reductions/rebates applicable on the day of
        the Order.
      </p>
      <p>
        They include the VAT at the rate applicable on the date, except if the
        Customer is invoiced in a country where VAT is not applicable.
      </p>
      <p>
        They also include any new taxes or contributions, notably environmental,
        likely to apply to the Items.
      </p>
      <p>
        The price of shipping costs is provided to the Customer on the Site
        before the validation of the Order.
      </p>
      <p>
        These vary according to the delivery destination and the method of
        delivery chosen by the Customer.
      </p>
      <p>
        The total price including the price of the Items and the shipping costs
        is recalled in the Confirmation.
      </p>
      <br />
      <h4>10. PAYMENT OF THE ORDER and INVOICING</h4>
      <p>
        The Order is payable on the Site at the time of the Order by means of
        the bank cards and/or payment platforms proposed on the Site.
      </p>
      <p>
        An invoice corresponding to the Order paid shall be sent to the Customer
        in a paper format at the time of the delivery of the Order and shall
        also be accessible and printable from the Site in the Customer Account
        of the Customer.
      </p>

      <br />
      <h4>11. DELIVERY</h4>
      <p>
        The Items for sale on the Site are available on delivery in all the
        countries mentioned and according to the conditions and delivery prices
        announced under the heading “Delivery” of the Site.
      </p>
      <p>
        The delivery method and times are chosen by the Customer on the Site at
        the time of the Order.
      </p>
      <p>
        The Customer is informed that LES SUITES does not itself operate the
        delivery of the Orders but uses outside shipment companies responsible
        for the delivery of the Orders (hereinafter “the Shipper”).
      </p>
      <p>
        When the Shipper ships the Order, LES SUITES informs the Customer
        thereof by e-mail to the e-mail address provided in the Customer
        Account.
      </p>

      <br />
      <h4>11.1 Methods of delivery:</h4>
      <p>
        The methods of delivery offered to the Customer are home delivery or
        Store pick-up.
      </p>
      <p>In the event where the Customer chooses home delivery:</p>
      <ul>
        <li>
          The Customer is informed that he is responsible for providing all
          accurate details necessary to ensure the successful delivery of his
          Order (access code, and access specifications, for example). LES
          SUITES and/or its providers can in no event be held liable in the
          event the Customer has provided inaccurate information preventing the
          Shipper from making the delivery.
        </li>
        <li>
          The parcel delivered shall contain a delivery receipt showing all
          Items ordered and delivered, a return form, a prepaid return label as
          well as an invoice corresponding to the Order.
        </li>
      </ul>
      <p>
        If the Customer is absent at the time of the delivery, the Shipper will
        send him a notice informing him of the modalities of pick-up or
        re-delivery of his parcel, thus under the full and exclusive
        responsibility of the Shipper.
      </p>
      <p>
        In the event the delivery should be impossible following an entry error
        of the Customer at the time of his Order (incomplete or mistaken
        address, etc.), the parcel shall be returned to LES SUITES by the
        Shipper. LES SUITES shall inform the Customer by e-mail to the address
        stated in his Customer Account.
      </p>
      <p>
        The Customer shall then have the choice of completing the missing
        information and of requesting the re-delivery of his Order at the price
        and within the times of a new delivery or of canceling the Order and
        obtaining the reimbursement thereof. The costs of the failed delivery
        shall remain incumbent on the Customer whatever the case.
      </p>
      <p>In the event the Customer has chosen Store pick-up:</p>
      <ul>
        <li>
          The Customer is informed by e-mail that his Order is available to
          pick-up in the Store.
        </li>
        <li>
          To withdraw his Order the Customer must come during Store opening
          hours and provide an identity document as well as the e-mail stated
          above.
        </li>
      </ul>

      <br />
      <h4>11.2 Delivery times</h4>

      <br />
      <h4>11.2.1 For Regular Retail items:</h4>
      <p>
        The Items are delivered or made available in the Store before the
        expiration of the time chosen in the Order and shown in the
        Confirmation, which cannot exceed 30 days from the date of the
        Confirmation.
      </p>
      <p>
        In the event of late delivery or making available less than 7 days after
        the time shown in the Confirmation, the shipping costs shall be
        reimbursed to the Customer by LES SUITES.
      </p>
      <p>
        In the event of a delay of more than 7 days, the entire Order shall be
        reimbursed to the Customer by LES SUITES.
      </p>
      <p>
        Any reimbursement to the Customer shall be made within 14 days following
        the expiration of the above-mentioned times.
      </p>

      <br />
      <h4>
        11.2.2 For Pre-Order items, Made to Order items and Made to Measure
        items:
      </h4>
      <p>
        The time for fabrication and delivery will vary depending on each
        designer and/or brand's fabrication process and delays, which LES SUITES
        is not liable for.
      </p>
      <p>
        Before completion of the Order for such items, LES SUITES will indicate
        to the Customer an expectable time frame for delivery, which is based on
        LES SUITES experience but is purely indicative and cannot be held
        against LES SUITES in any case.
      </p>

      <br />
      <h4>
        11.2.3 All deliveries are deemed to be complete at the time of the
        remittance of the Order to the Customer by the Shipper as proven by the
        control system used by the Shipper.
      </h4>

      <br />
      <h4>12. RETURNS</h4>
      <p>Returns are only accepted:</p>
      <ul>
        <li>
          For items returned in the condition they were received (no alteration
          or damage, not used, soiled or washed) in their original wrapping and
          with their original labels still on;
        </li>
        <li>Within the time frames stated hereunder;</li>
        <li>Upon completion of the following procedure:</li>
      </ul>
      <p>For all returns the Customer shall:</p>
      <ul>
        <li>Go on the Site and enter his Customer Account</li>
        <li>
          Click on the Order, select the item(s) to be returned and press
          "Return"
        </li>
      </ul>
      <p>
        LES SUITES will send an email to the Customer confirming the reception
        of the return request.
      </p>
      <p>
        Within 5 days of this confirmation, the Shipper will contact the
        Customer by email to agree on the pick-up date in time.
      </p>

      <br />
      <h4>12.1 Convenience returns</h4>
      <p>
        Regular retail items and pre-order items can be returned by the Customer
        without having to provide any justification within a maximum period of
        30 calendar days from the date of receipt of his Order under the
        conditions stated herein.
      </p>
      <p>
        Made to Order and/or Made to Measure items cannot be returned if not
        defective.
      </p>

      <br />
      <h4>12.2 Defective items</h4>
      <p>
        If the Item delivered is defective or does not correspond to Article
        stated in the Order Confirmation, the Customer can also return the Item
        in accordance with the statutory warranty of conformity under L.217-4
        and following provisions of the French Consumer Code within a period of
        2 years from the date of receipt of his Order while complying with the
        modalities of return stipulated herein.
      </p>
      <br />
      <h4>13. REIMBURSEMENT</h4>
      <p>
        Upon reception of the Items returned in compliance with the
        above-mentioned instructions, LES SUITES shall reimburse the Customer
        the full price of the Items and shipping costs paid by the Customer for
        the Order at the latest within a period of 7 calendar days.
      </p>
      <p>
        The reimbursement shall be made to the bank account or the payment
        account of the Customer used at the time of the Order.
      </p>

      <br />
      <h4>14. POSSIBILITY OF EXCHANGE IN THE STORE</h4>
      <p>
        LES SUITES also offers to the Customer the possibility to exchange in
        the Store any purchased Regular retail item, Pre-order item, or Made to
        Order item. No Made to Measure Item can be exchanged.
      </p>
      <p>
        To be accepted, this exchange must be made in strict compliance with the
        following conditions:
      </p>
      <ul>
        <li>
          The exchange must be done with a maximum period of 30 calendar days
          from the delivery of the Order.
        </li>
        <li>
          The returned item must be in the condition it was received (no
          alteration or damage, not used, soiled or washed) in its original
          wrapping and with its original labels still on.
        </li>
      </ul>
      <p>
        The exchange shall be made on the basis of the price of the Item as paid
        at the time of the Order.
      </p>

      <br />
      <h4>15. PERSONAL DATA</h4>
      <p>
        LES SUITES implements personal data processing in accordance with the
        applicable legal provisions and in particular the EU Regulation 2016/679
        known as GDPR.
      </p>
      <p>
        The management of your personal data as well as all of your rights
        related thereto are detailed in our Privacy Policy (insert here an
        active link to this text).
      </p>

      <br />
      <h4>16. COOKIES</h4>
      <p>
        The Site uses cookies to ensure relevance and efficiency of the Site and
        LES SUITES's services and to provide Customers with personalized
        services and offers. The use of these cookies and all of your rights are
        detailed in our Privacy Policy (insert here an active link to this
        text).
      </p>

      <br />
      <h4>17. LINKS TO OTHER WEBSITES OR APPLICATIONS</h4>
      <p>
        The Site may optionally contain hypertext links to websites or
        third-party applications.
      </p>
      <p>
        LES SUITES is not responsible for the operation and content of these
        third-party sites or applications with which it claims no legal link.
      </p>
      <p>
        The Customer is informed that LES SUITES is not responsible for the
        consequences to redirect these sites or applications.
      </p>

      <br />
      <h4>18. MARKETING and BUSINESS DEVELOPMENT COMMUNICATIONS</h4>
      <p>
        LES SUITES and/or his commercial partners are likely to send the
        Customers marketing and business development communications in any form
        and by any means if the Customer has checked the box "I agree to receive
        newsletters and commercial offers related to LES SUITES".
      </p>
      <p>
        The Customer can at any time oppose these mailings and offers by
        clicking on the link "Unsubscribe" present on each correspondence
        related thereto or upon request in the Site under the tab "My Account".
      </p>

      <br />
      <h4>19. INTELLECTUAL PROPERTY</h4>
      <p>
        LES SUITES is the exclusive holder of all the intellectual property
        rights attached to:
      </p>
      <ul>
        <li>The Store and, in particular, its trade name and trademark</li>
        <li>
          The Site and, in particular, its structure, arborescence,
          organization, possible software, headings, functionalities, its visual
          and graphic appearance, its texts, moving or fixed images, sounds,
          know-how, designs, and any other element of the Site
        </li>
        <li>
          The database(s) designed and managed by LES SUITES, their structures
          and their contents
        </li>
        <li>
          The name, signs, logos, colors, graphics or other signs that may be
          used, made or applied by LES SUITES.
        </li>
      </ul>
      <p>
        Accordingly, it is forbidden to represent, reproduce, and/or modify in
        any form whatsoever, directly or indirectly, all or any of the
        above-mentioned elements and more generally, to use or exploit these
        elements otherwise than in the framework of the execution of the
        T&amp;C.
      </p>
      <p>
        In this regard, the reproduction or the use of these elements is only
        authorized for the purposes of information for personal and private use
        exclusively, with any reproduction and any use of copies made for other
        purposes being expressly forbidden.
      </p>
      <p>
        Any other use, except with the prior, written permission of LES SUITES,
        constitutes wrongdoing that can be the subject of proceedings.
      </p>

      <br />
      <h4>20. WARRANTIES</h4>
      <p>
        The Items are guaranteed against the defects of conformity and
        redhibitory defects under the conditions stipulated under provisions
        1641 to 1649 of the French Civil Code and L.211-1 and following of the
        French Consumer Code.
      </p>
      <p>
        The Customer can contact LES SUITES to receive any information
        concerning the application of these warranties.
      </p>
      <p>
        In accordance with provision 24 hereof, the Customer shall contact LES
        SUITES in priority to seek an amicable solution when the Customer
        intends to invoke one of the above-mentioned warranties.
      </p>
      <p>
        The Customer undertakes only to use the Site for his own needs and for
        the sole purposes stipulated in the T&amp;C.
      </p>
      <p>
        The Customer undertakes not to hamper or disturb the Site or to use it
        for any illegal activity whatsoever.
      </p>
      <p>
        Any illegal use or use that generally does not comply with the T&amp;C
        of the Site shall entail the immediate suppression of the Customer
        Account of the Customer, without prejudice for LES SUITES to any
        possibility of legal action and claim for damages.
      </p>
      <p>
        The Customer expressly guarantees LES SUITES against any damage capable
        of being caused to it because of his use of the Site, including legal
        representation and procedural costs and undertakes in this regard to
        take part in any legal proceedings brought against it because of his use
        of the Site.
      </p>

      <br />
      <h4>21. LIABILITY</h4>
      <p>
        LES SUITES undertakes to describe the Items sold on the Site with the
        greatest accuracy and to ensure under the best conditions possible the
        updating of the information that is provided therein.
      </p>
      <p>
        However, LES SUITES cannot guarantee the exhaustiveness of the
        information shown on the Site.
      </p>
      <p>
        In the event of non-substantial differences between the visuals and
        characteristics shown on the Site and the Items delivered, LES SUITES
        shall not be held liable.
      </p>
      <p>
        In the framework of the execution of the T&amp;C, LES SUITES or any of
        its hosting companies and technology providers can never be held liable
        for any damage whatsoever resulting:
      </p>
      <ul>
        <li>from use of the Site non-compliant with the T&amp;C</li>
        <li>or, more generally, from any fault whatsoever of the Customer.</li>
      </ul>
      <p>
        Any improper use of the Site, fault, negligence, omission or failure on
        his part, any disclosure or illegal use of the password or codes of the
        Customer, as well as the filling-in of inaccurate information or the
        absence of updating of such information shall be considered to be a
        fault of the Customer opposable to the latter. The use of any technical
        process, such as robots, or automatic requests, the application of which
        would infringe the letter or the spirit of the T&amp;C shall also be
        considered as a fault of the Customer.
      </p>
      <p>
        Furthermore, LES SUITES or, as the case may be, its hosting companies
        and technology providers, cannot be held liable in the event of damage
        resulting:
      </p>
      <ul>
        <li>
          from temporary or total unavailability of all or part of the access to
          the Site, from a difficulty relating to the response time and,
          generally, from a defect in performance
        </li>
        <li>from an Event of Force Majeure as defined herein</li>
      </ul>
      <p>
        The Customer declares that he has been informed of the fragility of the
        Internet, especially in terms of the propagation of viruses, for which
        LES SUITES, its hosting companies and technology providers cannot be
        held liable.
      </p>
      <p>
        The Customer is responsible for applying to his personal devices a
        solution and security measures capable of preventing the propagation of
        viruses.
      </p>
      <br />
      <h4>22. FORCE MAJEURE</h4>
      <p>
        LES SUITES cannot be held liable in the event of a breach of one of its
        contractual obligations resulting from an accidental event or an event
        of force majeure, as defined by the jurisprudence established by French
        Courts. In particular, LES SUITES shall not be held liable for any
        non-execution or delay in the execution of an Order caused by events
        outside of its control ("Event of Force Majeure").
      </p>
      <p>
        An Event of Force Majeure includes any act, event, non-performance,
        omission, or accident beyond the control of LES SUITES and includes, in
        particular (without restriction):
      </p>
      <ul>
        <li>Strikes, closures, or other industrial actions.</li>
        <li>
          Civil disturbance, riot, invasion, terrorist attack or threat of
          terrorist attack, war (declared or not) or threat of or preparation
          for war.
        </li>
        <li>
          Fire, explosion, storm, flooding, earthquake, subsidence, epidemic, or
          other natural disasters.
        </li>
        <li>
          Failure or accidents of maritime postal transport by rail, ship,
          aircraft, or other private or public means of transport.
        </li>
        <li>
          Impossibility of using the networks of public or private
          telecommunications.
        </li>
        <li>
          Acts, decrees, legislation, regulations, or restrictions of any
          government.
        </li>
      </ul>
      <p>
        The execution of the T&amp;C shall be suspended for as long as the Event
        of Force Majeure shall last, and the performance and delivery times
        shall be extended by the same duration. LES SUITES shall try as far as
        possible to terminate the Event of Force Majeure or to find a solution
        allowing it to fulfill its contractual obligations despite the Event of
        Force Majeure.
      </p>

      <br />
      <h4>23. MEANS OF PROOF</h4>
      <p>
        The Customer acknowledges and accepts that the proof of their acceptance
        of the T&amp;C (and their eventual updates) is established by ticking
        the box "I have read and I accept the T&amp;C", accessible on the page
        dedicated to the validation of the Order and, more generally, by any use
        of the Site.
      </p>
      <p>
        The Customer acknowledges and accepts that computerized data stored on
        the computer servers of LES SUITES under reasonable conditions of
        security and integrity are considered, irrefutably, as proof of the
        acceptance of the terms of the T&amp;C and proof of all transactions
        concluded between LES SUITES and the Customer.
      </p>
      <p>
        Accordingly, except in the event of a clear mistake of LES SUITES proved
        by the Customer, the latter cannot contest the admissibility, validity,
        or binding force of the T&amp;C and the content of the Order based on
        any statutory provision that may specify that certain documents must be
        written or signed to constitute proof.
      </p>
      <p>
        Thus, these elements constitute proof and, if they are produced as means
        of proof by LES SUITES in any legal or other proceedings, shall be
        admissible, valid, and enforceable in the same way under the same
        conditions and with the same binding force as any other document that
        may be drawn up, received, or stored in writing.
      </p>

      <br />
      <h4>24. MODIFICATION OF THE T&amp;C</h4>
      <p>
        Given the possible changes of the Site and regulations, LES SUITES
        reserves the right to modify the T&amp;C at any time.
      </p>
      <p>
        If necessary, the new T&amp;C shall be brought to the attention of the
        Customer by modifying the dedicated page of the Site. The Customer
        expressly consents to the new version of the T&amp;C by validating any
        new Order.
      </p>

      <br />
      <h4>25. MEDIATION</h4>
      <p>
        In the event of a dispute about the execution of the T&amp;C, the
        Customer must contact the Customer Service of LES SUITES in writing in
        the first instance.
      </p>
      <p>
        If the request for a claim with the Customer Service fails or in the
        absence of a reply from this Service within a period of 2 calendar
        months from the receipt of the request, the Customer can submit the
        dispute concerning the Order or these T&amp;C with LES SUITES to a
        mediator who will try, with complete independence and impartiality, to
        bring the parties together with a view of reaching an amicable solution.
      </p>
      <p>
        The mediator appointed by LES SUITES. The contact details of the
        mediator appointed by LES SUITES are available upon request to the
        Customer Service.
      </p>
      <p>The mediation proceedings are free of charge for the Customer.</p>
      <p>
        The parties are free to accept or refuse the use of mediation as well
        as, in the event of use of mediation, to accept or refuse the solution
        proposed by the mediator.
      </p>

      <br />
      <h4>26. GOVERNING LAW – COMPETENT COURT</h4>
      <p>The T&amp;C are governed by French law.</p>
      <p>
        Any dispute shall lie within the jurisdiction of the Court of the place
        of domicile of the Customer in the absence of amicable agreement between
        the Customer and LES SUITES.
      </p>
    </div>
  );
};

export default TermsOfService;
