import { useEffect, useState } from "react";
import { useGetWishlistQuery } from "api/wishlist";

export function useGetActiveWishList() {
  const { data, isLoading, error } = useGetWishlistQuery();
  const [wishList, setWishList] = useState([]);

  useEffect(() => {
    if (data) {
      setWishList(data);
    }
  }, [data]);

  return { wishList, setWishList, data, isLoading, error };
}
