import React from "react";
import {
  ADD_SHOE_SIZE,
  UPDATE_SHOE_SIZE,
  DELETE_SHOE_SIZE,
} from "store/slices/NewProductItemSlice";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useDispatch } from "react-redux";

const ShoeSize = ({ item }) => {
  const dispatch = useDispatch();
  const addItemSize = (itemId) => {
    let obj = {};
    obj.itemId = itemId;
    dispatch(ADD_SHOE_SIZE(obj));
  };

  const updateItemSize = (itemId, sizeId, name, val) => {
    let obj = {};
    obj.itemId = itemId;
    obj.sizeId = sizeId;
    obj[name] = val;
    dispatch(UPDATE_SHOE_SIZE(obj));
  };

  const removeItemSize = (itemId, sizeId) => {
    let obj = {};
    obj.itemId = itemId;
    obj.sizeId = sizeId;
    dispatch(DELETE_SHOE_SIZE(obj));
  };
  return (
    <div className="shoe_size_container">
      <div
        style={{
          display: "flex",
        }}
      >
        <h6 style={{ flex: 0.5 }} htmlFor="sku">
          Shoe size
        </h6>
        <h6 style={{ flex: 0.5 }} htmlFor="sku">
          Stock
        </h6>
      </div>
      {item?.shoeSize?.map((sizeItem, index) => {
        return (
          <div
            key={sizeItem?.id}
            className="shoe_size_item_wrapper"
            style={{ marginTop: index !== 0 ? "15px" : "0px" }}
          >
            <TextField
              sx={{ flex: 0.45 }}
              value={sizeItem?.value}
              onChange={(e) =>
                updateItemSize(item?.id, sizeItem?.id, "value", e.target.value)
              }
            />
            <TextField
              sx={{ flex: 0.45, marginLeft: "65px" }}
              value={sizeItem?.quantity}
              onChange={(e) =>
                updateItemSize(
                  item?.id,
                  sizeItem?.id,
                  "quantity",
                  e.target.value
                )
              }
            />
            {index !== 0 ? (
              <div
                onClick={() => removeItemSize(item?.id, sizeItem?.id)}
                className="remove_shoe_size"
              >
                <RemoveCircleIcon style={{ color: "#e23a1d" }} />
              </div>
            ) : null}
          </div>
        );
      })}
      <div className="add_shoe_size_btn">
        <Button onClick={() => addItemSize(item.id)}>+ Shoe size</Button>
      </div>
    </div>
  );
};

export default ShoeSize;
