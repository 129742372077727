import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "api/config";

export const topBannerApi = createApi({
  reducerPath: "topBannerApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["banner"],
  endpoints: (builder) => ({
    getTopBanner: builder.query({
      query: () => `/admin/topbanner`,
      method: "GET",
      providesTags: ["banner"],
    }),
    updateTopBanner: builder.mutation({
      query: ({ text, enabled }) => ({
        url: `/admin/topbanner`,
        method: "POST",
        body: { text, enabled },
      }),
      invalidatesTags: ["banner"],
    }),
  }),
});

export const { useGetTopBannerQuery, useUpdateTopBannerMutation } =
  topBannerApi;
