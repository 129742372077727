import { useEffect, useRef } from "react";

const useScrollToTopWhatsNew = (page, getProducts) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    //IF WE ARE NOT COMING FROM THE PRODUCT BACK TO MAIN SIMPLY CHANGING PAGES, WE NEED TO SCROLL TO THE TOP OF THE PAGE
    if (
      scrollRef &&
      scrollRef.current &&
      !JSON.parse(localStorage.getItem("productScrollRef"))
      // previousPage === page
    ) {
      setTimeout(() => {
        scrollRef.current.scrollIntoView({
          block: "start",
          inline: "center",
          behavior: "smooth",
          align: true,
        });
      }, 250);
    }

    setTimeout(() => {
      getProducts({ page, limit: 30 });
    }, 250);
  }, [page]);

  return { scrollRef };
};

export default useScrollToTopWhatsNew;
