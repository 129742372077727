import React, { useState, useEffect, useCallback } from "react";
import DropDowns from "components/AdminPanel/AddProduct/DropDowns";
import { useDispatch, useSelector } from "react-redux";
import Toggles from "./Toggles";
import TaxShipVideo from "./TaxShipVideo";
import Product from "./Product";
import {
  useAddProductsMutation,
  useUploadPhotosMutation,
  useUpdateProductsMutation,
  useDeletePhotosMutation,
} from "api/products";
import { useNavigate } from "react-router-dom";
import { RESET_NEW_PRODUCT } from "store/slices/NewProductSlice";
import { RESET_NEW_PRODUCT_ITEM } from "store/slices/NewProductItemSlice";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { successToast, errorToast } from "utils/toasts";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";

const AddProduct = () => {
  const { productId } = useParams();
  const isEditing = !!productId;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addProducts, { isError: addProductError }] = useAddProductsMutation();
  const [updateProducts, { isError: updateProductsError }] =
    useUpdateProductsMutation();
  const [deletePhotos, { isError: deletePhotosError }] =
    useDeletePhotosMutation();
  const [uploadPhotos, {}] = useUploadPhotosMutation();
  const newProduct = useSelector((state) => state.newProduct);
  const newProductItems = useSelector((state) => state.newProductItem.products);
  const [loading, setLoading] = useState(false);
  const [localError, setLocalError] = useState(false);

  const submitNewProduct = useCallback(async () => {
    const consent = window.confirm(
      "Are you sure you want to submit this product?"
    );
    if (!consent) return;
    setLoading(true);
    setLocalError(false);

    const finalProduct = newProductItems?.map((item) => {
      const modifiedItem = { ...newProduct, ...item };
      return modifiedItem;
    });

    //PHOTO UPLOAD b4 submit
    for (let b = 0; b < finalProduct.length; b++) {
      let blob = [];
      let finalPhotos = [];
      let indexOfPrimaryPhoto;

      const data = new FormData();
      for (let i = 0; i < finalProduct[b]?.photos?.length; i++) {
        if (finalProduct[b]?.photos[i]?.url?.includes("blob")) {
          //CHECKING IF WE SELECT PRIMARY PHOTO THEN TAKING INDEX OF THAT PHOTO
          //TO MATCH IT LATER WITH THE NEW URL FROM FIREBASE
          if (
            finalProduct[b]?.primaryPhoto === finalProduct[b]?.photos[i]?.url
          ) {
            indexOfPrimaryPhoto = i;
          }

          await fetch(finalProduct[b]?.photos[i]?.url)
            .then((r) => r.blob())
            .then((blobFile) => {
              blob.push(
                new File([blobFile], finalProduct[b]?.photos[i]?.alt, {
                  type: blobFile.type,
                })
              );
            });
        } else {
          finalPhotos.push(finalProduct[b]?.photos[i]);
        }
      }
      if (blob.length > 0) {
        blob.forEach((file) => {
          data.append("images", file);
        });
        data.append("productId", finalProduct[b].id);
        const res = await uploadPhotos(data)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {});
        finalProduct[b].photos = [...finalPhotos, ...res.images];
        //IF WE HAVE INDEX FOR PRIMARY PHOTO THEN WE SET IT TO THE NEW URL FROM FIREBASE
        if (indexOfPrimaryPhoto) {
          finalProduct[b].primaryPhoto = res.images[indexOfPrimaryPhoto].url;
        }
      } else {
        finalProduct[b].photos = [...finalPhotos];
      }
    }

    //DELETE PHOTOS WHEN UPDATING PRODUCT IF ANY WERE REMOVED
    if (isEditing && newProductItems?.length > 0) {
      for (let i = 0; i < newProductItems.length; i++) {
        if (newProductItems[i].deletePhotos?.length > 0) {
          const deletePhotoResult = await deletePhotos(
            newProductItems[i]?.deletePhotos
          );
        }
      }
    }
    //RE-Writing the photos array with the new urls from firebase (replacing the old blob urls)
    //SUBMIT
    try {
      const res = isEditing
        ? await updateProducts(finalProduct)
        : await addProducts(finalProduct);
      if (res?.status === 200 || res?.data?.status === 200) {
        successToast();
        Promise.resolve();
        navigate("/adminpanel/products");
        return;
      } else {
        throw new Error(res?.error?.data?.message || res?.data?.message);
      }
    } catch (error) {
      errorToast();
      setLocalError(error?.message || "Unknown error");
      return Promise.reject(error);
    }
  }, [newProductItems, newProduct]);

  const SubmitBtn = ({ small }) => {
    return (
      <Button
        sx={{
          width: small ? "250px" : "100%",
          alignSelf: small ? "flex-end" : "left",
          marginTop: small ? "0" : "20px",
        }}
        className="submit_new_product_btn"
        onClick={() => submitNewProduct().finally(() => setLoading(false))}
        variant="contained"
      >
        {isEditing ? "Update product" : "Submit new product"}
      </Button>
    );
  };

  useEffect(() => {
    return () => {
      dispatch(RESET_NEW_PRODUCT());
      dispatch(RESET_NEW_PRODUCT_ITEM());
    };
  }, []);

  if (loading)
    return (
      <Box className="designer_table_loader">
        <CircularProgress />
      </Box>
    );

  return (
    <div>
      <div className="add_product_container">
        <SubmitBtn small={true} />
        {localError ? (
          <Alert sx={{ mb: 2 }} severity="error">
            {localError}
          </Alert>
        ) : null}
        <div className="dropdown_and_toggles_container">
          <DropDowns />
          <div style={{ marginLeft: "25px" }} />
          <Toggles />
        </div>
        <TaxShipVideo />
        <Product edit={isEditing} />
        <SubmitBtn />
      </div>
    </div>
  );
};

export default AddProduct;
