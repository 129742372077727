import React from "react";
import { NavLink } from "react-router-dom";
import "pages/profile/AddressPage.scss";
import { Helmet } from "react-helmet";

const AddressPage = () => {
  return (
    <div className="addressPage_container">
      <Helmet>
        <title>1511 Paris | Address book</title>
        {/* <link rel="canonical" href="https://1511.paris/profile/address" /> */}
        <meta
          name="keywords"
          content="1511 Paris Address Change, 1511 Paris Update Address, Modify Address, Change Shipping Address, Shipping Address Update, Edit Address,Update Shipping Details, Address Management"
        />
        <meta
          name="description"
          content="Welcome to the Address Change page! Update your shipping address effortlessly and ensure your beauty products are delivered to the right place. Easily modify your address details, including street, city, state, and postal code, to keep your information up-to-date. Enjoy a seamless shopping experience with accurate and reliable deliveries. Take control of your shipping destination and ensure your beauty products arrive at the perfect address. Update your address now and continue your beauty journey with confidence at 1511.paris."
        />
        <meta
          property="og:description"
          content="Welcome to the Address Change page! Update your shipping address effortlessly and ensure your beauty products are delivered to the right place. Easily modify your address details, including street, city, state, and postal code, to keep your information up-to-date. Enjoy a seamless shopping experience with accurate and reliable deliveries. Take control of your shipping destination and ensure your beauty products arrive at the perfect address. Update your address now and continue your beauty journey with confidence at 1511.paris."
        ></meta>
      </Helmet>
      <h1>ADDRESS BOOK</h1>
      <div className="addressPage_delivery_wrapper">
        <div>
          <span className="addressPage_delivery_title">
            <h2>Delivery address</h2>
            <NavLink>New address</NavLink>
          </span>
          <p>No delivery address has been registered</p>
        </div>
        <div>
          <span className="addressPage_delivery_title">
            <h2>Billing address</h2>
            <NavLink>New address</NavLink>
          </span>
          <p>No billing address has been registered</p>
        </div>
      </div>
    </div>
  );
};

export default AddressPage;
