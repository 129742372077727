import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    addItemToCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    deleteCategories: (state, action) => {
      const obj1 = {};
      action?.payload?.forEach((item) => {
        if (obj1[item]) return obj1[item]++;
        else obj1[item] = 1;
      });

      state.categories = state.categories.filter((category, index) => {
        if (!obj1[category.name]) {
          return category.name;
        }
      });
    },
  },
});

export const { setCategories, addItemToCategory, deleteCategories } =
  CategorySlice.actions;
export default CategorySlice.reducer;
