import React, { useEffect, useState } from "react";
import "components/WishList/WishList.scss";
import ProductCard from "components/Reusable/ProductCard/ProductCard";
import { useGetActiveWishList } from "utils/hooks/useGetActiveWishList";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import CreateWishList from "components/WishList/CreateWishList";
import AdminWishList from "components/WishList/AdminWishList";

const WishList = () => {
  const { wishList, isLoading } = useGetActiveWishList();
  const { user } = useSelector((state) => state);
  const isAdmin = user?.role === "admin";

  // const wishList = list.list;
  //   CONDITIONS
  if (!isAdmin && (!wishList || wishList[0]?.products?.length === 0))
    return <h3>Wish List is empty</h3>;
  if (isLoading)
    return (
      <Box className="designer_table_loader">
        <CircularProgress />
      </Box>
    );
  // MAIN RETURN

  return (
    <>
      {isAdmin ? <CreateWishList /> : null}
      {(!wishList || wishList?.length === 0) && isAdmin ? (
        <h3>No wishlist found</h3>
      ) : null}
      <div
        style={{
          justifyContent:
            wishList?.products?.length === 1 ? "flex-start" : "space-around",
        }}
        className={
          isAdmin
            ? "wish_list_product_admin_container"
            : "wish_list_product_container"
        }
      >
        {isAdmin
          ? wishList?.map((list) => {
              return <AdminWishList key={list?._id} list={list} />;
            })
          : wishList[0]?.products?.map((product) => {
              return (
                product?.productActive && (
                  <div className="wish_list_product_wrapper">
                    <ProductCard key={product.id} item={product} />
                  </div>
                )
              );
            })}
      </div>
    </>
  );
};

export default WishList;
