import React from "react";
import { useDispatch } from "react-redux";
import { ITEM_UPDATE } from "store/slices/NewProductItemSlice";
import TextField from "@mui/material/TextField";

const Descriptions = ({ item }) => {
  const dispatch = useDispatch();
  const itemUpdate = (fieldName, value, item) => {
    let obj = {};
    obj[fieldName] = value;
    obj.id = item.id;
    dispatch(ITEM_UPDATE(obj));
  };
  return (
    <>
      <div style={{ width: "100%" }}>
        <div>
          <h6 className="dropdowns_label" htmlFor="sku">
            Short description
          </h6>
        </div>
        <TextField
          sx={{ width: "100%" }}
          InputProps={{ sx: { height: "40px" } }}
          value={item?.shortDescription}
          onChange={(e) => itemUpdate("shortDescription", e.target.value, item)}
        />
      </div>
      <div style={{ width: "100%", marginTop: "25px" }}>
        <div>
          <h6 className="dropdowns_label" htmlFor="sku">
            Long description
          </h6>
        </div>
        <TextField
          sx={{ width: "100%" }}
          value={item?.longDescription}
          onChange={(e) => itemUpdate("longDescription", e.target.value, item)}
          multiline
          rows={5}
        />
      </div>
      <div style={{ width: "100%", marginTop: "25px" }}>
        <div>
          <h6 className="dropdowns_label" htmlFor="sku">
            Garment Details
          </h6>
        </div>
        <TextField
          sx={{ width: "100%" }}
          value={item?.details}
          onChange={(e) => itemUpdate("details", e.target.value, item)}
          multiline
          rows={5}
        />
      </div>
    </>
  );
};

export default Descriptions;
