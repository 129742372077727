import React from "react";
import "./Suggestions.scss";
import { useGetSuggestionsQuery } from "api/suggestions";
import ProductCard from "components/Reusable/ProductCard/ProductCard";

const Suggestions = ({ category, productId }) => {
  const { data } = useGetSuggestionsQuery({ category, productId });
  if (!data || data?.length === 0) return null;

  return (
    <div className="suggestions_container">
      <h2>You may also like</h2>
      <div className="suggestions_wrapper">
        {data?.map((suggestion) => {
          return (
            <ProductCard
              key={suggestion.id}
              item={suggestion}
              cardSize="335px"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Suggestions;
