import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  useAddHomeModulesMutation,
  useGetHomeModulesQuery,
  useUploadPhotosMutation,
} from "api/homeModules";
import "components/AdminPanel/HomeModules/HomeModules.scss";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "utils/toasts";

const HomeModules = () => {
  const [
    addHomeModules,
    { isLoading: addModulesLoading, isError: modulesError, isSuccess },
  ] = useAddHomeModulesMutation();
  const [uploadPhotos, { isLoading, isError }] = useUploadPhotosMutation();
  const {
    data: homeModulesData,
    isLoading: homeModulesLoading,
    isError: homeModulesError,
  } = useGetHomeModulesQuery();
  const largeVideo = [
    {
      firstVideo: true,
      radio: "image",
      imgUrl: null,
      videoUrl: null,
      title: null,
      subTitle: null,
      redirectLink: null,
    },
  ];
  const row = [
    {
      radio: "image",
      imgUrl: null,
      videoUrl: null,
      title: null,
      subTitle: null,
      redirectLink: null,
    },
    {
      radio: "image",
      imgUrl: null,
      videoUrl: null,
      title: null,
      subTitle: null,
      redirectLink: null,
    },
  ];
  const [homeModules, setHomeModules] = useState(null);
  const [imagesToRemove, setImagesToRemove] = useState([]);

  useEffect(() => {
    if (homeModulesData?.modules && homeModulesData?.modules?.length > 0)
      setHomeModules(homeModulesData?.modules);
    else setHomeModules([largeVideo]);
  }, [homeModulesData]);

  const imageSelector = (index, i) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ["image/jpeg", "image/png", "image/jpg", "image/webp"];
    input.multiple = false;
    input.onchange = async (e) => {
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      let tempArr = structuredClone(homeModules);

      if (typeof tempArr[index][i].imgUrl === "string")
        setImagesToRemove((prev) => [...prev, tempArr[index][i].imgUrl]);
      tempArr[index][i].imgUrl = selectedFilesArray[0];

      setHomeModules(tempArr);
    };
    input.click();
  };

  const handleRadioChange = (row, index, i, e) => {
    const { name } = e.target;
    const tempArr = structuredClone(homeModules);
    const rowCopy = structuredClone(row);
    if (name === "image") {
      if (rowCopy[i]?.videoUrl) {
        const consent = window.confirm(
          "Are you sure you want to change the radio button? This will remove the video url from this item."
        );
        if (!consent) return;
      }
      rowCopy[i].videoUrl = null;
      rowCopy[i].radio = "image";
    } else {
      if (rowCopy[i]?.imgUrl) {
        const consent = window.confirm(
          "Are you sure you want to change the radio button? This will delete the image from this item."
        );
        if (!consent) return;
      }
      if (typeof rowCopy[i]?.imgUrl === "string")
        setImagesToRemove((prev) => [...prev, rowCopy[i].imgUrl]);
      rowCopy[i].imgUrl = null;
      rowCopy[i].radio = "video";
    }
    tempArr[index] = [...rowCopy];
    setHomeModules(tempArr);
  };
  const handleAddRow = () => {
    if (homeModules.length === 0) setHomeModules([largeVideo, ...homeModules]);
    else setHomeModules([...homeModules, row]);
  };
  const handleAddLargeVideo = () => {
    const tempArr = [...homeModules];
    tempArr[0] = largeVideo;
    setHomeModules([...tempArr]);
  };
  const removeRow = (index, large = false) => {
    const consent = window.confirm("Are you sure you want to remove this row?");
    if (!consent) return;
    const tempArr = [...homeModules];
    //take image urls and delete them from the server
    tempArr[index].forEach((item) => {
      if (item.imgUrl) {
        if (typeof item.imgUrl === "string") {
          setImagesToRemove((prev) => [...prev, item.imgUrl]);
        }
      }
    });
    tempArr.splice(index, 1);
    if (large) tempArr.unshift([]);
    setHomeModules(tempArr);
  };
  const handleInputUpdate = (e, index, i) => {
    const { name, value } = e.target;

    const tempArr = structuredClone(homeModules);
    tempArr[index][i][name] = value;
    setHomeModules(tempArr);
  };

  const handleSubmit = async () => {
    ////CONVERT IMAGES TO BLOBS
    const imagesData = new FormData();
    homeModules.forEach((row, index) => {
      row.forEach((item, i) => {
        if (typeof item.imgUrl === "object" && item.imgUrl !== null) {
          imagesData.append("images", item.imgUrl);
        }
      });
    });

    if (imagesData.getAll("images").length === 0)
      return addHomeModules({
        data: homeModules,
        imagesToRemove: imagesToRemove.filter((item) => item !== "null"),
      });

    const submitRequest = (res) => {
      const tempArr = [...homeModules];
      let imageCount = 0;
      tempArr.forEach((row, index) => {
        row.forEach((item, i) => {
          if (typeof item.imgUrl === "object" && item.imgUrl !== null) {
            item.imgUrl = res?.images[imageCount]?.url;
            imageCount++;
          }
        });
      });

      addHomeModules({ data: tempArr, imagesToRemove });
      setImagesToRemove([]);
    };

    await uploadPhotos(imagesData)
      .then((res) => {
        //SUBMIT REQUEST After images are uploaded
        submitRequest(res?.data);
      })
      .catch((err) => console.log(err));
  };
  //Success or error messages
  useEffect(() => {
    if (modulesError) {
      errorToast();
    }
    if (isSuccess) {
      successToast("Home modules updated successfully");
    }
  }, [modulesError, isSuccess]);

  if (!homeModules || homeModules?.length === 0) return null;

  return (
    <div className="homemodule_container">
      <h2>Home modules</h2>
      <hr />
      {/* radio */}
      <div className="homemodule_wrapper">
        {homeModules?.map((row, index) => {
          if (index === 0 && row.length === 1) {
            return (
              <div key={index} className="homemodule_item_container">
                <h3>Large Video</h3>
                <div className="homemodule_separator" />
                <RadioGroup
                  value={row[0].radio}
                  onChange={(e) => handleRadioChange(row, index, 0, e)}
                >
                  <div>
                    <FormControlLabel
                      value={"image"}
                      name="image"
                      label="Image"
                      control={<Radio />}
                    />

                    {row[0]?.radio === "image" ? (
                      <div className="homemodule_add_photo">
                        {row[0]?.imgUrl ? (
                          <img
                            src={
                              typeof row[0]?.imgUrl === "string"
                                ? row[0]?.imgUrl
                                : URL.createObjectURL(row[0]?.imgUrl)
                            }
                            style={{
                              width: "150px",
                            }}
                            alt=""
                          />
                        ) : null}
                        <Button
                          sx={{
                            marginTop: row[0]?.imgUrl
                              ? "1rem !important"
                              : "0px !important",
                          }}
                          onClick={() => imageSelector(index, 0)}
                        >
                          + {row[0]?.imgUrl ? "Replace" : "Add"} photo
                        </Button>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <FormControlLabel
                      value={"video"}
                      label="Video"
                      name="video"
                      control={<Radio />}
                    />
                    {row[0]?.radio === "video" ? (
                      <div className="homemodule_radio_input">
                        <label htmlFor="video">Video url</label>
                        <TextField
                          value={row[0]?.videoUrl || ""}
                          onChange={(e) => handleInputUpdate(e, index, 0)}
                          name="videoUrl"
                        />
                      </div>
                    ) : null}
                  </div>
                </RadioGroup>
                <hr />
                <div className="homemodule_input_fields">
                  <label htmlFor="title">Title</label>
                  <TextField
                    onChange={(e) => handleInputUpdate(e, index, 0)}
                    name="title"
                    value={row[0]?.title || ""}
                  />
                </div>
                <div className="homemodule_input_fields">
                  <label htmlFor="subTitle">Subtitle (optional)</label>
                  <TextField
                    value={row[0]?.subTitle || ""}
                    onChange={(e) => handleInputUpdate(e, index, 0)}
                    name="subTitle"
                  />
                </div>
                <div className="homemodule_input_fields">
                  <label htmlFor="redirectUrl">Redirect url</label>
                  <TextField
                    value={row[0]?.redirectLink || ""}
                    onChange={(e) => handleInputUpdate(e, index, 0)}
                    name="redirectLink"
                  />
                </div>
                <Button
                  onClick={() => removeRow(index, true)}
                  className="homemodule_remove_buttons"
                  variant="outlined"
                  color="error"
                >
                  Remove large video
                </Button>
              </div>
            );
          } else {
            if (row.length === 0) return null;
            return (
              <div key={index} className="homemodule_item_container">
                <h3>Row number {index}</h3>
                <div className="homemodule_item_wrapper">
                  {row.map((rowItem, i) => {
                    return (
                      <div key={i} className="homemodule_item">
                        <h4>{i % 2 !== 0 ? "Right Side" : "Left side"}</h4>
                        <RadioGroup
                          value={rowItem.radio}
                          onChange={(e) => handleRadioChange(row, index, i, e)}
                        >
                          <div
                            style={{ maxHeight: "250px", minHeight: "200px" }}
                          >
                            <FormControlLabel
                              value="image"
                              label="Image"
                              name="image"
                              control={<Radio />}
                            />
                            {rowItem?.imgUrl ? (
                              <img
                                src={
                                  typeof rowItem?.imgUrl === "string"
                                    ? rowItem?.imgUrl
                                    : URL.createObjectURL(rowItem?.imgUrl)
                                }
                                style={{
                                  width: "150px",
                                  height: "150px",
                                  objectFit: "contain",
                                }}
                                alt=""
                              />
                            ) : null}
                            {rowItem?.radio === "image" ? (
                              <div className="homemodule_add_photo">
                                <Button
                                  sx={{
                                    marginTop: rowItem?.imgUrl ? "1rem" : 0,
                                  }}
                                  onClick={() => imageSelector(index, i)}
                                >
                                  {rowItem?.imgUrl
                                    ? "+ Replace photo"
                                    : "+ Add photo"}
                                </Button>
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <FormControlLabel
                              value={"video"}
                              label="Video"
                              name="video"
                              control={<Radio />}
                            />
                            {rowItem?.radio === "video" ? (
                              <div className="homemodule_radio_input">
                                <label htmlFor="video">Video url</label>
                                <TextField
                                  onChange={(e) =>
                                    handleInputUpdate(e, index, i)
                                  }
                                  name="videoUrl"
                                  value={rowItem?.videoUrl || ""}
                                />
                              </div>
                            ) : null}
                          </div>
                        </RadioGroup>
                        <hr />
                        <div className="homemodule_input_fields">
                          <label htmlFor="title">Title</label>
                          <TextField
                            value={rowItem?.title || ""}
                            onChange={(e) => handleInputUpdate(e, index, i)}
                            name="title"
                          />
                        </div>
                        <div className="homemodule_input_fields">
                          <label htmlFor="subTitle">Subtitle (optional)</label>
                          <TextField
                            value={rowItem?.subTitle || ""}
                            onChange={(e) => handleInputUpdate(e, index, i)}
                            name="subTitle"
                          />
                        </div>
                        <div className="homemodule_input_fields">
                          <label htmlFor="redirectUrl">Redirect url</label>
                          <TextField
                            value={rowItem?.redirectLink || ""}
                            onChange={(e) => handleInputUpdate(e, index, i)}
                            name="redirectLink"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <Button
                  onClick={() => removeRow(index)}
                  className="homemodule_remove_buttons"
                  variant="outlined"
                  color="error"
                >
                  Remove row
                </Button>
              </div>
            );
          }
        })}
      </div>
      <hr />
      <div className="homemodule_control_buttons">
        {homeModules[0]?.length === 0 ? (
          <Button onClick={handleAddLargeVideo} variant="outlined">
            + Add Large Video
          </Button>
        ) : null}
        <Button onClick={handleAddRow} variant="outlined">
          + Add another row
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {addModulesLoading ? (
            <CircularProgress size={25} sx={{ color: "#fff" }} />
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </div>
  );
};

export default HomeModules;
