import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductCard from "components/Reusable/ProductCard/ProductCard";
import { useGetProductsByIdsQuery } from "api/products";
import "styles/BlogCollage.scss";
import { Helmet } from "react-helmet";

const BlogCollage = () => {
  const { id } = useParams();
  const { blocks } = useSelector((state) => state.blogs);
  const filteredBlock = useMemo(() => {
    return blocks.find((block) => {
      return block.id.toLowerCase() === id;
    });
  }, []);
  const itemCategory =
    filteredBlock?.category !== "objects" ? filteredBlock?.title : "Objects";

  const {
    data: collageElements,
    loading,
    error,
  } = useGetProductsByIdsQuery(filteredBlock?.collageElements);

  return (
    <div className="blog_collage_container">
      <Helmet>
        <title>
          1511 Paris | {itemCategory ? itemCategory : "Blog Collage"}
        </title>
        <meta
          name="keywords"
          content="1511 shop, 1511, 1511 paris shop, 1511 products, item Collage, Handpicked Products, Curated Selection, Unique Finds, Fashion, Accessories, Home Decor, Beauty Products, Gifts, Style, Trendy, Must-Have, Shopping, Discover, Explore, Variety, One-of-a-Kind, Exclusive, Quality, Showcase, Inspire, Shop"
        />
        <meta
          name="description"
          content="Welcome to our Item Collage page, where you'll find a carefully curated selection of handpicked products that embody style, quality, and uniqueness. Discover a variety of fashion, accessories, home decor, beauty products, and gifts, all thoughtfully chosen to inspire and elevate your shopping experience. Each item in our collage is a one-of-a-kind find, selected with great care and attention to detail. Explore our diverse range of products and indulge in the latest trends and must-have pieces. Whether you're looking for a fashion statement, a stylish home accent, or a thoughtful gift, our item collage is the perfect showcase of exclusive and high-quality items. Get ready to shop and be inspired by our exceptional collection."
        />
        <meta
          property="og:description"
          content="Welcome to our Item Collage page, where you'll find a carefully curated selection of handpicked products that embody style, quality, and uniqueness. Discover a variety of fashion, accessories, home decor, beauty products, and gifts, all thoughtfully chosen to inspire and elevate your shopping experience. Each item in our collage is a one-of-a-kind find, selected with great care and attention to detail. Explore our diverse range of products and indulge in the latest trends and must-have pieces. Whether you're looking for a fashion statement, a stylish home accent, or a thoughtful gift, our item collage is the perfect showcase of exclusive and high-quality items. Get ready to shop and be inspired by our exceptional collection."
        ></meta>
      </Helmet>
      <h1>{itemCategory}</h1>
      <div className="blog_collage_items_container">
        {collageElements?.items?.map((item) => {
          return item?.productActive && <ProductCard item={item} />;
        })}
      </div>
    </div>
  );
};

export default BlogCollage;
