import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useGetCategoriesQuery } from "api/categories";
import { useGetDesignersQuery } from "api/designers";
import { useDispatch, useSelector } from "react-redux";
import { SET_PRODUCT } from "store/slices/NewProductSlice";
import ListSubheader from "@mui/material/ListSubheader";
import { prepareCategories } from "utils/formCategoriesForSelect";
import "./addProduct.scss";

const DropDowns = () => {
  const dispatch = useDispatch();
  const { data: categories, isLoading: isCategoryLoading } =
    useGetCategoriesQuery();
  const { data: designers, isLoading: isDesignersLoading } =
    useGetDesignersQuery();
  const { sku, category, designer, season } = useSelector(
    (state) => state.newProduct
  );
  const [preparedCategories, setPreparedCategories] = useState({});

  const handleChange = (key, val) => {
    const obj = {};
    obj[key] = val;
    dispatch(SET_PRODUCT(obj));
  };

  useEffect(() => {
    if (categories?.length > 0) {
      setPreparedCategories(prepareCategories(categories));
    }
  }, [categories]);

  return (
    <div className="dropdowns_container">
      <Box
        sx={{ boxShadow: 2, backgroundColor: "white", p: 4, borderRadius: 2 }}
      >
        <div className="dropdowns_row_wrap">
          {/* SKU BLOCK */}
          <div>
            <div>
              <h5 className="dropdowns_label" htmlFor="sku">
                SKU
              </h5>
            </div>
            <TextField
              className="dropdowns_input"
              InputProps={{ sx: { height: "50px" } }}
              id="sku"
              placeholder="sku number"
              variant="outlined"
              value={sku}
              onChange={(e) => handleChange("sku", e.target.value)}
            />
          </div>

          {/* CATEGORY BLOCK */}
          <div style={{ marginLeft: "55px" }}>
            <div>
              <h5 className="dropdowns_label" htmlFor="category">
                Category
              </h5>
            </div>
            <Select
              displayEmpty
              className="dropdowns_input"
              inputProps={{ "aria-label": "Without label" }}
              value={category}
              onChange={(e, child) => {
                // const { parentCategory } = e.currentTarget.dataset;

                if (child?.props?.parent) {
                  handleChange("parentCategory", child.props.parent);
                }
                handleChange("category", e.target.value);
              }}
              //CONTROLS THE HEIGHT OF THE DROPDOWN MENU
              MenuProps={{ PaperProps: { sx: { maxHeight: "500px" } } }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {Object.keys(preparedCategories).map((key) => {
                /* It's ugly but we had to do it this way because it didn't want to register onChange
                event if used MenuItem and ListSubheader between <div> or <React.Fragment>
                And we cannot return two elements without wrapping them into outer element.
                At least using PUSH, so it is easy on the performance */
                let menuItms = [];
                menuItms.push(<ListSubheader key={key}>{key}</ListSubheader>);
                /* ADDING PARENT CATEGORY TO THE LIST OF AVAILABLE CATEGORIES
                IN CASE ITEM DOESN'T HAVE PARENT CATEGORY AND SHOULD BELONG TO THE MAIN */
                menuItms.push(
                  <MenuItem
                    key={key + `${Math.random(1000000 * 100000)}`}
                    value={key}
                  >
                    {key}
                  </MenuItem>
                );
                menuItms.push(
                  preparedCategories[key]?.map((item, index) => {
                    return (
                      <MenuItem key={item._id} value={item.name} parent={key}>
                        {item.name}
                      </MenuItem>
                    );
                  })
                );
                return menuItms;
              })}
            </Select>
          </div>
        </div>

        <div className="dropdowns_row_wrap">
          {/* DESIGNERS BLOCK */}
          <div style={{ marginTop: "35px" }}>
            <div>
              <h5 className="dropdowns_label" htmlFor="sku">
                Designer
              </h5>
            </div>
            <Select
              className="dropdowns_input"
              value={designer}
              onChange={(e) => handleChange("designer", e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              //CONTROLS THE HEIGHT OF THE DROPDOWN MENU
              MenuProps={{ PaperProps: { sx: { maxHeight: "500px" } } }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {designers?.map((designer) => (
                <MenuItem key={designer._id} value={designer.name}>
                  {designer.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* SEASONS BLOCK */}
          <div style={{ marginTop: "35px", marginLeft: "55px" }}>
            <div>
              <h5 className="dropdowns_label" htmlFor="sku">
                Season
              </h5>
            </div>
            <Select
              className="dropdowns_input"
              value={season}
              onChange={(e) => handleChange("season", e.target.value)}
              // displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="spring">Spring</MenuItem>
            </Select>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default DropDowns;
