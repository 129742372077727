import React from "react";
import "pages/blogs/mainBlocks.scss";
import { NavLink } from "react-router-dom";

const MainBlocks = ({
  blog,
  noTitle = false,
  small = false,
  id = null,
  scrollToSection,
}) => {
  const width = window.innerWidth;
  const renderItem = (block) => {
    if (block.previewImageUrl) {
      return (
        <img
          className="image_frame"
          src={block.previewImageUrl}
          alt={block?.title}
        />
      );
    } else {
      return (
        <iframe
          className="video_iframe"
          allow="autoplay"
          frameborder="0"
          loading="lazy"
          src={block?.previewVideoUrl || ""}
        ></iframe>
      );
    }
  };
  //AMOUNT OF ITEMS FOR SUGGESTION
  let itemsToTake = 5;

  return (
    <div className="main_block_container">
      <div className="main_block">
        {noTitle ? null : (
          <div
            className="main_block_title"
            style={{ display: "flex", alignItems: "baseline" }}
          >
            <h3>{blog?.title}.</h3>
            <span>{blog?.subtitle}</span>
          </div>
        )}
        <div className="video_grid_container">
          {blog?.blocks?.map((block, index) => {
            //CHECKING IF THIS IS A BLOG PAGE WHERE WE ONLY NEED 6 ITEMS

            if (id && id === block?.id) {
              itemsToTake = 6;
              return null;
            }
            if (index > itemsToTake && small) return null;
            return (
              <div
                className={small ? "item_block small" : "item_block"}
                key={block?.title + block?.description}
              >
                <NavLink to={`/blogs/${blog?.title}/${block?.id}`} />
                <p>{block?.title}</p>
                {renderItem(block)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MainBlocks;
