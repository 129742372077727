import React from "react";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb from "components/Reusable/BreadCrumbs/BreadCrumb";
import { useGetProductQuery } from "api/products";
import Product from "components/Product";
import CircularProgress from "@mui/material/CircularProgress";
import useWindowDimensions from "utils/windowWidth";
import { Helmet } from "react-helmet";

const ProductPage = () => {
  const { category, subCategory, product_id } = useParams();
  const { data: productData, isLoading } = useGetProductQuery({ product_id });
  const product = productData?.product;

  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>
          1511 Paris | Exclusive clothing and perfume store in Paris
        </title>
        {/* <link
          rel="canonical"
          href={`https://1511.paris/product/${product?.category}/${product?.id}`}
        /> */}
        <meta
          name="keywords"
          content={`${product?.category}, ${product?.category.toLowerCase()}, ${
            product?.shortDescription
          }, ${product?.shortDescription.toLowerCase()}, ${product?.shortDescription
            .split(" ")
            .join(", ")}`}
        />
        <meta
          name="description"
          content={product?.longDescription || product?.shortDescription}
        />
        <meta
          property="og:description"
          content={product?.longDescription || product?.shortDescription}
        ></meta>
      </Helmet>
      {product && !isLoading ? (
        <>
          <Breadcrumb
            category={category}
            subCategory={subCategory}
            productId={product_id}
            shortDescription={product?.shortDescription}
          />
          <Product />
        </>
      ) : (
        <div style={{ marginTop: "5rem" }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default ProductPage;
