import React, { useEffect, useState } from "react";
import "./TopBanner.scss";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  useGetTopBannerQuery,
  useUpdateTopBannerMutation,
} from "api/topBanner";
import { errorToast, successToast } from "utils/toasts";

const TopBanner = () => {
  const [bannerText, setBannerText] = useState("");
  const [textArray, setTextArray] = useState([]);
  const [enabled, setEnabled] = useState(true);
  //REQUEST BANNER ITEMS FROM SERVER
  const { data, isLoading } = useGetTopBannerQuery();
  const [bannerSubmit, { isSuccess, isError: updateError }] =
    useUpdateTopBannerMutation();

  //setting data to what comes from backend
  useEffect(() => {
    if (data?.text) {
      setTextArray(data.text);
    }
  }, [data]);
  //error or succes message when submitting
  useEffect(() => {
    if (isSuccess) {
      successToast();
    } else if (updateError) {
      errorToast();
    }
  }, [isSuccess, updateError]);

  const handleSwtich = () => {
    setEnabled(!enabled);
  };

  const handleSubmit = async () => {
    await bannerSubmit({ text: textArray, enabled: enabled });
  };

  if (isLoading)
    return (
      <Box className="category_table_loader">
        <CircularProgress />
      </Box>
    );

  return (
    <div className="banner_top_container">
      <h1>Top Banner</h1>
      <h5>Enter at least one text or disable it all together</h5>
      <div>
        <FormControlLabel
          key={"item"}
          control={
            <Switch
              checked={textArray?.length === 0 ? false : enabled ? true : false}
              onChange={handleSwtich}
              name="disabled"
            />
          }
          label={"Enabled"}
          sx={{ fontWeight: "bold", fontSize: "16px" }}
        />
      </div>
      <div className="banner_form">
        <TextField
          value={bannerText}
          onChange={(e) => setBannerText(e.target.value)}
          id="outlined-basic"
          label="Banner text"
          variant="outlined"
        />
        <Button
          onClick={() => {
            setTextArray([...textArray, bannerText]);
            setBannerText("");
          }}
          className="form_btn"
          variant="contained"
        >
          Add banner
        </Button>
      </div>
      <div className="banner_text_output">
        {textArray?.map((item) => {
          return (
            <div className="banner_text_container">
              <h5>{item}</h5>
              <Button
                onClick={() => {
                  if (textArray.length === 1) {
                    setEnabled(false);
                  }
                  setTextArray(textArray.filter((text) => text !== item));
                }}
                variant="contained"
                color="error"
              >
                Delete
              </Button>
            </div>
          );
        })}
      </div>
      <Button
        onClick={handleSubmit}
        sx={{ width: "100%", marginTop: "5rem" }}
        variant="contained"
      >
        Save
      </Button>
    </div>
  );
};

export default TopBanner;
