import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "pages/auth/Login";

const RequireAuth = () => {
  const { accessToken } = useSelector((state) => state.user);

  return accessToken ? <Outlet /> : <Login />;
};

export default RequireAuth;
